import React, { useEffect, useState } from 'react';
import { 
  Badge, Button, ButtonToolbar, Container, Modal, Row,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { Tag, PageHeader } from 'antd';
import { connect } from 'react-redux';


import { 
  getAllTicket, getTicketHelperID, getTicketHelper,
} from '../../redux/actions/apis/ticket';
import Pageheader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import EditForm from '../../global/editForm';

import ViewAgent from './view';

const columns = [
  {
    Header: 'ID',
    accessor: 'id',
    disableGlobalFilter: true,
  },
  {
    Header: 'Subject',
    accessor: 'subject',
  },
  {
    Header: 'Account Name',
    accessor: 'name',
  },
  {
    Header: 'Status',
    accessor: 'authenticated',
    disableGlobalFilter: true,
  },
  {
    Header: 'Enterprise Name',
    accessor: 'enterprise',
    disableGlobalFilter: true,
  },
  {
    Header: 'Created At',
    accessor: 'createAt',
    disableGlobalFilter: true,
  },
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
  },
];

const TicketList = ({ 
  getAllTicket, getTicketHelperID, getTicketHelper,
 }) => {
  const [data, setData] = useState([]);
  const [datahelp, setDataHelp] = useState([]);
  const [count, setCount] = useState('');
  const [viewModal, setViewModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(false); 
  const [pageOptions, setPageOptions] = useState(null); 
  const [subject, setSubject] = useState('');
  const [page, setPage] = useState(1); 
  const [name, setName] = useState(''); 
  const [ticketId, setTicketId] = useState(''); 
  const [email, setEmail] = useState(''); 
  const [status, setStatus] = useState(''); 
  const [ds, setDS] = useState(''); 
  const [de, setDE] = useState(''); 
  const [mobilenumber, setMobileNumber] = useState(''); 
  const [accounts, setAccounts] = useState(''); 
  const [enterprise, setEnterprise] = useState('');
  const [product, setProduct] = useState([]);
  const [productfun, setProductfun] = useState([]);



  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const vtoggle = () => {
    setViewModal(prevState => !prevState);
  };

  const getData = async () => {
  const res = await getAllTicket(page, subject, ticketId, enterprise, accounts, status, ds, de);
  const helperdata = await getTicketHelper();
    setDataHelp(helperdata?.enterprise);
    setData(res?.list);
    setCount(res?.count);

    const boundary = Math.ceil(res?.count / 20);
    
    const pageNumber = [];
      for (let i = 0; i < boundary; i += 1) {
      pageNumber.push({
        value: i + 1,
        label: i + 1, 
      });
    }
    setPageOptions(pageNumber);
  };
  
  useEffect(() => {
    getData();
  }, []);



  
  const createData = (id, subject, name, authenticated, createAt, enterprise, action) => {
    const a = 0;
    return { 
      id, subject, name, authenticated, createAt, enterprise, action,
    };
  };
  
  const rows = [...
    data
      ? data?.map((item, i) => createData(
        item?.id,  
        item?.subject, 
        item?.account?.name, 
        <>
          {item?.active 
          ? (
            <> 
              <Tag color="#87d068">Active</Tag> &nbsp;
            </>
          ) 
          : (
            <>
              <Tag size="sm" color="#3ab569">Solved</Tag> &nbsp;
            </>
            )}
        </>,
        item?.created_at,
        item?.enterprise?.name,
        <>
          <Tag 
            color="#f50" 
            style={{ cursor: 'pointer' }} 
            onClick={() => history.push(`/ticket/details?id=${item?.id}`)}
          >
            view
          </Tag> &nbsp;
          {/* <Tag 
            color="#108ee9" 
            style={{ cursor: 'pointer' }} 
            onClick={() => { vtoggle(item?.id); setId(item?.id); }}
          >
            View
          </Tag> &nbsp; */}
          {/* <Tag color="#108ee9" style={{ cursor: 'pointer' }} onClick={() => { setId(item?.id); toggle(); }}>Re-Evaluate</Tag> */}
        </>,
        ))
      : '',
  ];

  const fields = [
    {
      type: 'password', 
      label: 'Password', 
      name: 'password', 
      placeholder: '8 Digit Password', 
      rules: { 
        required: 'This is required field', 
        pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
      },
    },
  ];

  const handleSearch = async (event) => {
    const res = await getAllTicket(page, subject, ticketId, enterprise, accounts, status, ds, de);
    setData(res?.list);
    setCount(res?.count);
  };
  const handleResetN = async (event) => {
    const res = await getAllTicket(1, '', '', '', '', '', '', '');
    setPage('1');
    setStatus('');
    setDE('');
    setDS('');
    setEnterprise('');
    setTicketId('');
    setSubject('');
    setAccounts('');
    setData(res?.list);
    setCount(res?.count);
  };

  const handlePageSearch = async (e) => {
    const res = await getAllTicket(e, subject, ticketId, enterprise, accounts, status, ds, de);
    setData(res?.list);
    setCount(res?.count);
  };
  const handleEnterprise = async (e) => {
    const id = e.target.value;
    setEnterprise(id); 
    const res = await getTicketHelperID(e.target.value);
    setProductfun(res);
  };


  const handleReset = () => {
    setPage('1');
    setStatus('');
    setDE('');
    setDS('');
    setEnterprise('');
    setTicketId('');
    setSubject('');
    setAccounts('');
    getData('1', '', '', '', '', '', '', '');
  };
  


  // unionOption = (
  //   unions ? unions?.map(item => createOption(item.union_id, item.name))
  // : null);
  
  return (
    <Container>
      <Row>
        <PageHeader
          ghost={false}
          style={{ background: '#f2f4f7' }}
          onBack={() => window.history.back()}
          title="Back"
        />
        <Pageheader title="Support management" subTitle="Manage all kind of Support operations" />
        <Form inline style={{ padding: '0px 25px' }}>

          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="name" className="mr-sm-2">Subject </Label>
            <Input bsSize="sm" value={subject} type="input" id="name" placeholder="" onChange={(e) => { setSubject(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="name" className="mr-sm-2">Id </Label>
            <Input bsSize="sm" value={ticketId} type="input" id="ticket" placeholder="" onChange={(e) => { setTicketId(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="a">Active &nbsp; </Label>
            <Input bsSize="sm" value={status} type="select" name="active" id="a" onChange={(e) => { setStatus(e.target.value); }}>
              <option value="">Select One</option>
              <option value="active">Active</option>
              <option value="solved">Solved</option>
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="ent">Enterprise &nbsp; </Label>
            <Input 
              bsSize="sm" 
              value={enterprise} 
              type="select" 
              name="enterprise" 
              id="ent" 
              onChange={async (e) => { handleEnterprise(e); }}
            >
              <option value="">Select One</option>
              {
              datahelp?.map(item => (
                <option value={item.id}>{item.name}</option>
                  
                ))
              }
              
             
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="ac">Account &nbsp; </Label>
            <Input bsSize="sm" value={accounts} type="select" name="account" id="ac" onChange={(e) => { console.log(e); setAccounts(e.target.value); }}>
              <option value="">Select One</option>
              {
              productfun?.account?.map(item => (
                <option value={item.id}>{item.name}</option>
                  
                ))
              }
              
              
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="ds" className="mr-sm-2">Start Date </Label>
            <Input bsSize="sm" value={ds} type="date" name="dates" id="st" onChange={(e) => { setDS(e.target.value); }} />
          </FormGroup>
          
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="de" className="mr-sm-2">End Date </Label>
            <Input bsSize="sm" value={de} type="date" name="datee" id="ste" onChange={(e) => { setDE(e.target.value); }} />
          </FormGroup>
          
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mt-sm-4">
            <Button color="primary" type="button" size="sm" onClick={handleSearch}>Search</Button>&nbsp;
            {/* <Button color="primary" type="button" size="sm" onClick={handleDownload}>Download</Button>&nbsp; */}
            <Button color="danger" type="button" size="sm" onClick={handleResetN}>Reset</Button>
          </FormGroup>
        </Form>
        <Table 
          columns={columns} 
          rows={rows} 
          title="Ticket List" 
          subTitle="All Ricket" 
          formTitle="Create a new Ticket" 
          samePage={!false}
          route="/ticket/create"
          count={count}
          current={pageOptions}
          handlePageSearch={handlePageSearch}
          isResizable={!false}
        />
      </Row>
       
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Change Password</h4>
        </div>
        <div className="modal__body">
          {/* <EditForm isAboveError fiels={fields} submitFnc={updateAgentPassword} id={id} closeFnc={toggle} /> */}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
      
      {viewModal ? <ViewAgent id={id} toggle={vtoggle} open={viewModal} /> : null}
    </Container>
  );
};

export default connect(null, { 
  getAllTicket, getTicketHelperID, getTicketHelper,
 })(TicketList);
