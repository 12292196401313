import {
    LOADING,
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    AUTH_ERROR,
    AUTH_SUCCESS,
    RESET_MESSAGES,
    VALIDATION_ERROR,
    VALIDATION_AUTH_ERROR,
    LOADED,
  } from '../types';
  
  const initState = {
    loading: false,
    success: '', 
    authSuccess: '', 
    error: '',
    authError: '',
    validationError: '',
    authValidationError: '',
  };
  const messageReducer = (state = initState, action) => {
    switch (action.type) {
      case LOADING:
        return {
          ...state,
          loading: true,
        };
      case LOADED:
        return {
          ...state,
          loading: false,
        };
      case SUCCESS_MESSAGE:
        return {
          ...state,
          success: action.payload,
        };
      case AUTH_SUCCESS:
        return {
          ...state,
          authSuccess: action.payload,
        };
      case AUTH_ERROR:
        return {
          ...state,
          authError: action.payload,
        };
      case ERROR_MESSAGE:
        return {
          ...state,
          error: action.payload,
        };
      case VALIDATION_ERROR:
        return {
          ...state,
          validationError: action.payload,
        };
      case VALIDATION_AUTH_ERROR:
        return {
          ...state,
          authValidationError: action.payload,
        };
        case RESET_MESSAGES:
          return initState;
     
      default:
        return state;
    }
  };
  export default messageReducer;
