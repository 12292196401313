import React, { useEffect, useState } from 'react';
import { 
  Button, ButtonToolbar, Container, Modal,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { 
  Col, Row, Statistic, Badge, Descriptions, Timeline, Divider, List, Typography, Tag,
 } from 'antd';
import { connect } from 'react-redux';
import { 
  getTransactionById, 
} from '../../redux/actions/apis/transaction';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';


const ViewTransactionDetails = ({ 
  getTransactionById, toggle, open, id,
 }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getTransactionById(id);
    setData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  
  return (
    <Container>
      <Modal
        isOpen={open}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
        size="lg" 
        style={{ maxWidth: '90%', width: '100%' }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          
          <h4 className="text-modal  modal__title" style={{ color: '#1a0e75', fontSize: '30px' }}>Transaction Details</h4>
        </div>
        <div style={{ pading: '20px' }}>
          {/* <h4>Subject : {data?.subject} </h4> */}
          <Tag color="#2db7f5">ID: {data?.detail?.transaction_id}</Tag>
          <Tag color="#00b894"> State : {data?.detail?.transaction_state}</Tag> 
          <Tag color="#cd2df5">Type : {data?.detail?.transaction_type}</Tag>
          <Tag color="#db2775">Transfer Amount : {data?.detail?.transfer_amount}</Tag>
          <Tag color="#16ceb2">{data?.detail?.created_at}</Tag>
          <br />
          <br />
        </div>
        <div className="modal__body">
          <Row gutter={16}>
            {/* <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Logs</h4>
              <Descriptions title=" Transaction Info" size="small">
                <Descriptions.Item label="Id">{data?.detail?.transaction_id}</Descriptions.Item>
                <Descriptions.Item label="State">{data?.detail?.transaction_state}</Descriptions.Item>
                <Descriptions.Item label="Type">{data?.detail?.transaction_type}</Descriptions.Item>
                <Descriptions.Item label="Transfer Amount">{data?.detail?.transfer_amount}</Descriptions.Item>
                <Descriptions.Item label="Error">{data?.detail?.is_error
                  ? <Badge status="error" text="Problem" /> : <Badge status="success" text="No Problem" />}
                </Descriptions.Item>
                <Descriptions.Item label="Created at">{data?.detail?.created_at}</Descriptions.Item>
                <Descriptions.Item label="Update at">{data?.detail?.updated_at}</Descriptions.Item>
              </Descriptions>
            </Col> */}
            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Transaction Info</h4>
              <Descriptions>
                <Descriptions.Item label="Note">{data?.detail?.transaction_detail?.note}</Descriptions.Item>
                <Descriptions.Item label="Amount">{data?.detail?.transaction_detail?.amount}</Descriptions.Item>
                <Descriptions.Item label="payment Method">{data?.detail?.transaction_detail?.payment_method}</Descriptions.Item>
                <Descriptions.Item label="Receeiver">{data?.detail?.transaction_detail?.receiver?.state}</Descriptions.Item>
                <Descriptions.Item label="Sender">{data?.detail?.transaction_detail?.sender?.state}</Descriptions.Item>
                <Descriptions.Item label="Transaction Flow">{data?.detail?.transaction_flow?.account}</Descriptions.Item>
                <Descriptions.Item label="Transaction at">{data?.detail?.transaction_detail?.transaction_date}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Operation Info</h4>
              <Descriptions>
                <Descriptions.Item label="Name">{data?.detail?.ops?.ops_manager?.name}</Descriptions.Item>
                <Descriptions.Item label="Email">{data?.detail?.ops?.ops_manager?.email}</Descriptions.Item>
                <Descriptions.Item label="Mobile Number">{data?.detail?.ops?.ops_manager?.mobile_number}</Descriptions.Item>
                <Descriptions.Item label="Model">{data?.detail?.ops?.on_model}</Descriptions.Item>
                <Descriptions.Item label="Message">{data?.detail?.ops?.message}</Descriptions.Item>
  
              </Descriptions>
            </Col>
            <Col span={12}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Account Info</h4>
              <Descriptions>
                <Descriptions.Item label="Name">{data?.detail?.account?.name}</Descriptions.Item>
                <Descriptions.Item label="Email">{data?.detail?.account?.email}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={12}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Enterprise Info</h4>
              <Descriptions>
                <Descriptions.Item label="Name">{data?.detail?.enterprise?.name}</Descriptions.Item>
                <Descriptions.Item label="Email">{data?.detail?.enterprise?.email}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Logs</h4>
              <br />
              <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
                {data?.detail?.logs?.slice(0)?.reverse()?.map(item => (
                  <Timeline.Item>
                    <p>{item?.message}</p>
                    <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{item?.created_at}</p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col>
            
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    </Container>
  );
};

export default connect(null, { 
  getTransactionById,
 })(ViewTransactionDetails);
