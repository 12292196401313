import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AgentList from '../../../../pages/agent';
import CreateAgent from '../../../../pages/agent/createAgent';
import EditAgent from '../../../../pages/agent/editAgent';
import ProductList from '../../../../pages/product';
import createProduct from '../../../../pages/product/createProduct';
import editProduct from '../../../../pages/product/editProduct';

export default () => (
  <Switch>
    <Route exact path="/product/list" component={ProductList} />
    <Route exact path="/product/create" component={createProduct} />
    <Route exact path="/product/edit" component={editProduct} />
  </Switch>
);
