import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({
  onClick, changeToLight, changeToDark, sidebarCollapse,
}) => (
  <div className="sidebar__content">
    <ul className="sidebar__block">
      <SidebarLink
        title="Dashboard"
        icon="chart-bars"
        route="/home"
        onClick={onClick}
      />
      {JSON.parse(localStorage.getItem('access'))?.user_management
      ? (
        <SidebarCategory title="User Management" icon="user" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="User List" route="/user/list" onClick={onClick} />
          <SidebarLink title="Role List" route="/user/role" onClick={onClick} />
        </SidebarCategory>
        )
      : null}
       
      {/* {JSON.parse(localStorage.getItem('access'))?.agent_management
      ? 
      (
        <SidebarCategory title="Agent Management" icon="users" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Agent List" route="/agent/list" onClick={onClick} />
          <SidebarLink title="Create Agent" route="/agent/create" onClick={onClick} />
        </SidebarCategory>
        )
      : null} */}
      
      {JSON.parse(localStorage.getItem('access'))?.enterprise_management 
      ? (
        <SidebarCategory title="Enterprise Management" icon="users" sidebarCollapse={sidebarCollapse}>

          <SidebarLink title="Enterprise" route="/enterprise/list" onClick={onClick} />
          <SidebarLink title="Account" route="/enterprise-account/list" onClick={onClick} />
        
        </SidebarCategory>
         )
      : null} 
      {JSON.parse(localStorage.getItem('access'))?.enterprise_user_management 
       ? (
         <SidebarCategory title="Ent. User Management" icon="users" sidebarCollapse={sidebarCollapse}>
           <SidebarLink title="User" route="/enterprise-user/list" onClick={onClick} />
         </SidebarCategory>
         )
      : null} 

      {JSON.parse(localStorage.getItem('access'))?.product_management
      ? (
        <SidebarCategory title="Product Management" icon="store" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Product List" route="/product/list" onClick={onClick} />
         
        </SidebarCategory>
        )
      : null}
      
      {JSON.parse(localStorage.getItem('access'))?.support_management
      ? (
        <SidebarCategory title="Support Management" icon="users" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Ticket List" route="/ticket/list" onClick={onClick} />
          <SidebarLink title="Subject List" route="/subject/list" onClick={onClick} />
        </SidebarCategory>
        )
      : null}
      {JSON.parse(localStorage.getItem('access'))?.support_management
      ? (
        <SidebarCategory title="Transaction Management" icon="store" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Transaction List" route="/transactions/list" onClick={onClick} />
        </SidebarCategory>
        )
      : null}
      
      <SidebarCategory title="Setting Management" icon="user" sidebarCollapse={sidebarCollapse}>
        <SidebarLink title="Setting List" route="/setting/list" onClick={onClick} />
      </SidebarCategory>
      
      {/* {JSON.parse(localStorage.getItem('access'))?.setting_management
      ? (
        <SidebarCategory title="Setting Management" icon="cog" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="API List" route="/setting/api" onClick={onClick} />
        </SidebarCategory>
        )
      : null} */}
      
    </ul>
  </div>
);

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
  onClick: () => {},
};

export default SidebarContent;
