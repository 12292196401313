import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { 
  Button, Form, Modal, Divider, Descriptions, Select, Input, PageHeader, Tooltip, Checkbox, Space,
} from 'antd';
import { 
  Content, 
} from 'antd/lib/layout/layout';

import { updateOrganization, getOrganizationById } from '../../redux/actions/apis/organization';

const { TextArea } = Input;

const EditOrganization = ({ updateOrganization, getOrganizationById, location }) => {
    const [unions, setUnions] = useState([]);
    const [ent, setEnt] = useState([]);
    const [enterpriseadmin, setenterpriseAdmin] = useState('');
    const [StatusType, setStatusType] = useState(null);
    const [datahelpn, setDataHelp] = useState('');
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [data, setData] = useState(null);
    const [ID, setID] = useState(null);
    const [submitmoel, setSubmitModel] = useState(false);
    const [form] = Form.useForm();
    let enterprise = [];
    // let datahelp = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      // const res = await getOrganizationHelper();
      const search = location?.search;
      const id = new URLSearchParams(search).get('id');
      setID(id);
      const result = await getOrganizationById(id);
      setData(result);
      // console.log(result);
      if (result) {
        form.setFieldsValue({
          name: result?.detail?.name,
          active: result?.detail?.active ? 'true' : 'false',
          email: result?.detail?.email,
          mobile_number: result?.detail?.mobile_number,
          address: result?.detail?.address,
          description: result?.detail?.description,
          remark: result?.detail?.remark,
          contact_person: result?.detail?.contact_person,
        
        });
      }
      // setUnions(res);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };

    enterprise = (
      ent?.enterprise?.length > 0 ? ent?.enterprise?.map(item => createOption(item.id, item.name))
    : null);
    const onFinish = async (values) => {
      const code = await updateOrganization(values, ID);
    };
    
    return ( 
      <Content style={{ background: 'white' }}>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Enterprise Update"
          subTitle="Enterprise Management"
        />
        <Divider>Enterprise Update</Divider>
        <Form style={{ padding: '20px', background: 'white' }} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} name="dynamic_form_nest_item" onFinish={onFinish} form={form} autoComplete="off">
          <Form.Item
            label="Name" 
            name="name"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Mobile Number" 
            name="mobile_number"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
              {
                pattern: /^(01)[0-9][0-9]{8}$/,
                message: 'Please valid phone number!',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input placeholder="017********" />
          </Form.Item>
          <Form.Item
            label="Email" 
            name="email"
            rules={[
              {
                required: true,
                message: 'Field is required',
                pattern: {
                  value: new RegExp(/\S+@\S+\.\S+/),
                  message: 'Email is not valid',
                },
              },
              
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input placeholder="xyz@redorch.com" />
          </Form.Item>
          <Form.Item 
            label="Status"
            name="active"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Select>
              <Select.Option value="true">Active</Select.Option>
              <Select.Option value="false">Inactive</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Address" 
            name="address"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <TextArea />
          </Form.Item>
          {/* <Form.Item
            label="Description" 
            name="description"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <TextArea />
          </Form.Item> */}
          
          <Form.Item
            label="Remark" 
            name="remark"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Field is required',
            //   },
            // ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input />
          </Form.Item>
          <Form.List 
            name="contact_person"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: 2,
                    }}
                    align="baseline"
                  >
                    { name <= 0 ? setSubmitModel(true) : name }

                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: 'Field is required',
                        },
                      ]}
                      
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'number']}
                      rules={[
                        {
                          required: true,
                          message: 'Field is required',
                        },
                        {
                          pattern: /^(01)[0-9][0-9]{8}$/,
                          message: 'Please valid phone number!',
                        },
                      ]}
                      
                      
                    >
                      <Input placeholder="Mobile Number" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'email']}
                      rules={[
                        {
                          required: true,
                          message: 'Field is required',
                          pattern: {
                            value: new RegExp(/\S+@\S+\.\S+/),
                            message: 'Email is not valid',
                          },
                        },
                        
                      ]}
                      
                      
                    >
                      <Input placeholder="xyz@gmail.com" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      // rules={[
                      //   {
                      //     pattern: /^(01)[0-9][0-9]{8}$/,
                      //     message: 'Please insert Valid email!',
                      //   },
                      // ]}
                      
                      
                    >
                      <Input placeholder="description" />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                      />
                      ) : null}
                  </Space>
                ))}
                <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button style={{ display: 'flex', justifyContent: 'center' }} type="dashed" onClick={() => { add(); setSubmitModel(true); }} block icon={<PlusOutlined />}>
                    Add Contact Persons
                  </Button>
                </Form.Item>
              </>
          )}
          </Form.List>
          {submitmoel ? (
            <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          )
          : (
            <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button disabled type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      </Content>
     );
};
 
export default connect(null, { getOrganizationById, updateOrganization })(EditOrganization);
