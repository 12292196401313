import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Pie } from '@ant-design/plots';
import {
  PieChart, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import Panel from '@/shared/components/Panel';
import getTooltipStyles from '@/shared/helpers';



const style = {
  left: 0,
  width: 250,
  lineHeight: '24px',
  position: 'relative',
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {payload.map(entry => (
      <li key={entry.payload.id}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
    ))}
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};

const VisitorsSessions = ({ dir, themeName, data0 }) => {
  // const [data, setData] = useState([]);
  const { t } = useTranslation('common');
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  
  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({ x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
    }
  };
  
  useEffect(() => {
    
  }, []);
  const data = [];
    data.push(...[{
     type: 'Pending', value: data0?.pending,
    }, {
     type: 'Lottery', value: data0?.waiting,
    }, {
      type: 'Extra waiting', value: data0?.e_waiting,
    }, {
      type: 'Home Verification', value: data0?.verification,
    }, {
      type: 'Selected', value: data0?.selected,
    }, {
      type: 'Rejected', value: data0?.rejected,
    }]);
    // setData(d);
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],
  };

  return (
    <Panel
      // lg={6}
      // xl={4}
      // md={12}
      xs={12}
      // title={t('home.visitors_sessions')}
      // subhead="What browsers are most popular"
    >
      <div className="dashboard__visitors-chart" style={{ height: '400px' }}>
        <p className="dashboard__visitors-chart-title">Total Canidtates by Status</p>
        <p className="dashboard__visitors-chart-number">{data0?.total}</p>
        {data?.length > 0 
          ? <Pie {...config} />
          : null}
      </div>
    </Panel>
  );
};

VisitorsSessions.propTypes = {
  dir: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
};

export default connect(state => ({ themeName: state.theme.className }))(VisitorsSessions);
