import React from 'react';
import { Col } from 'reactstrap';

const PageHeader = ({ title, subTitle }) => {
    const flag = 0;
    return ( 
      <Col md={12}>
        <h3 className="page-title">{title}</h3>
        <h3 className="page-subhead subhead">
          {subTitle}
        </h3>
      </Col>
     );
};
 
export default PageHeader;
