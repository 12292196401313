import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import { PageHeader } from 'antd';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import MicrosoftOfficeIcon from 'mdi-react/MicrosoftOfficeIcon';
import EditForm from '../../global/editForm';

import { getProductById, updateProduct } from '../../redux/actions/apis/product';


const EditProduct = ({ 
      updateProduct, getProductById, location,
    }) => {
    const [unions, setUnions] = useState([]);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [data, setData] = useState(null);
    const [ID, setID] = useState(null);
    const [page, setPage] = useState(1); 
    const [name, setName] = useState(''); 
    const [userId, setUserId] = useState(''); 
    const [email, setEmail] = useState(''); 
    const [status, setStatus] = useState(''); 
    const [ds, setDS] = useState(''); 
    const [de, setDE] = useState(''); 
    const [mobilenumber, setMobileNumber] = useState(''); 
    let un = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      // const res = await getAllOrganization(page, name, mobilenumber, status, email, ds, de);
      // setUnions(res);
      const search = location?.search;
      const id = new URLSearchParams(search).get('id');
      setID(id);
      const data = await getProductById(id);
      setData(data);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    const closeModal = () => {
      setError('');
      setIsOpen(false);
    };

    const openModal = () => {
      setIsOpen(true);
      setError('');
    };
    un = (
      unions ? unions.map(item => createOption(item.id, item.name))
    : null);
    const fiels = [
        // {
        //   type: 'input', 
        //   label: 'User Id', 
        //   name: 'enterprise', 
        //   placeholder: 'User Id', 
        //   value: data?.id,
        //   rules: { 
        //     required: 'This is required field', 
        //     },
        // },
        {
          type: 'input', 
          label: 'User Name', 
          name: 'name', 
          placeholder: 'Name', 
          value: data?.detail?.name,
          rules: { 
            required: 'This is required field', 
            },
        },
      
        { 
          type: 'dropdown', 
          label: 'Status', 
          name: 'active', 
          placeholder: 'Status', 
          value: {
            value: data?.detail?.active,
            label: data?.detail?.active ? 'Active' : 'Inactive',
          },
          rules: { required: 'This is required field' },
          option: authenticated,
          multi: false,
        },
        {
          type: 'input', 
          label: 'Production Cost', 
          name: 'production_cost', 
          placeholder: 'production cost', 
          value: data?.detail?.production_cost,
        },
        {
          type: 'input', 
          label: 'Sale Price', 
          name: 'sale_price', 
          placeholder: 'ale price', 
          value: data?.detail?.sale_price,
        },
        {
          type: 'input', 
          label: 'Description', 
          name: 'description', 
          placeholder: 'description', 
          value: data?.detail?.description,
        },
        {
          type: 'input', 
          label: 'Remark', 
          name: 'remark', 
          placeholder: 'Remark', 
          value: data?.remark,
          rules: { required: 'This is required field' },
        },
    ];
    
    
    return ( 
      // <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen}>
      //   <ModalHeader>Form</ModalHeader>
      //   <ModalBody>
      //     <p>
      //       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint neque aut, debitis, 
      //       similique temporibus amet voluptatem minima sequi
      //       tempore reiciendis laborum itaque numquam omnis mollitia, quibusdam magnam optio dolorem corrupti!
      //     </p>
      data 
      ? (
        <>
        
          <PageHeader
            ghost={false}
            style={{ background: '#f2f4f7' }}
            onBack={() => window.history.back()}
            title="Back"
          />
          <EditForm isHorizontal isAboveError title="Edit Product" fiels={fiels} submitFnc={updateProduct} id={ID} />
        </>
        )
      : null      
      //   </ModalBody>
      // </Modal>
     );
};
 
export default connect(null, { 
  updateProduct, getProductById,
 })(EditProduct);
