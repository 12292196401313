import React from 'react';
import { 
    Col, Row,
    Button,
    ButtonToolbar,
    Card,
    CardBody,
    Container,
  } from 'reactstrap';
  import { useTranslation } from 'react-i18next';
  import { useForm, Controller } from 'react-hook-form';
  import { SelectField } from '@/shared/components/form/Select';
  import { emailPatter, urlPattern } from '@/shared/helpers';
  import { MultiSelectField } from '@/shared/components/form/MultiSelect';
  import showResults from '@/utils/showResults';
  import PasswordField from '@/shared/components/form/Password';
  import styled from 'styled-components';
  import PropTypes from 'prop-types';
  import { FormField } from './FormField';

const EditForm = ({ 
    isHorizontal, isAboveError, title, subtitle, fiels, submitFnc, id, closeFnc, unionCode,
 }) => {
  const field = {};
  
  for (let i = 0; i < fiels.length;) {
    field[`${fiels[i].name}`] = '';
    i += 1;
  }
  const { t } = useTranslation('common');
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  // console.log(closeFnc);
  const onSubmit = async (data) => {
    const val = { ...data };
    if (unionCode) {
      val.union_code = unionCode;
    }
    const res = await submitFnc(val, id);
    if (res === 200) {
      reset(field);
    }
    if (res === 201) {
      // reset(field);
    }
    // if (closeFnc) {
    //   closeFnc();
    // }
  };

  const multiVal = [];
  
  // fiels[4]?.value?.map(item => (
  //   multiVal.push(...item)
  // ));
  const selection = item => (
    (
      item?.fnc
      ? (
        <FormField
          name={item?.name}
          control={control}
          component={SelectField}
          errors={errors}
          options={item?.option}
          // onChange={(e) => { item?.fnc(e); }}
          rules={item?.rules}
          defaultValue={item?.value}
          isAboveError={isAboveError}
          builder={item?.fnc}
          reset={setValue}
        />
        )
      : (
        <FormField
          name={item?.name}
          control={control}
          component={SelectField}
          errors={errors}
          options={item?.option}
          rules={item?.rules}
          defaultValue={item?.value ? item?.value : ''}
          isAboveError={isAboveError}
        />
        )
    )
  );
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{ title }</h3>
          <h3 className="page-subhead subhead">{ subtitle }</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              {/* <div className="card__title">
                <h5 className="bold-text">Form</h5>
                <h5 className="subhead">
                  fill up carefully
                </h5>
              </div> */}
              <form className={`form ${isHorizontal && 'form--horizontal'}`} onSubmit={handleSubmit(onSubmit)}>
                
                {
                fiels?.map(item => (
                  <>

                    {/* ============== Input Field ============ */}
                    {item.type === 'input' && item?.name !== 'union_code'
                    ? (
                      <div className="form__form-group">
                        <span className="form__form-group-label">{item?.label}</span>
                        <div className="form__form-group-field">
                          <FormField
                            name={item?.name}
                            control={control}
                            component="input"
                            errors={errors}
                            rules={item?.rules}
                            defaultValue={item?.value ? item?.value : ''}
                            isAboveError={isAboveError}
                            placeholder={item?.placeholder}
                          />
                        </div>
                      </div>
                      )
                    : null}
                    
                    {item?.name === 'union_code'
                    ? (
                      <div className="form__form-group">
                        <span className="form__form-group-label">{item?.label}</span>
                        <div className="form__form-group-field">
                          <FormField
                            name="union_code"
                            control={control}
                            component="input"
                            errors={errors}
                            rules={item?.rules}
                            // defaultValue={item?.value}
                            isAboveError={isAboveError}
                            placeholder={item?.value}
                            disabled 
                          />
                        </div>
                      </div>
                      )
                    : null}
                  
                    {/* ============== Password Field ============ */}
                    {item.type === 'password'
                    ? (
                      <div className="form__form-group">
                        <span className="form__form-group-label">{item?.label}</span>
                        <div className="form__form-group-field">
                          <Controller
                            name={item?.name}
                            control={control}
                            render={({ field, fieldState }) => (
                              <PasswordField
                                input={field}
                                meta={{ touched: !!fieldState.error, error: fieldState.error?.message }}
                                placeholder={item?.placeholder}
                                isAboveError={isAboveError}
                              />
                            )}
                            rules={item?.rules}
                            defaultValue=""
                          />
                        </div>
                      </div>
                      )
                    : null}
                    
                    {/* ============== Dropdown ============ */}
                    {item.type === 'dropdown'
                    ? (
                      <div className="form__form-group">
                        <span className="form__form-group-label">{item?.label}</span>
                        <div className="form__form-group-field">
                          
                          {item?.multi 
                          ? (
                            
                            <Controller
                              name={item?.name}
                              control={control}
                              errors={errors}
                              rules={item?.rules}
                              defaultValue={item?.value}
                              isAboveError={isAboveError}
                              render={({ field }) => <MultiSelectField onChnage={(e) => { console.log(e); }} {...field} options={item?.option} />}
                            />
                            )
                          : selection(item)}
                          
                        </div>
                      </div>
                      )
                    : null}
                  </>
                  ))
                }
                {/* <div className="form__form-group">
                  <span className="form__form-group-label">E-mail</span>
                  <div className="form__form-group-field">
                    <FormField
                      name="email"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        required: 'This is required field',
                        pattern: {
                        value: emailPatter,
                        message: 'Entered value does not match email format',
                        },
                    }}
                      defaultValue=""
                      isAboveError={isAboveError}
                      placeholder="example@gmail.com"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Url</span>
                  <div className="form__form-group-field">
                    <FormField
                      name="url"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        required: 'This is required field',
                        pattern: {
                        value: urlPattern,
                        message: 'invalid url',
                        },
                      }}
                      placeholder="https://themeforest.com"
                      defaultValue=""
                      isAboveError={isAboveError}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field, fieldState }) => (
                        <PasswordField
                          input={field}
                          meta={{ touched: !!fieldState.error, error: fieldState.error?.message }}
                          placeholder="Password"
                          isAboveError={isAboveError}
                        />
                      )}
                      rules={{ required: 'This is required field' }}
                      defaultValue=""
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Select Option</span>
                  <div className="form__form-group-field">
                    <FormField
                      name="select"
                      control={control}
                      component={SelectField}
                      errors={errors}
                      options={[
                        { value: 'one', label: 'One' },
                        { value: 'two', label: 'Two' },
                      ]}
                      rules={{ required: 'This is required field' }}
                      defaultValue=""
                      isAboveError={isAboveError}
                    />
                  </div>
                </div> */}
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">Submit</Button>
                  {/* <Button
                    type="button"
                    onClick={() => { 
                        reset(field);
                    }}
                  >
                    Close
                  </Button> */}
                </ButtonToolbar>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

  EditForm.propTypes = {
    isHorizontal: PropTypes.bool,
    isAboveError: PropTypes.bool,
  };
  
  EditForm.defaultProps = {
    isHorizontal: false,
    isAboveError: false,
  };

export default EditForm;
