import React, { useEffect, useState } from 'react';
import { 
  Button, ButtonToolbar, Container, Modal,
  Form, FormGroup, Label, Input, Table,
 } from 'reactstrap';
 import { 
  Col, Row, Badge, Descriptions, Timeline, Divider, List, PageHeader, Tag, Empty,
 } from 'antd';
import { connect } from 'react-redux';
import { 
  getAccountById, 
} from '../../redux/actions/apis/account';
// import PageHeader from '../../global/pageHeader';
// import Table from '../../global/table';
import history from '../../history';


const ViewAccount = ({ 
  getAccountById, toggle, open, location,
 }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const search = location?.search;
    const id = new URLSearchParams(search).get('id');
    const res = await getAccountById(id);
    setData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container style={{ background: 'white' }}>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Account Details"
        subTitle="Enterprise Management"
        style={{ paddingLeft: '0px' }}
      />
      <Row gutter={16}>
        <Col span={24}>
          <h4 className="detailHeader">Basic Info</h4>
          <Descriptions>
            <Descriptions.Item label="Type">{data?.detail?.type}</Descriptions.Item>
            <Descriptions.Item label="Name">{data?.detail?.name}</Descriptions.Item>
            <Descriptions.Item label="Mobile number">{data?.detail?.mobile_number}</Descriptions.Item>
            <Descriptions.Item label="Email">{data?.detail?.email}</Descriptions.Item>
            <Descriptions.Item label="Address">{data?.detail?.address}</Descriptions.Item>
            <Descriptions.Item label="Description">{data?.detail?.description}</Descriptions.Item>
            <Descriptions.Item label="Active">{data?.detail?.active
              ? <Badge status="success" text="Active" /> : <Badge status="error" text="Inactive" />}
            </Descriptions.Item>
            <Descriptions.Item label="Created at">{data?.detail?.created_at}</Descriptions.Item>
            <Descriptions.Item label="Update at">{data?.detail?.updated_at}</Descriptions.Item>
            <Descriptions.Item label="Creator">{data?.detail?.creator?.name}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <h4 className="detailHeader">Account Info</h4>
          <Descriptions>
            <Descriptions.Item label="Asset">{data?.detail?.asset}</Descriptions.Item>
            <Descriptions.Item label="Bank">{data?.detail?.bank}</Descriptions.Item>
            <Descriptions.Item label="Cash">{data?.detail?.cash}</Descriptions.Item>
            <Descriptions.Item label="MFS">{data?.detail?.mfs}</Descriptions.Item>
            <Descriptions.Item label="Owners Equity">{data?.detail?.owners_equity}</Descriptions.Item>
            <Descriptions.Item label="Paybale">{data?.detail?.payable}</Descriptions.Item>
            <Descriptions.Item label="Type">{data?.detail?.type}</Descriptions.Item>
            <Descriptions.Item label="Profit Margin">{data?.detail?.profit_margin}</Descriptions.Item>
            <Descriptions.Item label="Receivable">{data?.detail?.receivable}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col xs={data?.detail?.type === 'production' ? 12 : 24}>
          <div style={{ pading: '20px' }}>
            <h4 className="detailHeader">Account Users</h4>
            {console.log(data?.user?.length > 0)}
            {data?.user?.length > 0 
            ? (
              <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                <Table size="sm">
                  <thead>
                    <tr style={{ backgroundColor: '#FFF', zIndex: '200000' }}>
                      <th>#</th>
                      <th>Names</th>
                      <th>Email</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.user?.map((item, id) => (  
                      <tr>
                        <td>{id + 1 }</td>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.enterprise_admin ? 'Admin' : 'User'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : <Empty />}
            <br />
            <br />
          </div>
        </Col>
        {data?.detail?.type === 'production'
        ? (
          <Col xs={12}>
            <div style={{ pading: '20px' }}>
              <h4 className="detailHeader">Products</h4>
              {data?.product?.length > 0 
              ? (
                <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                  <Table size="sm">
                    <thead>
                      <tr style={{ backgroundColor: '#FFF', zIndex: '200000' }}>
                        <th>#</th>
                        <th>Product Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.product?.map((item, id) => (  
                        <tr>
                          <td>{id + 1 }</td>
                          <td>{item?.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : <Empty />}
              <br />
              <br />
            </div>
          </Col>
        )
        : null}
        <Col span={24}>
          <h4 className="detailHeader">Contact Person</h4>
          <br />
          <div>
            {data?.detail?.contact_person?.slice(0)?.reverse()?.map((item, id) => (
              <>
                <Row>
                  <Col xs={1}>{id + 1} </Col>
                  <Col xs={4}>Name: {item?.name} </Col>
                  <Col xs={6}>Mobile Number: {item?.number} </Col>
                  <Col xs={4}> Email: {item?.email}</Col>
                  <Col xs={9}>Description: {item?.description}</Col>
                  
                </Row>
                <br />
              </>
              
            ))}
          </div>
        </Col>   
        <Col span={12}>
          <h4 className="detailHeader">Account Logs</h4>
          <br />
          <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
            {data?.detail?.account_logs?.slice(0)?.reverse()?.map(item => (
              <Timeline.Item>
                <p>{item?.message}</p>
                <p style={{ fontSize: '10px', fontWeight: 'bold', color: '#fc142f' }}>
                  Asset:{item?.asset}&nbsp;Bank:{item?.bank} &nbsp;Cash:{item?.cash}  &nbsp;MFS:{item?.mfs}  &nbsp;Owners Equity:{item?.owners_equity}&nbsp;
                  Payable:{item?.payable} &nbsp;Receivable:{item?.receivable}
                </p>
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
        <Col span={12}>
          <h4 className="detailHeader">Logs </h4>
          <br />
          <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
            {data?.detail?.logs?.slice(0)?.reverse()?.map(item => (
              <Timeline.Item>
                <p>{item?.message}</p>
                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{item?.created_at}</p>
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
        
        
      </Row>
    </Container>
  );
};

export default connect(null, { 
  getAccountById,
 })(ViewAccount);
