import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import EmailIcon from 'mdi-react/EmailIcon';
import AccountSearchIcon from 'mdi-react/AccountSearchIcon';
import renderFileInputField from '@/shared/components/form/FileInput';
import renderSelectField from '@/shared/components/form/Select';
import renderMultiSelectField from '@/shared/components/form/MultiSelect';
import PasswordField from '@/shared/components/form/Password';

import { createUnion } from '../../redux/actions/apis/union';
import { areaList } from '../../redux/actions/apis/area';

const HorizontalForm = ({ onSubmit, areaList }) => {
  const [area, setArea] = useState(null);
  const [allDistrict, setAllDistrict] = useState(null);
  const [district, setDistrict] = useState(null);
  const [allupazilla, setAllupazilla] = useState(null);
  const [allunion, setAllunion] = useState(null);
  const [upaZilla, setUpaZilla] = useState(null);
  const [union, setUnion] = useState(null);
  const [unionCode, setUnionCode] = useState(null);

  const { t } = useTranslation('common');

  
  const getArea = async () => {
    const res = await areaList();
    setArea(res);
    const dis = [];
    const upz = [];
    const uni = [];

    for (let i = 0; i < res.length; i += 1) {
      const element = res[i];
      dis.push(element?.district);
    }
    setAllDistrict([...new Set(dis)]);
  };

  
  useEffect(() => {
    getArea();
  }, []);

    
  const getUpozilla = (val) => {
    console.log(val);
    setUnionCode(null);
    setUnion(null);
    setUpaZilla(null);
    setDistrict(val);
    const uni = [];
    for (let i = 0; i < area.length; i += 1) {
      const element = area[i]?.district;
      if (element === val?.value) {
        uni.push(area[i]?.upazilla);
      }
    }
    setAllupazilla([...new Set(uni)]);
  };

  const getUnion = (val) => {
    setUnion(null);
    setUnionCode(null);
    setUpaZilla(val);
    const uni = [];
    for (let i = 0; i < area.length; i += 1) {
      const element = area[i]?.upazilla;
      if (element === val?.value) {
        uni.push(area[i]?.union);
      }
    }
    setAllunion([...new Set(uni)]);
  };

  const getUnionCode = (val) => {
    setUnionCode(null);
    setUnion(val);
    for (let i = 0; i < area.length; i += 1) {
      const element = area[i]?.union;
      
      // console.log(element);
      // console.log(val?.value);
      if (element === val?.value) {
        setUnionCode(area[i]?.union_code);
      }
    }
  };
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{t('forms.basic_form.horizontal_form')}</h5>
            <h5 className="subhead">Labels are left from fields</h5>
          </div>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form }) => (
              <form className="form form--horizontal" onSubmit={handleSubmit}>
                
                
                <div className="form__form-group">
                  <span className="form__form-group-label">District</span>
                  <div className="form__form-group-field">
                    <Field
                      name="district"
                      value={district}
                      component={renderSelectField}
                      options={
                        allDistrict?.map(item => ({
                          value: item,
                          label: item,
                        }))
                      }
                      builder={getUpozilla}
                    />
                  </div>
                </div>
                
                <div className="form__form-group">
                  <span className="form__form-group-label">Upazilla</span>
                  <div className="form__form-group-field">
                    <Field
                      name="upazilla"
                      value={upaZilla}
                      component={renderSelectField}
                      options={
                        allupazilla?.map(item => ({
                          value: item,
                          label: item,
                        }))
                      }
                      builder={getUnion}
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Default Label</span>
                  <div className="form__form-group-field">
                    <Field
                      name="defaultInput"
                      component="input"
                      type="text"
                      placeholder="Default Input"
                    />
                  </div>
                </div>
               
                
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">Submit</Button>
                  <Button type="button" onClick={form.reset}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </form>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default connect(null, { areaList })(HorizontalForm);
