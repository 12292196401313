import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AgentList from '../../../../pages/agent';
import CreateAgent from '../../../../pages/agent/createAgent';
import EditAgent from '../../../../pages/agent/editAgent';
import TransactionList from '../../../../pages/transaction';
import createOrganization from '../../../../pages/organization/createOrganization';
import editOrganization from '../../../../pages/organization/editOrganization';

export default () => (
  <Switch>
    <Route exact path="/transactions/list" component={TransactionList} />
    {/* <Route exact path="/enterprise/create" component={createOrganization} />
    <Route exact path="/enterprise/edit" component={editOrganization} /> */}
  </Switch>
);
