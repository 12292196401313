import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AgentList from '../../../../pages/agent';
import CreateAgent from '../../../../pages/agent/createAgent';
import EditAgent from '../../../../pages/agent/editAgent';
import SubjectList from '../../../../pages/subject';
import createSubject from '../../../../pages/subject/createSubject';
import editSubject from '../../../../pages/subject/editSubject';

export default () => (
  <Switch>
    <Route exact path="/subject/list" component={SubjectList} />
    <Route exact path="/subject/create" component={createSubject} />
    <Route exact path="/subject/edit" component={editSubject} />
  </Switch>
);
