
import axios from 'axios';
import history from '../../../history';
import api from './base';
import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_AUTH_ERROR,
    VALIDATION_ERROR,
} from '../../types';

export const getTransactionById = id => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem('tk_auth')).get(
      `/transaction-management/transaction/detail?transaction=${id}`,
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};
export const getTransactionLoanById = id => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem('tk_auth')).get(
      `/transaction-management/loan/detail?loan=${id}`,
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};
export const getTransactionHelper = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem('tk_auth')).get(
      '/transaction-management/helper?enterprise',
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};
export const getTransactionHelperId = id => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem('tk_auth')).get(
      `/transaction-management/helper?enterprise=${id}`,
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const getAllTransactionLoan = (p, l, t, ls, s, en, ac, ds, de) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
      const response = await api(localStorage.getItem('tk_auth')).get(`/transaction-management/loan?p=${p}&l=${l}&t=${t}&ls=${ls}&s=${s}&en=${en}&ac=${ac}&ds=${ds}&de=${de}`);
          if (response.status === 200) {
              dispatch({ type: LOADED });
              return response.data;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};

