import React, { useEffect, useState } from 'react';
import { 
  Button, ButtonToolbar, Container, Modal,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { 
  Col, Row, Statistic, Badge, Descriptions, Timeline, Divider, List, Typography,
 } from 'antd';
import { connect } from 'react-redux';
import { 
  getCandidateByID, 
} from '../../redux/actions/apis/candidate';
import './style.css';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';


const ViewCandiate = ({ 
  getCandidateByID, toggle, open, id,
 }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getCandidateByID(id);
    setData(res?.detail[0]);
  };

  useEffect(() => {
    getData();
  }, []);

  // const img = 'https://staging.bahonexpress.com/css/files/NID-063fb825-ecd2-43dc-ac11-00045d62d38c.jpg';
  const img = 'https://sbss-api.redorch.com/files/NID-063fb825-ecd2-43dc-ac11-00045d62d38c.jpg';
  return (
    <Container>
      <Modal
        isOpen={open}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
        size="lg" 
        style={{ maxWidth: '90%', width: '100%' }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-user modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Candidate Details</h4>
        </div>
        <div className="modal__body">
          <Row gutter={16}>
            <Col span={24}>
              <Descriptions title="Candidate Info" size="small">

                <Descriptions.Item label="Candidate Id"><strong>{data?.candidate_id}</strong></Descriptions.Item>
                <Descriptions.Item label="Name"><strong>{data?.name}</strong></Descriptions.Item>
                <Descriptions.Item label="Status"><strong>{data?.status}</strong></Descriptions.Item>

                <Descriptions.Item label="NID"><strong>{data?.nid}</strong></Descriptions.Item>
                <Descriptions.Item label="Gender"><strong>{data?.gender}</strong></Descriptions.Item>
                <Descriptions.Item label="Rejection state"><strong>{data?.rejection_state}</strong></Descriptions.Item>

                <Descriptions.Item label="Old NID"><strong>{data?.other_nid}</strong></Descriptions.Item>
                <Descriptions.Item label="DOB"><strong>{data?.dob}</strong></Descriptions.Item>
                <Descriptions.Item label="Home coordinates"><strong>{data?.home_coordinates}</strong></Descriptions.Item>

                <Descriptions.Item label="Union name"><strong>{data?.union_name}</strong></Descriptions.Item>
                <Descriptions.Item label="Age"><strong>{data?.age}</strong></Descriptions.Item>
                <Descriptions.Item label="Ward no"><strong>{data?.word_no}</strong></Descriptions.Item>
                

                <Descriptions.Item label="Upazilla"><strong>{data?.upazilla}</strong></Descriptions.Item>
                <Descriptions.Item label="Address"><strong>{data?.address}</strong></Descriptions.Item>
                <Descriptions.Item label=""><strong> </strong></Descriptions.Item>

                <Descriptions.Item label="Blood Group"><strong>{data?.blood_group}</strong></Descriptions.Item>
                <Descriptions.Item label="Mobile Number"><strong>{data?.mobile_number}</strong></Descriptions.Item>
                <Descriptions.Item label=""><strong> </strong></Descriptions.Item>

                
                <Descriptions.Item label="Union code"><strong>{data?.union_code}</strong></Descriptions.Item>
                <Descriptions.Item label="Candidate Image">
                  <img src={`https://sbss-api.redorch.com/files/${data?.image}`} alt="nid" className="zoom" />
                </Descriptions.Item>
                {data?.home_image 
                ? (
                  <Descriptions.Item label="Home Image">
                    <img src={`https://sbss-api.redorch.com/files/${data?.home_image}`} alt="nid" className="zoom" />
                  </Descriptions.Item>
                )
                : null }
              </Descriptions>
            </Col>
            <Col span={24}>
              {data?.selection_criteria
              ? (
                <Descriptions title="Selection Criteria" size="small">
                  {Object.keys(data?.selection_criteria).map((index, key) => (
                    <Descriptions.Item label={index.charAt(0).toUpperCase() + index.slice(1)}><strong>{data?.selection_criteria[index]}</strong></Descriptions.Item>
                  ))}
                  {/* <Descriptions.Item label="Education level"><strong>{data?.selection_criteria ? data?.selection_criteria['education level'] : null}</strong></Descriptions.Item>
                  <Descriptions.Item label="Marital status"><strong>{data?.selection_criteria ? data?.selection_criteria['marital status'] : null}</strong></Descriptions.Item>
                  <Descriptions.Item label="Diseases in last one year"><strong>{data?.selection_criteria ? data?.selection_criteria['diseases in last one year'] : null}</strong></Descriptions.Item>
                  <Descriptions.Item label="Minimum income"><strong>{data?.selection_criteria ? data?.selection_criteria['minimum income'] : null}</strong></Descriptions.Item>
                  <Descriptions.Item label="Work in Dhaka"><strong>{data?.selection_criteria ? data?.selection_criteria['work in Dhaka'] : null}</strong></Descriptions.Item> */}
                  
                </Descriptions>
              )
              : null}
            </Col>
            <Col span={24}>
              {data?.skill
              ? (
                <Descriptions title="Skill" size="small">
                  {Object.keys(data?.skill).map((index, key) => (
                    <Descriptions.Item label={index.charAt(0).toUpperCase() + index.slice(1)}><strong>{data?.skill[index]}</strong></Descriptions.Item>
                  ))}
                  {/* <Descriptions.Item label="Agriculture experience"><strong>{data?.skill ? data?.skill['agriculture experience'] : null}</strong></Descriptions.Item>
                  <Descriptions.Item label="Business experience"><strong>{data?.skill ? data?.skill['business experience'] : null}</strong></Descriptions.Item>
                  <Descriptions.Item label="Cattle rearing experience"><strong>{data?.skill ? data?.skill['cattle rearing experience'] : null}</strong></Descriptions.Item>
                  <Descriptions.Item label="Handicrafts experience"><strong>{data?.skill ? data?.skill['handicrafts experience'] : null}</strong></Descriptions.Item>
                  */}
                </Descriptions>
              )
              : null}
            </Col>
            
            {/* <Col span={4}>
              <h4>Logs</h4>
              <br />
              <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
                {data?.logs?.map(item => (
                  <Timeline.Item>
                    <p>{item?.message}</p>
                    <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{item?.created_at}</p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col> */}
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    </Container>
  );
};

export default connect(null, { 
  getCandidateByID,
 })(ViewCandiate);
