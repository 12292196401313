import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import MicrosoftOfficeIcon from 'mdi-react/MicrosoftOfficeIcon';
import EditForm from '../../global/editForm';
import { getAllUnions, updateAgent, getAgentById } from '../../redux/actions/apis/agent';

const EditAgent = ({ 
      getAllUnions, updateAgent, getAgentById, location,
    }) => {
    const [unions, setUnions] = useState([]);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [data, setData] = useState(null);
    const [ID, setID] = useState(null);
    let un = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      const res = await getAllUnions();
      setUnions(res);
      const search = location?.search;
      const id = new URLSearchParams(search).get('id');
      setID(id);
      const data = await getAgentById(id);
      setData(data);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    const closeModal = () => {
      setError('');
      setIsOpen(false);
    };

    const openModal = () => {
      setIsOpen(true);
      setError('');
    };

    un = (
      unions ? unions.map(item => createOption(item.union_id, item.name))
    : null);
    const fiels = [
        {
          type: 'input', 
          label: 'User Id', 
          name: 'user', 
          placeholder: 'User Id', 
          value: data?.user_id,
          rules: { 
            required: 'This is required field', 
            },
        },
        {
          type: 'input', 
          label: 'User Name', 
          name: 'name', 
          placeholder: 'Name', 
          value: data?.name,
          rules: { 
            required: 'This is required field', 
            },
        },
        {
          type: 'input', 
          label: 'Email Address', 
          name: 'email', 
          placeholder: 'Email', 
          value: data?.email,
          rules: { 
            required: 'This is required field', 
            // pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Entered value does not match email format' },
          },
        },
        { 
          type: 'input', 
          label: 'Mobile Number', 
          name: 'mobile_number', 
          placeholder: 'Mobile', 
          value: data?.mobile_number,
          rules: { required: 'This is required field' },
        },
        { 
          type: 'dropdown', 
          label: 'Union', 
          name: 'union', 
          placeholder: 'Union', 
          value: data?.union?.map(item => ({ 
            value: item?.union_id,
            label: item?.name,
          })),
          rules: { required: 'This is required field' },
          option: un,
          multi: true,
        },
        
        { 
          type: 'dropdown', 
          label: 'Status', 
          name: 'authenticated', 
          placeholder: 'Status', 
          value: {
            value: data?.authenticated,
            label: data?.authenticated ? 'Active' : 'Inactive',
          },
          rules: { required: 'This is required field' },
          option: authenticated,
          multi: false,
        },
        {
          type: 'input', 
          label: 'Address', 
          name: 'address', 
          placeholder: 'Address', 
          value: data?.address,
        },
        // {
        //   type: 'input', 
        //   label: 'Remark', 
        //   name: 'remark', 
        //   placeholder: 'Remark', 
        //   value: data?.remark,
        //   rules: { required: 'This is required field' },
        // },
    ];
    
    
    return ( 
      // <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen}>
      //   <ModalHeader>Form</ModalHeader>
      //   <ModalBody>
      //     <p>
      //       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint neque aut, debitis, 
      //       similique temporibus amet voluptatem minima sequi
      //       tempore reiciendis laborum itaque numquam omnis mollitia, quibusdam magnam optio dolorem corrupti!
      //     </p>
      data 
      ? <EditForm isHorizontal isAboveError title="Edit Agent" fiels={fiels} submitFnc={updateAgent} id={ID} />
      : null      
      //   </ModalBody>
      // </Modal>
     );
};
 
export default connect(null, { 
  getAllUnions, updateAgent, getAgentById,
 })(EditAgent);
