
import axios from 'axios';
import history from '../../../history';
import api from './base';
import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_AUTH_ERROR,
    VALIDATION_ERROR,
} from '../../types';

// export const getOrganizations = (p, n, mbl, a,e, ds, de) => async (dispatch) => {
//     dispatch({ type: LOADING });
//     try {
//       if (d) {
//       const response = await axios(`
//       https://sbss-api.redorch.com/sailor/v1/enterprise-management/enterprise?p=${p}&n=${n}&user=${user}&a=${a}&u=${u}&uc=${uc}&dis=${dis}&up=${up}&d=${d}`,
//       {
//         headers: {
//           authorization: `Bearer ${localStorage.getItem('tk_auth')}`,
//           },
//           method: 'GET',
//           responseType: 'blob',
//         });
      
//         if (response.status === 200) {
//           dispatch({ type: LOADED });
//           const url = window.URL.createObjectURL(new Blob([response.data]));
//           const link = document.createElement('a');
//           link.href = url;
//           link.setAttribute(
//             'download',
//             'agent.xlsx',
//           );
//           document.body.appendChild(link);
//           link.click();
//           link.parentNode.removeChild(link);
//         }
//       } else {
//         const response = await api(localStorage.getItem('tk_auth')).get(`/enterprise-management/enterprise?p=${p}&n=${n}&m=${mbl}&a=${a}&e=${e}&ds=${ds}&de=${de}`);
//         if (response.status === 200) {
//             dispatch({ type: LOADED });
//             return response?.data;
//         }
//       }
//     } catch (err) {
//         /* =============Start========== */
//           if (err?.response?.status === 422) {
//             err?.response?.data?.errors?.forEach((item) => {
//                 dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
//             });
//           }
//           if (err?.response?.status === 401 || err?.response?.status === 403) {
//               dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
//               localStorage.clear();
//               history.push('/login');
//           }
//           dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
//           dispatch({ type: LOADED });
//         /* ===========End============ */
//       }
//       return true;
// };

export const createOrganizationUser = data => async (dispatch) => {
    dispatch({ type: LOADING });
    // const boolValue = (data.authenticated === 'true');
    // const boolAdmin = (data.enterprise_admin === 'true');

    console.log(data);
    // console.log(data.active.value);
    const accountData = [];
    data?.account?.forEach((item) => { 
      accountData.push(item?.value);
   });
    const object = {
      
      name: data.name,
      email: data.email,
      enterprise_admin: data.enterprise_admin,
      enterprise: data.enterprise,
      account: data?.account,
      password: data.password,
      mobile_number: data.mobile_number,
      address: data.address,
      authenticated: data.authenticated,
      description: data.description,
     

    };
    try {
        const response = await api(localStorage.getItem('tk_auth')).post('/enterprise-user-management/user/create', { ...object });
        // const response = null;
            if (response.status === 201) {
                dispatch({ type: LOADED });
                dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
                history.push('/enterprise-user/list');
            }
    } catch (err) {
        /* =============Start========== */
          if (err?.response?.status === 422) {
            err?.response?.data?.errors?.forEach((item) => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
              localStorage.clear();
              history.push('/login');
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
          dispatch({ type: LOADED });
        /* ===========End============ */
      }
      return true;
};

export const getOrganizationUserById = id => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem('tk_auth')).get(
      `/enterprise-user-management/user/detail?user=${id}`,
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};
export const getOrganizationUserHelper = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem('tk_auth')).get(
      '/enterprise-user-management/helper?enterprise',
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};
export const getOrganizationUserHelperid = id => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem('tk_auth')).get(
      `/enterprise-user-management/helper?enterprise=${id}`,
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateOrganizationUser = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const boolValue = (data.authenticated === 'true');
    const boolAdmin = (data.enterprise_admin === 'true');
    console.log(data);
    const object = {
        user: id,  
        enterprise: data?.enterprise,
        enterprise_admin: boolAdmin,
        name: data.name,
        email: data.email,
        mobile_number: data.mobile_number,
        address: data.address,
        authenticated: boolValue,
        description: data.description,
        account: data.account,
  
    //   remark: data?.remark,
    };

  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/enterprise-user-management/user/update/profile', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      history.push('/enterprise-user/list');
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateOrganizationUserPassword = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
      user: id,
      password: data.password,
      // confirm_password: data.confirm_password,
    };

  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/enterprise-user-management/user/update/pass', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response?.data?.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateOrganizationUserStatus = (auth, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
        user: id,
        authenticated: auth,
    };

  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/enterprise-user-management/user/auth', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response?.data?.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const getAllOrganizationUser = (p, n, mbl, a, e, ent, ac) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
      const response = await api(localStorage.getItem('tk_auth')).get(`/enterprise-user-management/user?p=${p}&n=${n}&m=${mbl}&a=${a}&e=${e}&ent=${ent}&ac=${ac}`);
          if (response.status === 200) {
              dispatch({ type: LOADED });
              return response.data;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};

export const getOrganizationUserStac = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
      const response = await api(localStorage.getItem('tk_auth')).get('/enterprise-user-management/dashboard');
          if (response.status === 200) {
              dispatch({ type: LOADED });
              return response.data;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};
