import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import MicrosoftOfficeIcon from 'mdi-react/MicrosoftOfficeIcon';
import Form from '../../global/form';
import { getAllUnions, createAgent } from '../../redux/actions/apis/agent';

const CreateAgent = ({ getAllUnions, createAgent }) => {
    const [unions, setUnions] = useState([]);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    let union = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      const res = await getAllUnions();
      setUnions(res);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    const closeModal = () => {
      setError('');
      setIsOpen(false);
    };

    const openModal = () => {
      setIsOpen(true);
      setError('');
    };

    union = (
      unions?.length > 0 ? unions?.map(item => createOption(item.union_id, item.name))
    : null);
    
 

    const fiels = [
        {
          type: 'input', 
          label: 'Agent Id', 
          name: 'user_id', 
          placeholder: 'Agent Id', 
          rules: { 
            required: 'This is required field', 
            },
        },
        {
          type: 'input', 
          label: 'Agent Name', 
          name: 'name', 
          placeholder: 'Name', 
          rules: { 
            required: 'This is required field', 
            },
        },
        {
          type: 'input', 
          label: 'Email Address', 
          name: 'email', 
          placeholder: 'Email', 
          rules: { 
            required: 'This is required field', 
            // pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Entered value does not match email format' },
          },
        },
        { 
          type: 'input', 
          label: 'Mobile Number', 
          name: 'mobile_number', 
          placeholder: 'Mobile', 
          rules: { required: 'This is required field' },
        },
        {
          type: 'password', 
          label: 'Password', 
          name: 'password', 
          placeholder: '8 Digit Password', 
          rules: { 
            required: 'This is required field', 
            pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
          },
        },
        { 
          type: 'dropdown', 
          label: 'Unions', 
          name: 'union', 
          placeholder: 'Unions', 
          rules: { required: 'This is required field' },
          option: union,
          multi: true,
        },
       
        { 
          type: 'dropdown', 
          label: 'Status', 
          name: 'authenticated', 
          placeholder: 'Status', 
          rules: { required: 'This is required field' },
          option: authenticated,
          multi: false,
        },
        {
          type: 'input', 
          label: 'Address', 
          name: 'address', 
          placeholder: 'address', 
          rules: { 
            required: 'This is required field', 
            },
        },
    ];
    
    
    return ( 
      // <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen}>
      //   <ModalHeader>Form</ModalHeader>
      //   <ModalBody>
      //     <p>
      //       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint neque aut, debitis, 
      //       similique temporibus amet voluptatem minima sequi
      //       tempore reiciendis laborum itaque numquam omnis mollitia, quibusdam magnam optio dolorem corrupti!
      //     </p>
      <Form isHorizontal isAboveError title="Create a new agent" fiels={fiels} submitFnc={createAgent} />
      //   </ModalBody>
      // </Modal>
     );
};
 
export default connect(null, { getAllUnions, createAgent })(CreateAgent);
