import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import MicrosoftOfficeIcon from 'mdi-react/MicrosoftOfficeIcon';
import Form from '../../global/form';
import { createUnion } from '../../redux/actions/apis/union';
import { areaList } from '../../redux/actions/apis/area';
import HorizontalForm from './HorizontalForm';

const CreateUnion = ({ createUnion, areaList }) => {
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [area, setArea] = useState(null);
    const [allDistrict, setAllDistrict] = useState(null);
    const [district, setDistrict] = useState(null);
    const [allupazilla, setAllupazilla] = useState(null);
    const [allunion, setAllunion] = useState(null);
    const [upaZilla, setUpaZilla] = useState(null);
    const [union, setUnion] = useState(null);
    const [unionCode, setUnionCode] = useState(null);
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];

    const getArea = async () => {
      const res = await areaList();
      setArea(res);
      const dis = [];
      const upz = [];
      const uni = [];

      for (let i = 0; i < res.length; i += 1) {
        const element = res[i];
        dis.push(element?.district);
      }
      setAllDistrict([...new Set(dis)]);

      // for (let i = 0; i < res.length; i += 1) {
      //   const element = res[i];
      //   upz.push(element?.upazilla);
      // }
      // setAllupazilla([...new Set(upz)]);

      // for (let i = 0; i < res.length; i += 1) {
      //   const element = res[i];
      //   uni.push(element?.union);
      // }
      // setAllunion([...new Set(uni)]);
    };

    useEffect(() => {
      getArea();
    }, []);
  

    const getUpozilla = (val) => {
      setUnionCode(null);
      setUnion(null);
      setUpaZilla(null);
      setDistrict(val);
      const uni = [];
      for (let i = 0; i < area.length; i += 1) {
        const element = area[i]?.district;
        if (element === val?.value) {
          uni.push(area[i]?.upazilla);
        }
      }
      setAllupazilla([...new Set(uni)]);
    };

    const getUnion = (val) => {
      setUnion(null);
      setUnionCode(null);
      setUpaZilla(val);
      const uni = [];
      for (let i = 0; i < area.length; i += 1) {
        const element = area[i]?.upazilla;
        if (element === val?.value) {
          uni.push(area[i]?.union);
        }
      }
      setAllunion([...new Set(uni)]);
    };

    const getUnionCode = (val) => {
      setUnionCode(null);
      setUnion(val);
      for (let i = 0; i < area.length; i += 1) {
        const element = area[i]?.union;

        if (element === val?.value) {
          setUnionCode(area[i]?.union_code);
          break;
        } else setUnionCode('');
      }
    };
    const fields = [
        { 
          type: 'dropdown', 
          label: 'District', 
          name: 'district', 
          placeholder: 'District', 
          rules: { required: 'This is required field' },
          option: allDistrict?.map(item => ({
            value: item,
            label: item,
          })),
          multi: false,
          value: district,
          fnc: val => getUpozilla(val),
        },
        { 
          type: 'input', 
          label: 'Mobile Number', 
          name: 'mobile_number', 
          placeholder: 'Mobile', 
        },
        {
          type: 'input', 
          label: 'Chairman Name', 
          name: 'chairman_name', 
        },
        {
          type: 'input', 
          label: 'Chairman Mobile Number', 
          name: 'chairman_mobile_number', 
        },
        { 
          type: 'dropdown', 
          label: 'Status', 
          name: 'authenticated', 
          placeholder: 'Status', 
          rules: { required: 'This is required field' },
          option: authenticated,
          multi: false,
        },
        {
          type: 'input', 
          label: 'Address', 
          name: 'address', 
          placeholder: 'address', 
          rules: { 
            required: 'This is required field', 
            },
        },
    ];
    
    if (district !== null) {
      fields.splice(1, 0, { 
        type: 'dropdown', 
        label: 'Upazilla', 
        name: 'upazilla', 
        placeholder: 'Upazilla', 
        rules: { required: 'This is required field' },
        option: allupazilla?.map(item => ({
          value: item,
          label: item,
        })),
        multi: false,
        value: upaZilla,
        fnc: getUnion,
      });
    }
    if (upaZilla !== null) {
      fields.splice(2, 0, { 
        type: 'dropdown', 
        label: 'Union', 
        name: 'union', 
        placeholder: 'Union', 
        rules: { required: 'This is required field' },
        option: allunion?.map(item => ({
          value: item,
          label: item,
        })),
        multi: false,
        value: union,
        fnc: getUnionCode,
      });
    }
    if (unionCode !== null) {
      fields.splice(3, 0, {
          type: 'input', 
          label: 'Union Code', 
          name: 'union_code',
          value: unionCode,
        });
    }

    
    return ( 
      // <HorizontalForm onSubmit={showResults} />
      // <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen}>
      //   <ModalHeader>Form</ModalHeader>
      //   <ModalBody>
      //     <p>
      //       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint neque aut, debitis, 
      //       similique temporibus amet voluptatem minima sequi
      //       tempore reiciendis laborum itaque numquam omnis mollitia, quibusdam magnam optio dolorem corrupti!
      //     </p>
      <Form unionCode={unionCode} isHorizontal isAboveError title="Create a new union" fiels={fields} submitFnc={createUnion} />
      //   </ModalBody>
      // </Modal>
     );
};
 
export default connect(null, { createUnion, areaList })(CreateUnion);
