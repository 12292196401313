// import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import { 
//   Button, Form, Modal, Divider, Descriptions, Select, Input, PageHeader, Tooltip, Checkbox, Space,
// } from 'antd';
// import { 
//   Content, 
// } from 'antd/lib/layout/layout';

// import { getProductHelper, getProductEnterpriseHelper, createProduct } from '../../redux/actions/apis/product';

// const { TextArea } = Input;

// const CreateProduct = ({ getProductHelper, getProductEnterpriseHelper, createProduct }) => {
//     const [unions, setUnions] = useState([]);
//     const [ent, setEnt] = useState([]);
//     const [enterpriseadmin, setenterpriseAdmin] = useState('');
//     const [StatusType, setStatusType] = useState(null);
//     const [datahelpn, setDataHelp] = useState('');
//     const [error, setError] = useState('');
//     const [EntAct, setEnterpriseAccountType] = useState('');
//     const [isOpen, setIsOpen] = useState(true);
//     const [form] = Form.useForm();
//     let enterprise = [];
//     let datahelp = [];
//     const authenticated = [
//       { value: true, label: 'Active' },
//       { value: false, label: 'Inactive' },
//     ];
//     useEffect(async () => {
//       // const res = await getOrganizationHelper();
//       const enterpriseall = await getProductHelper();
//       setEnt(enterpriseall);
//       // setUnions(res);
//     }, []);
//     const createOption = (value, label) => {
//       const a = '';
//       return { value, label };
//     };

//     enterprise = (
//       ent?.enterprise?.length > 0 ? ent?.enterprise?.map(item => createOption(item.id, item.name))
//     : null);
//     const onFinish = async (values) => {
//       const data = await createProduct(values);
      
//       console.log('Received values of form:', values);
//     };
    
//     return ( 
//       <Content style={{ background: 'white' }}>
//         <PageHeader
//           ghost={false}
//           onBack={() => window.history.back()}
//           title="Product Create"
//           subTitle="Product Management"
//         />
//         <Divider>Product Create</Divider>
//         <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} name="dynamic_form_nest_item" onFinish={onFinish} form={form} autoComplete="off">
//           <Form.Item
//             label="Name" 
//             name="name"
//             rules={[
//               {
//                 required: true,
//                 message: 'Field is required',
//               },
//             ]}
//             style={{ alignSelf: 'center', width: '100%' }}
//           >
//             <Input placeholder="Name" />
//           </Form.Item>
          
//           <Form.Item 
//             label="Status"
//             name="active"
//             rules={[
//               {
//                 required: true,
//                 message: 'Field is required',
//               },
//             ]}
//             style={{ alignSelf: 'center', width: '100%' }}
            
//           >
//             <Select>
//               <Select.Option value="true">Active</Select.Option>
//               <Select.Option value="false">Inactive</Select.Option>
//             </Select>
//           </Form.Item>
//           <Form.Item 
//             label="Enterprise"
//             name="enterprise"
//             rules={[
//               {
//                 required: true,
//                 message: 'Field is required',
//               },
//             ]}
//             style={{ alignSelf: 'center', width: '100%' }}
           
//           >
//             <Select
//               showSearch
//               onChange={async (e) => {
//               setEnterpriseAccountType(e);
//               datahelp = await getProductEnterpriseHelper(e);
//               setDataHelp(datahelp);
//               form.setFieldsValue({ account: null });
//               }}
//             >
//               {
//               ent?.enterprise?.map(item => (
//                 <Select.Option value={item.id}>{item.name}</Select.Option>
                
//               ))
//               }
//             </Select>
//           </Form.Item>
//           { (EntAct !== '') && (datahelpn?.account?.lenth !== 0)
           
//           ? (
//             <Form.Item 
//               label="Account"
//               name="account"
//               rules={[
//                 {
//                   required: true,
//                   message: 'Field is required',
//                 },
//               ]}
//               style={{ alignSelf: 'center', width: '100%' }}
//             >
//               <Select
//                 showSearch
//               >
//                 {
//                 datahelpn?.account?.map(item => (
                  
//                   <Select.Option value={item.id}>{item.name}</Select.Option>
                  
//                 ))
//                 }
//               </Select>
//             </Form.Item>
//             ) : null }
          

//           <Form.Item
//             label="Production Cost" 
//             name="production_cost"
//             rules={[
//               {
//                 required: true,
//                 message: 'Field is required',
//               },
//             ]}
//             style={{ alignSelf: 'center', width: '100%' }}
//           >
//             <Input type="number" />
//           </Form.Item>
//           <Form.Item
//             label="Sale price" 
//             name="sale_price"
//             rules={[
//               {
//                 required: true,
//                 message: 'Field is required',
//               },
//             ]}
//             style={{ alignSelf: 'center', width: '100%' }}
//           >
//             <Input type="number" />
//           </Form.Item>
//           <Form.Item
//             label="Description" 
//             name="description"
//             rules={[
//               {
//                 required: true,
//                 message: 'Field is required',
//               },
//             ]}
//             style={{ alignSelf: 'center', width: '100%' }}
//           >
//             <TextArea />
//           </Form.Item>
          
          
          
//           <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
//             <Button type="primary" htmlType="submit">
//               Submit
//             </Button>
//           </Form.Item>
//         </Form>
//       </Content>
//      );
// };
 
// export default connect(null, { getProductHelper, getProductEnterpriseHelper, createProduct })(CreateProduct);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import MicrosoftOfficeIcon from 'mdi-react/MicrosoftOfficeIcon';
import Form from '../../global/form';
import { getAllUnions, createAgent } from '../../redux/actions/apis/agent';
import { getProductHelper, getProductEnterpriseHelper, createProduct } from '../../redux/actions/apis/product';

const CreateProduct = ({ getProductHelper, getProductEnterpriseHelper, createProduct }) => {
    // const [enterprise, setEnterprise] = useState([]);
    const [org, setOrg] = useState('');
    const [enterpriseadmin, setenterpriseAdmin] = useState('');
    const [datahelpn, setDataHelp] = useState('');
    const [formValues, setFormValues] = useState([{ name: '', mobile_number: '' }]);
    const [error, setError] = useState('');
    const [accountfieldresent, setAccountFieldreset] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const {
      handleSubmit,
      reset,
      setValue,
      control,
      formState: { errors },
    } = useForm({});
    let enterprise = [];
    let accounts = [];
    let datahelp = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    const authenticatedadmin = [
      { value: true, label: 'Admin' },
      { value: false, label: 'User' },
    ];
    // console.log(enterpriseadmin, 'ddd');
    useEffect(async () => {
      const organizationHelper = await getProductHelper();
      // console.log(organizationHelper);
      setOrg(organizationHelper);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    const closeModal = () => {
      setError('');
      setIsOpen(false);
    };
    const getenterprise = async (val) => {
    // console.log(val);
    datahelp = await getProductEnterpriseHelper(val.value);
    setDataHelp(datahelp);
    };
    const checkvalue = async (val) => {
    // console.log(val.value);
    setenterpriseAdmin(val.value);
    };

    const openModal = () => {
      setIsOpen(true);
      setError('');
    };
    // console.log(datahelpn?.account);
    enterprise = (
      org?.enterprise?.length > 0 ? org?.enterprise?.map(item => createOption(item.id, item.name))
    : null);

    accounts = (
      datahelpn?.account?.length > 0 ? datahelp?.account?.map(item => createOption(item.id, item.name))
    : null);
    
 

    const fiels = [
        
        {
          type: 'input', 
          label: 'Name', 
          name: 'name', 
          placeholder: 'Name', 
          rules: { 
            required: 'This is required field', 
            },
        },
        { 
          type: 'dropdown', 
          label: 'Enterprise', 
          name: 'enterprise', 
          placeholder: 'enterprise', 
          rules: { required: 'This is required field' },
          option: enterprise,
          multi: false,
          fnc: val => getenterprise(val),
        },
        
        
        { 
          type: 'dropdown', 
          label: 'Status', 
          name: 'active', 
          placeholder: 'authenicated status', 
          rules: { required: 'This is required field' },
          option: authenticated,
          multi: false,
        },
        {
          type: 'input', 
          label: 'Production Cost', 
          name: 'production_cost', 
          placeholder: 'production cost', 
          rules: { 
            required: 'This is required field', 
            },
        },
        {
          type: 'input', 
          label: 'Sale price', 
          name: 'sale_price', 
          placeholder: 'Sale price', 
          rules: { 
            required: 'This is required field', 
            },
        },
        {
          type: 'input', 
          label: 'Description', 
          name: 'description', 
          placeholder: 'description', 
          rules: { 
            required: 'This is required field', 
            },
        },
        

    ];
    // console.log(accountfieldresent);
    if (datahelpn) {
      fiels.splice(2, 0, { 
        type: 'dropdown', 
        label: 'Account', 
        name: 'account', 
        placeholder: 'account', 
        option: datahelpn?.account?.map(item => ({
          value: item?.id,
          label: item?.name,
        })),
        multi: false,
        // value: account,
        // fnc: getUnion,
      });
    }
    
    return ( 
      // <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen}>
      //   <ModalHeader>Form</ModalHeader>
      //   <ModalBody>
      //     <p>
      //       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint neque aut, debitis, 
      //       similique temporibus amet voluptatem minima sequi
      //       tempore reiciendis laborum itaque numquam omnis mollitia, quibusdam magnam optio dolorem corrupti!
      //     </p>
      <Form isHorizontal isAboveError title="Create a new Enterprise User" fiels={fiels} submitFnc={createProduct} />
      //   </ModalBody>
      // </Modal>
     );
};
 
export default connect(null, { getProductHelper, getProductEnterpriseHelper, createProduct })(CreateProduct);
