import axios from 'axios';
import history from '../../../history';
import api from './base';
import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_AUTH_ERROR,
    VALIDATION_ERROR,
} from '../../types';

export const getUnions = (p, dis, up, n, uc, ea, a, o, d) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    if (d) {
      const response = await axios(`
      https://sbss-api.redorch.com/sailor/v1/union-management/union/?p=${p}&dis=${dis}&up=${up}&n=${n}&ea=${ea}&a=${a}&uc=${uc}&o=${o}&d=${d}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('tk_auth')}`,
          },
          method: 'GET',
          responseType: 'blob',
        });
      
        if (response.status === 200) {
          dispatch({ type: LOADED });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            'union.xlsx',
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      } else {
      const response = await api(localStorage.getItem('tk_auth')).get(`/union-management/union/?p=${p}&dis=${dis}&up=${up}&n=${n}&ea=${ea}&a=${a}&uc=${uc}&o=${o}&d=false`);
          if (response.status === 200) {
              dispatch({ type: LOADED });
              return response?.data;
          }
        }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};

export const getUnionsByID = id => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
      const response = await api(localStorage.getItem('tk_auth')).get(`union-management/union/detail?union=${id}`);
          if (response.status === 200) {
              dispatch({ type: LOADED });
              return response?.data;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};

export const createUnion = data => async (dispatch) => {
    dispatch({ type: LOADING });
    const object = {
      name: data.union?.value,
      candidate_limit: data.candidate_limit,
      union_code: data.union_code,
      mobile_number: data.mobile_number,
      chairman_name: data.chairman_name,
      chairman_mobile_number: data.chairman_mobile_number,
      address: data.address,
      active: data?.authenticated?.value,
      district: data?.district?.value,
      upazilla: data?.upazilla?.value,
    };
    try {
        const response = await api(localStorage.getItem('tk_auth')).post('/union-management/union/create', { ...object });
            if (response.status === 201) {
                dispatch({ type: LOADED });
                dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
                return response.status;
            }
    } catch (err) {
        /* =============Start========== */
          if (err?.response?.status === 422) {
            err?.response?.data?.errors?.forEach((item) => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
              localStorage.clear();
              history.push('/login');
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
          dispatch({ type: LOADED });
        /* ===========End============ */
      }
      return true;
};

export const updateUnion = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING });

  const object = {
    union: id,
    name: data.union?.value,
    candidate_limit: data.candidate_limit,
    union_code: data.union_code,
    mobile_number: data.mobile_number,
    chairman_name: data.chairman_name,
    chairman_mobile_number: data.chairman_mobile_number,
    address: data.address,
    active: data?.authenticated?.value,
    district: data?.district?.value,
    upazilla: data?.upazilla?.value,
    candidate_entry: data?.candidate_entry?.value,
  };
  try {
      const response = await api(localStorage.getItem('tk_auth')).patch('/union-management/union/update', { ...object });
          if (response.status === 201) {
              dispatch({ type: LOADED });
              dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
              history.push('/union/list');
              return response.status;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};

export const updateUnionStatus = (active, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
      union: id,
      active,
    };

  dispatch({ type: LOADED });
  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/union-management/union/active', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response?.data?.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateUnionEntryStatus = (active, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
      union: id,
      active,
    };

  dispatch({ type: LOADED });
  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/union-management/union/entry', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response?.data?.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const areaList = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
      const response = await api(localStorage.getItem('tk_auth')).get('/public-management/area/list');
          if (response.status === 200) {
              dispatch({ type: LOADED });
              return response?.data?.list;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};

export const getUnionStac = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
      const response = await api(localStorage.getItem('tk_auth')).get('/union-management/union/statistic');
          if (response.status === 200) {
              dispatch({ type: LOADED });
              return response.data;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};
