import React, { useEffect, useState } from 'react';
import { 
  Badge, Button, ButtonToolbar, Container, Modal, Row,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { Tag, PageHeader } from 'antd';
import { connect } from 'react-redux';

import { 
  getAllOrganizationUser, getOrganizationUserById, updateOrganizationUserStatus, getOrganizationUserHelper, updateOrganizationUserPassword, getOrganizationUserHelperid,
} from '../../redux/actions/apis/organizationuser';
import Pageheader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import EditForm from '../../global/editForm';
import ViewAgent from './view';

const columns = [
  {
    Header: '#SL',
    accessor: 'id',
    disableGlobalFilter: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Mobile Number',
    accessor: 'mobileNumber',
  },
  {
    Header: 'Status',
    accessor: 'authenticated',
    disableGlobalFilter: true,
  },
  
  {
    Header: 'Enterprise Name',
    accessor: 'enterprise',
    disableGlobalFilter: true,
  },
  {
    Header: 'Enterprise Status',
    accessor: 'admin',
    disableGlobalFilter: true,
  },
  
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
  },
];

const OrganizationList = ({ 
  getAllOrganizationUser, updateOrganizationUserStatus, updateOrganizationUserPassword, getOrganizationUserHelper, getOrganizationUserHelperid,
 }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState('');
  const [viewModal, setViewModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(false); 
  const [allArea, setAllArea] = useState([]);

  const [pageOptions, setPageOptions] = useState(null); 
  const [page, setPage] = useState(1); 
  const [name, setName] = useState(''); 
  const [userId, setUserId] = useState(''); 
  const [email, setEmail] = useState(''); 
  const [status, setStatus] = useState(''); 
  const [en, setEN] = useState(''); 
  const [ac, setAC] = useState(''); 
  const [ds, setDS] = useState(''); 
  const [de, setDE] = useState(''); 
  const [mobilenumber, setMobileNumber] = useState('');
  const [accounts, setAccounts] = useState('');  
  const [enterprise, setEnterprise] = useState('');
  const [product, setProduct] = useState([]);
  const [productfun, setProductfun] = useState([]);

  const toggle = () => {
    setModal(prevState => !prevState);
  };
  console.log(productfun);
  const vtoggle = () => {
    setViewModal(prevState => !prevState);
  };

  const getData = async () => {
  const res = await getAllOrganizationUser(page, name, mobilenumber, status, email, enterprise, accounts);
  const res1 = await getOrganizationUserHelper();
    setData(res?.list);
    setProduct(res1);
    setCount(res?.count);

    const boundary = Math.ceil(res?.count / 20);
    
    const pageNumber = [];
      for (let i = 0; i < boundary; i += 1) {
      pageNumber.push({
        value: i + 1,
        label: i + 1, 
      });
    }
    setPageOptions(pageNumber);
  };
  const handleEnterprise = async (e) => {
    const id = e.target.value;
    setEnterprise(id); 
    const res = await getOrganizationUserHelperid(e.target.value);
    setProductfun(res);
  };
  const handleDownload = async () => {
    const res = await getAllOrganizationUser(page, name, mobilenumber, status, email, en, ac);
  };
  // console.log(data);
  useEffect(() => {
    getData();
  }, []);
  const createOption = (value, label) => {
    const a = '';
    return { value, label };
  };

  const handleStatus = async (val, id) => {
    const res = await updateOrganizationUserStatus(val, id);
    if (res) {
      getData();
    }
  };
  
  const createData = (id, name, email, mobileNumber, authenticated, admin, enterprise, action) => {
    const a = 0;
    return { 
      id, name, email, mobileNumber, authenticated, admin, enterprise, action,
    };
  };
  
  const rows = [...
    data
      ? data?.map((item, i) => createData(
        i + 1, 
        item?.name, 
        item?.email ? item?.email : '', 
        item?.mobile_number, 
        <>
          {item?.authenticated 
          ? (
            <> 
              <Tag color="#87d068">Active</Tag> &nbsp;
              <Tag size="sm" color="#9E1F21" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(false, item?.user_id); }}>Disable</Tag> 
            </>
          ) 
          : (
            <>
              <Tag size="sm" color="#f50">Inactive</Tag> &nbsp;
              <Tag size="sm" color="#87d068" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(true, item?.user_id); }}>Activate</Tag> 
            </>
            )}
        </>,
        <>
          {item?.enterprise_admin 
          ? (
            <> 
              <Tag color="#f94b2c">Admin</Tag> &nbsp;
            </>
          ) 
          : (
            <>
              <Tag size="sm" color="#3ab569">User</Tag> &nbsp;
            </>
            )}
        </>,
        <> 
          <strong>{item?.enterprise?.name }</strong>&nbsp;
        </>, 
        <>
          <Tag 
            color="#DDD22C" 
            style={{ cursor: 'pointer' }} 
            onClick={() => history.push(`/enterprise-user/edit?id=${item?.user_id}`)}
          >
            Edit
          </Tag> 
          <Tag 
            color="#108ee9" 
            style={{ cursor: 'pointer' }} 
            onClick={() => { vtoggle(item?.user_id); setId(item?.user_id); }}
          >
            View
          </Tag> &nbsp;
          <Tag color="#1B8973" style={{ cursor: 'pointer' }} onClick={() => { setId(item?.user_id); toggle(); }}>Change Password</Tag>
        </>,
        ))
      : '',
  ];

  const fields = [
    {
      type: 'password', 
      label: 'Password', 
      name: 'password', 
      placeholder: '8 Digit Password', 
      rules: { 
        required: 'This is required field', 
        pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
      },
    },
  ];

  const handleSearch = async (event) => {
    const res = await getAllOrganizationUser(page, name, mobilenumber, status, email, enterprise, accounts);
    setData(res?.list);
    setCount(res?.count);
  };
  const handleResetN = async (event) => {
    const res = await getAllOrganizationUser(1, '', '', '', '', '', '');
    setPage('1');
    setName('');
    setAccounts('');
    setEnterprise('');
    setMobileNumber('');
    setEmail('');
    setStatus('');
    setData(res?.list);
    setCount(res?.count);
  };

  const handlePageSearch = async (e) => {
    const res = await getAllOrganizationUser(e, name, mobilenumber, status, email, enterprise, accounts);
    
    setData(res?.list);
    setCount(res?.count);
  };

  const handleReset = () => {
    setPage('1');
    setName('');
    setAccounts('');
    setEnterprise('');
    setMobileNumber('');
    setEmail('');
    setStatus('');
    getData('1', '', '', '', '', '', '');
  };
  


  // unionOption = (
  //   unions ? unions?.map(item => createOption(item.union_id, item.name))
  // : null);
  
  return (
    <Container>
      <Row>
        <PageHeader
          ghost={false}
          style={{ background: '#f2f4f7' }}
          onBack={() => window.history.back()}
          title="Back"
        />
        <Pageheader title="Enterprise User management" subTitle="Manage all kind of Enterprise User operations" />
        <Form inline style={{ padding: '0px 25px' }}>
          
          {/* <FormGroup>
            <Label for="p">Page &nbsp; </Label>
            <Input bsSize="sm" value={page} type="select" id="p" onChange={(e) => { setPage(e.target.value); }}>
              <option value="1">Select Page Number</option>
              {pageOptions?.length > 0 
              ? pageOptions.map(item => (
                <option value={item?.value}>{item?.label}</option>
                ))
              : <option value="1">1</option>}
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp; */}
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="name" className="mr-sm-2">Name </Label>
            <Input bsSize="sm" value={name} type="input" id="name" placeholder="" onChange={(e) => { setName(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="user" className="mr-sm-2">Mobile Number</Label>
            <Input bsSize="sm" type="input" value={mobilenumber} id="user" placeholder="" onChange={(e) => { setMobileNumber(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="email" className="mr-sm-2">Email </Label>
            <Input bsSize="sm" type="input" value={email} id="email" placeholder="" onChange={(e) => { setEmail(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          
          <FormGroup>
            <Label for="a">Active &nbsp; </Label>
            <Input bsSize="sm" value={status} type="select" name="select" id="a" onChange={(e) => { setStatus(e.target.value); }}>
              <option value="">Select One</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="ent">Enterprise &nbsp; </Label>
            <Input 
              bsSize="sm" 
              value={enterprise} 
              type="select" 
              name="enterprise" 
              id="ent" 
              onChange={async (e) => { handleEnterprise(e); }}
            >
              <option value="">Select One</option>
              {
              product?.enterprise?.map(item => (
                <option value={item?.id}>{item?.name}</option>
                  
                ))
              }
              
             
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="ac">Account &nbsp; </Label>
            <Input bsSize="sm" value={accounts} type="select" name="account" id="ac" onChange={(e) => { console.log(e); setAccounts(e.target.value); }}>
              <option value="">Select One</option>
              {
              productfun?.account?.map(item => (
                <option value={item.id}>{item.name}</option>
                  
                ))
              }
              
              
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mt-sm-4">
            <Button color="primary" type="button" size="sm" onClick={handleSearch}>Search</Button>&nbsp;
            {/* <Button color="primary" type="button" size="sm" onClick={handleDownload}>Download</Button>&nbsp; */}
            <Button color="danger" type="button" size="sm" onClick={handleResetN}>Reset</Button>
          </FormGroup>
        </Form>
        <Table 
          columns={columns} 
          rows={rows} 
          title="Enterprise User List" 
          subTitle="All Enterprise User" 
          formTitle="Create a new Enterprise User" 
          samePage={false} 
          route="/enterprise-user/create"
          count={count}
          current={pageOptions}
          handlePageSearch={handlePageSearch}
          isResizable={!false}
        />
      </Row>
       
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Change Password</h4>
        </div>
        <div className="modal__body">
          <EditForm isAboveError fiels={fields} submitFnc={updateOrganizationUserPassword} id={id} closeFnc={toggle} />
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
      
      {viewModal ? <ViewAgent id={id} toggle={vtoggle} open={viewModal} /> : null}
    </Container>
  );
};

export default connect(null, { 
  getAllOrganizationUser, updateOrganizationUserStatus, getOrganizationUserById, getOrganizationUserHelper, getOrganizationUserHelperid, updateOrganizationUserPassword,
 })(OrganizationList);
