import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AgentList from '../../../../pages/agent';
import CreateAgent from '../../../../pages/agent/createAgent';
import EditAgent from '../../../../pages/agent/editAgent';
import AccountList from '../../../../pages/account';
import createAccount from '../../../../pages/account/createAccount';
import editAccount from '../../../../pages/account/editAccount';
import ViewAccount from '../../../../pages/account/view';
import reevaluate from '../../../../pages/account/reEvaulate';

export default () => (
  <Switch>
    <Route exact path="/enterprise-account/list" component={AccountList} />
    <Route exact path="/enterprise-account/create" component={createAccount} />
    <Route exact path="/enterprise-account/edit" component={editAccount} />
    <Route exact path="/enterprise-account/view" component={ViewAccount} />
    <Route exact path="/enterprise-account/reevaluate-edit" component={reevaluate} />
  </Switch>
);
