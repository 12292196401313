import React, { useEffect, useState } from 'react';
import { 
  Badge, Button, ButtonToolbar, Container, Modal, Row,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { Tag, PageHeader } from 'antd';
import { connect } from 'react-redux';
import { 
  getAllProduct, getProductHelper, updateProductStatus, getProductEnterpriseHelper,
} from '../../redux/actions/apis/product';
import Pageheader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import EditForm from '../../global/editForm';
import { areaList } from '../../redux/actions/apis/union';
import ViewProduct from './view';

const columns = [
  {
    Header: '#SL',
    accessor: 'id',
    disableGlobalFilter: true,
  },
  
  
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Production cost',
    accessor: 'productionCost',
  },
  {
    Header: 'Sale price',
    accessor: 'salePrice',
  },
  {
    Header: 'Status',
    accessor: 'authenticated',
    disableGlobalFilter: true,
  },
  {
    Header: 'Account',
    accessor: 'account',
    disableGlobalFilter: true,
  },
  {
    Header: 'Enterprise',
    accessor: 'enterprse',
    disableGlobalFilter: true,
  },
  
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
  },
];

const ProductList = ({ 
  getAllProduct, updateProductStatus, getProductEnterpriseHelper, getProductHelper,
 }) => {
  const [data, setData] = useState([]);
  
  const [count, setCount] = useState('');
  const [viewModal, setViewModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(false); 
  const [allArea, setAllArea] = useState([]);

  const [pageOptions, setPageOptions] = useState(null); 
  const [page, setPage] = useState(1); 
  const [name, setName] = useState(''); 
  const [userId, setUserId] = useState(''); 
  const [email, setEmail] = useState(''); 
  const [status, setStatus] = useState(''); 
  const [ds, setDS] = useState(''); 
  const [de, setDE] = useState(''); 
  const [mobilenumber, setMobileNumber] = useState(''); 
  const [accounts, setAccounts] = useState(''); 
  const [enterprise, setEnterprise] = useState('');
  const [product, setProduct] = useState([]);
  const [productfun, setProductfun] = useState([]);
  const [unionCode, setUnionCode] = useState('');
  const [allDistrict, setAllDistrict] = useState(null);
  const [district, setDistrict] = useState('');
  const [upazilla, setUpazilla] = useState('');
  const [allupazilla, setAllupazilla] = useState(null);
  const [allunion, setAllunion] = useState(null);
  const [allunionOption, setAllunionOption] = useState(null);

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const vtoggle = () => {
    setViewModal(prevState => !prevState);
  };

  const getData = async () => {
  const res = await getAllProduct(page, name, enterprise, status, accounts, ds, de);
  const producthelp = await getProductHelper();
  console.log(res);
  // console.log(enterpriseall);
  // const helper_data = await getOrganizationHelper();
    setProduct(producthelp?.enterprise);
    setData(res?.list);
    // console.log(res);
    setCount(res?.count);

    const boundary = Math.ceil(res?.count / 20);
    
    const pageNumber = [];
      for (let i = 0; i < boundary; i += 1) {
      pageNumber.push({
        value: i + 1,
        label: i + 1, 
      });
    }

    setPageOptions(pageNumber);
  };
  
  const handleDownload = async () => {
    const res = await getAllProduct(page, name, mobilenumber, status, email, ds, de);
  };
  // console.log(data);
  useEffect(() => {
    getData();
  }, []);

  const createOption = (value, label) => {
    const a = '';
    return { value, label };
  };

  const handleStatus = async (val, id) => {
    const res = await updateProductStatus(val, id);
    if (res) {
      getData();
    }
  };
  
  const createData = (id, name, productionCost, salePrice, authenticated, account, enterprse, action) => {
    const a = 0;
    return { 
      id, name, productionCost, salePrice, authenticated, account, enterprse, action,
    };
  };
  
  const rows = [...
    data
      ? data?.map((item, i) => createData(
        i + 1,  
        item?.name, 
        item?.production_cost ? item?.production_cost : '', 
        item?.sale_price, 
        <>
          {item?.active 
          ? (
            <> 
              <Tag color="#87d068">Active</Tag> &nbsp;
              <Tag size="sm" color="#f50" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(false, item?.id); }}>Disable</Tag> 
            </>
          ) 
          : (
            <>
              <Tag size="sm" color="#f50">Inactive</Tag> &nbsp;
              <Tag size="sm" color="#87d068" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(true, item?.id); }}>Activate</Tag> 
            </>
            )}
        </>,
        item?.account?.name, 
        item?.enterprise?.name, 
        <>
          <Tag 
            color="#D3BF2A" 
            style={{ cursor: 'pointer' }} 
            onClick={() => history.push(`/product/edit?id=${item?.id}`)}
          >
            Edit
          </Tag> &nbsp;
          <Tag 
            color="#108ee9" 
            style={{ cursor: 'pointer' }} 
            onClick={() => { vtoggle(item?.id); setId(item?.id); }}
          >
            View
          </Tag> &nbsp;
          {/* <Tag color="#108ee9" style={{ cursor: 'pointer' }} onClick={() => { setId(item?.id); toggle(); }}>Change Password</Tag> */}
        </>,
        ))
      : '',
  ];

  const fields = [
    {
      type: 'password', 
      label: 'Password', 
      name: 'password', 
      placeholder: '8 Digit Password', 
      rules: { 
        required: 'This is required field', 
        pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
      },
    },
  ];

  const handleSearch = async (event) => {
    const res = await getAllProduct(page, name, enterprise, status, accounts, ds, de);
    setData(res?.list);
    setCount(res?.count);
  };
  const handleResetN = async (event) => {
    const res = await getAllProduct(1, '', '', '', '', '', '');
    setPage('1');
    setName('');
    setDE('');
    setDS('');
    setAccounts('');
    setEnterprise('');
    setStatus('');
    setData(res?.list);
    setCount(res?.count);
  };

  const handlePageSearch = async (e) => {
    const res = await getAllProduct(e, name, enterprise, status, accounts, ds, de);
    setData(res?.list);
    setCount(res?.count);
  };

  const handleReset = () => {
    setPage('1');
    setName('');
    setDE('');
    setDS('');
    setAccounts('');
    setEnterprise('');
    setStatus('');
    getData('1', '', '', '', '', '', '');
  };
  
  const handleEnterprise = async (e) => {
    const id = e.target.value;
    setEnterprise(id); 
    const res = await getProductEnterpriseHelper(e.target.value);
    setProductfun(res);
  };

  // unionOption = (
  //   unions ? unions?.map(item => createOption(item.union_id, item.name))
  // : null);
  
  return (
    <Container>
      <Row>
        <PageHeader
          ghost={false}
          style={{ background: '#f2f4f7' }}
          onBack={() => window.history.back()}
          title="Back"
        />
        <Pageheader title="Product management" subTitle="Manage all kind of product operations" />
        <Form inline style={{ padding: '0px 25px' }}>

          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="name" className="mr-sm-2">Name </Label>
            <Input bsSize="sm" value={name} type="input" id="name" placeholder="" onChange={(e) => { setName(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="a">Active &nbsp; </Label>
            <Input bsSize="sm" value={status} type="select" name="active" id="a" onChange={(e) => { setStatus(e.target.value); }}>
              <option value="">Select One</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="ent">Enterprise &nbsp; </Label>
            <Input 
              bsSize="sm" 
              value={enterprise} 
              type="select" 
              name="enterprise" 
              id="ent" 
              onChange={async (e) => { handleEnterprise(e); }}
            >
              <option value="">Select One</option>
              {
              product?.map(item => (
                <option value={item.id}>{item.name}</option>
                  
                ))
              }
              
             
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="ac">Account &nbsp; </Label>
            <Input bsSize="sm" value={accounts} type="select" name="account" id="ac" onChange={(e) => { console.log(e); setAccounts(e.target.value); }}>
              <option value="">Select One</option>
              {
              productfun?.account?.map(item => (
                <option value={item.id}>{item.name}</option>
                  
                ))
              }
              
              
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="ds" className="mr-sm-2">Start Date </Label>
            <Input bsSize="sm" value={ds} type="date" name="dates" id="st" onChange={(e) => { setDS(e.target.value); }} />
          </FormGroup>
          
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="de" className="mr-sm-2">End Date </Label>
            <Input bsSize="sm" value={de} type="date" name="datee" id="ste" onChange={(e) => { setDE(e.target.value); }} />
          </FormGroup>
          
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mt-sm-4">
            <Button color="primary" type="button" size="sm" onClick={handleSearch}>Search</Button>&nbsp;
            {/* <Button color="primary" type="button" size="sm" onClick={handleDownload}>Download</Button>&nbsp; */}
            <Button color="danger" type="button" size="sm" onClick={handleResetN}>Reset</Button>
          </FormGroup>
        </Form>
        <Table 
          columns={columns} 
          rows={rows} 
          title="Product List" 
          subTitle="All Product" 
          formTitle="Create a new Product" 
          samePage={false} 
          route="/product/create"
          count={count}
          current={pageOptions}
          handlePageSearch={handlePageSearch}
          isResizable={!false}
        />
      </Row>
       
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Change Password</h4>
        </div>
        {/* <div className="modal__body">
          <EditForm isAboveError fiels={fields} submitFnc={updateAgentPassword} id={id} closeFnc={toggle} />
        </div> */}
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
      
      {viewModal ? <ViewProduct id={id} toggle={vtoggle} open={viewModal} /> : null}
    </Container>
  );
};

export default connect(null, { 
  updateProductStatus, getProductEnterpriseHelper, getAllProduct, getProductHelper,
 })(ProductList);
