import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AgentList from '../../../../pages/agent';
import CreateAgent from '../../../../pages/agent/createAgent';
import EditAgent from '../../../../pages/agent/editAgent';
import ViewOrganization from '../../../../pages/organization/view';
import OrganizationList from '../../../../pages/organization';
import createOrganization from '../../../../pages/organization/createOrganization';
import editOrganization from '../../../../pages/organization/editOrganization';

export default () => (
  <Switch>
    <Route exact path="/enterprise/list" component={OrganizationList} />
    <Route exact path="/enterprise/create" component={createOrganization} />
    <Route exact path="/enterprise/edit" component={editOrganization} />
    <Route exact path="/enterprise/details" component={ViewOrganization} />
  </Switch>
);
