import React, { useEffect, useState } from 'react';
import { 
  Badge, Button, ButtonToolbar, Container, Modal, Row,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
import { Tag, PageHeader } from 'antd';
import { connect } from 'react-redux';

import { 
  deleteSubject,
  getAllSubject,
} from '../../redux/actions/apis/subject';
import { 
  getAllTicket,
} from '../../redux/actions/apis/ticket';
import Pageheader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import EditForm from '../../global/editForm';

import ViewAgent from './view';

const columns = [
  {
    Header: '#SL',
    accessor: 'sl',
    disableGlobalFilter: true,
  },
  {
    Header: 'Subject Name',
    accessor: 'name',
  },
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
  },
];

const SubjectList = ({ 
  getAllSubject, deleteSubject,
 }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState('');
  const [viewModal, setViewModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(false); 
  const [pageOptions, setPageOptions] = useState(null); 
  const [page, setPage] = useState(1); 
  const [name, setName] = useState(''); 
  const [userId, setUserId] = useState(''); 
  const [email, setEmail] = useState(''); 
  const [status, setStatus] = useState(''); 
  const [ds, setDS] = useState(''); 
  const [de, setDE] = useState(''); 
  const [delModal, setDelModal] = useState(false); 
  const [mobilenumber, setMobileNumber] = useState(''); 


  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const vtoggle = () => {
    setViewModal(prevState => !prevState);
  };
  const delToggle = () => {
    setDelModal(prevState => !prevState);
  };

  const getData = async () => {
  const res = await getAllSubject();

    setData(res?.list);
    setCount(res?.count);

    const boundary = Math.ceil(res?.count / 20);
    
    const pageNumber = [];
      for (let i = 0; i < boundary; i += 1) {
      pageNumber.push({
        value: i + 1,
        label: i + 1, 
      });
    }
    setPageOptions(pageNumber);
  };
  
  useEffect(() => {
    getData();
  }, []);


  const handleDelete = async (id) => {
    const res = await deleteSubject(id);
    if (res) {
      getData();
    }
  };
  const createData = (sl, name, action) => {
    const a = 0;
    return { 
      sl, name, action,
    };
  };
  
  const rows = [...
    data
      ? data?.map((item, i) => createData(
        i + 1,
        item?.name, 
        <>
          <Tag 
            color="#D3BF2A" 
            style={{ cursor: 'pointer' }} 
            onClick={() => history.push(`/subject/edit?id=${item?.id}`)}
          >
            edit
          </Tag> &nbsp;
          <Tag color="#F23033" style={{ cursor: 'pointer' }} onClick={() => { handleDelete(item?.id); }}>Delete</Tag> &nbsp;
        </>,
        ))
      : '',
  ];


  return (
    <Container>
      <PageHeader
        ghost={false}
        style={{ background: '#f2f4f7' }}
        onBack={() => window.history.back()}
        title="Back"
      />
      <Pageheader title="Support Management" subTitle="Manage all kind of subject operations" />
      <Table 
        columns={columns} 
        rows={rows} 
        title="Subject User List" 
        subTitle="All Subject " 
        formTitle="Create a new Subject" 
        samePage={false} 
        route="/subject/create"
        count={count}
        current={pageOptions}
        isResizable={!false}
      />
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Change Password</h4>
        </div>
        <div className="modal__body">
          <EditForm isAboveError submitFnc={handleDelete} id={id} closeFnc={delToggle} />
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
      
      {viewModal ? <ViewAgent id={id} toggle={vtoggle} open={viewModal} /> : null}
    </Container>
  );
};

export default connect(null, { 
  getAllSubject, deleteSubject,
 })(SubjectList);
