import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import MainWrapper from '../MainWrapper';
import Landing from '../../Landing/index';
import NotFound404 from '../../DefaultPage/404/index';
import LockScreen from '../../Account/LockScreen/index';
import LogIn from '../../Account/LogIn/index';
import LogInPhoto from '../../Account/LogInPhoto/index';
import Register from '../../Account/Register/index';
import RegisterPhoto from '../../Account/RegisterPhoto/index';
import ResetPassword from '../../Account/ResetPassword/index';
import ResetPasswordPhoto from '../../Account/ResetPasswordPhoto';
import WrappedRoutes from './WrappedRoutes';
import AlertBox from '../../../global/alertBox';
import ErrorPage from '../../../pages/others/errorPage';
import Loader from '../../../pages/others/loader';
import PrivacyPolicy from '../../../pages/privacyPolicy';

const Router = ({ 
  errorMsg, valMsg, success, loading, authError, 
}) => (
  <MainWrapper>
    {valMsg ? valMsg?.map(item => <AlertBox type="danger" value={item?.message} />) : null}
    {authError ? <AlertBox type="danger" value={authError} /> : null}
    {errorMsg ? <AlertBox type="danger" value={errorMsg} /> : null}
    {success ? <AlertBox type="success" value={success} /> : null}
    {/* {!loading ? <Loader /> : null} */}
    <main>
      <Switch>
        <Route path="/404" component={NotFound404} />
        <Route path="/lock_screen" component={LockScreen} />
        <Route path="/log_in" component={LogIn} />
        <Route path="/logIn" component={LogInPhoto} />
        <Route path="/register" component={Register} />
        <Route path="/register_photo" component={RegisterPhoto} />
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/reset_password_photo" component={ResetPasswordPhoto} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/err" component={ErrorPage} />
        <Route path="/" component={WrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default connect(state => ({
  valMsg: state?.message?.authValidationError,
  authError: state?.message?.authError,
  errorMsg: state?.message?.error,
  success: state?.message?.success,
  loading: state?.message?.loading,
}))(Router);
