import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { 
  Button, Form, Divider, Descriptions, Select, Input, PageHeader, Tooltip, Checkbox, Space,
} from 'antd';
import { 
  Content, 
} from 'antd/lib/layout/layout';
import { createOrganizationdb } from '../../redux/actions/apis/organization';

const { TextArea } = Input;

const CreateTicket = ({ createOrganizationdb }) => {
    const [unions, setUnions] = useState([]);
    const [ent, setEnt] = useState([]);
    const [enterpriseadmin, setenterpriseAdmin] = useState('');
    const [StatusType, setStatusType] = useState(null);
    const [datahelpn, setDataHelp] = useState('');
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [form] = Form.useForm();
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    const onFinish = async (values) => {
      const code = await createOrganizationdb(values);
    };
    
    return ( 
      <Content style={{ background: 'white' }}>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Enterprise Create"
          subTitle="Enterprise Management"
        />
        <Divider>Enterprise Create</Divider>
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} name="dynamic_form_nest_item" onFinish={onFinish} form={form} autoComplete="off">
          <Form.Item
            label="Name" 
            name="name"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Mobile Number" 
            name="mobile_number"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
              {
                pattern: /^(01)[0-9][0-9]{8}$/,
                message: 'Please valid phone number!',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input placeholder="mobile number" />
          </Form.Item>
          <Form.Item
            label="Email" 
            name="email"
            rules={[
              {
                required: true,
                message: 'Field is required',
                pattern: {
                  value: new RegExp(/\S+@\S+\.\S+/),
                  message: 'Email is not valid',
                },
              },
              
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input placeholder="email" />
          </Form.Item>
          <Form.Item 
            label="Status"
            name="active"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Select>
              <Select.Option value="true">Active</Select.Option>
              <Select.Option value="false">Inactive</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Address" 
            name="address"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            label="Description" 
            name="description"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <TextArea />
          </Form.Item>
          
          <Form.List name="contact_person">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: 2,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'number']}
                      rules={[
                        {
                          pattern: /^(01)[0-9][0-9]{8}$/,
                          message: 'Please valid phone number!',
                        },
                      ]}
                      
                      
                    >
                      <Input placeholder="Mobile Number" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'email']}
                      // rules={[
                      //   {
                      //     pattern: /^(01)[0-9][0-9]{8}$/,
                      //     message: 'Please insert Valid email!',
                      //   },
                      // ]}
                      
                      
                    >
                      <Input placeholder="email" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      // rules={[
                      //   {
                      //     pattern: /^(01)[0-9][0-9]{8}$/,
                      //     message: 'Please insert Valid email!',
                      //   },
                      // ]}
                      
                      
                    >
                      <Input placeholder="description" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button style={{ display: 'flex', justifyContent: 'center' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Contact Persons
                  </Button>
                </Form.Item>
              </>
          )}
          </Form.List>
          <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Content>
     );
};
 
export default connect(null, { createOrganizationdb })(CreateTicket);
