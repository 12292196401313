// ======================Auth==
export const LOGIN = 'LOGIN';
            // ==success==
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
            // ==Error==
export const AUTH_ERROR = 'AUTH_ERROR';
// ========================================Message
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
// =========================Loader
export const LOADING = 'LOADING';
export const LOADED = 'LOADED';
// =========================RESET
export const RESET_MESSAGES = 'RESET_MESSAGES';
export const RESET_SUCCESS = 'RESET_SUCCESS';

// =======================New Errror=================
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const VALIDATION_AUTH_ERROR = 'VALIDATION_AUTH_ERROR';
