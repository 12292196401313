import React from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { SelectField } from '@/shared/components/form/Select';
import { emailPatter, urlPattern } from '@/shared/helpers';
import showResults from '@/utils/showResults';
import PasswordField from '@/shared/components/form/Password';
import { connect } from 'react-redux';
import { login } from '../../../redux/actions/apis/auth';
import { FormField } from '../../Form/ReactHookForm/FormHookValidation/components/FormField';
import AlertBox from '../../../global/alertBox';
import './style.css';

const LogInPhoto = ({ 
  isHorizontal, isAboveError, login, errorMsg, valMsg, successMsg, 
}) => {
  const { t } = useTranslation('common');
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = data => (
    login(data)
  );

  return (
    <>
      {/* {valMsg ? valMsg?.map(item => <AlertBox />) : null}
      {errorMsg ? <AlertBox type="danger" value={errorMsg} /> : null}
      {successMsg ? <AlertBox type="success" value={successMsg} /> : null} */}
      <div className="loginContainer">
        <img src="./gov.png" alt="" className="gov" />
        <br />
        <br />
        <form className={`form ${isHorizontal && 'form--horizontal'} loginform`} onSubmit={handleSubmit(onSubmit)}>
          <img src="./logo.png" alt="" className="formLogo" />
          <div className="form__form-group">
            <span className="form__form-group-label">User Id</span>
            <div className="form__form-group-field">
              <FormField
                name="user_id"
                control={control}
                component="input"
                errors={errors}
                rules={{ 
                  required: 'This is required field',
                }}
                defaultValue=""
                isAboveError={isAboveError}
                placeholder="User Id"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <PasswordField
                    input={field}
                    meta={{ touched: !!fieldState.error, error: fieldState.error?.message }}
                    placeholder="Password"
                    isAboveError={isAboveError}
                  />
                )}
                rules={{ 
                  required: 'This is required field', 
                  // pattern: { 
                  //   value: /^[A-Za-z\d@$!%*?&]{8,50}$/,
                  //   message: 'Minimum 8 character, allowed special characters are @$!%*?&',
                  // },
                }}
                defaultValue=""
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">Login</Button>
            <Button
              type="button"
              onClick={() => reset({
                email: '',
                password: '',
              })}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        </form>
        <br />
        <div className="imageG">
          <img src="./sw.png" alt="" className="bimg" /> &nbsp; &nbsp; &nbsp;
          <img src="./marico.png" alt="" className="mimg" /> &nbsp; &nbsp; &nbsp;
          <img src="./undp.png" alt="" className="undpimg" />
        </div>
      </div>
    </>
  );
};

export default connect(state => ({
  valMsg: state?.message?.authValidationError,
  errorMsg: state?.message?.authError,
  successMsg: state?.message?.authSuccess,
}), { login })(LogInPhoto);

