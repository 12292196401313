import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { 
  Button, Form, Modal, Divider, Descriptions, Select, Input, PageHeader, Tooltip, Checkbox, Space,
} from 'antd';
import { 
    Content, 
  } from 'antd/lib/layout/layout';
import { reevaluteAccount, getAccountHelper, getAccountById } from '../../redux/actions/apis/account';


const EditAccountReEvaluate = ({ 
  getAccountById, getAccountHelper, reevaluteAccount, location,
  }) => {
    const [unions, setUnions] = useState([]);
    const [ent, setEnt] = useState([]);
    const [enterpriseadmin, setenterpriseAdmin] = useState('');
    const [StatusType, setStatusType] = useState(null);
    const [datahelpn, setDataHelp] = useState('');
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [data, setData] = useState(null);
    const [ID, setID] = useState(null);
    const [form] = Form.useForm();
    let enterprise = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      // const res = await getOrganizationHelper();
      const enterpriseall = await getAccountHelper();
      setEnt(enterpriseall);
      const search = location?.search;
      const id = new URLSearchParams(search).get('id');
      setID(id);
      const result = await getAccountById(id);
      setData(result);
      console.log(result);
      if (result) {
        form.setFieldsValue({
          owners_equity: result?.detail?.owners_equity,
          asset: result?.detail?.asset,
          payable: result?.detail?.payable,
          receivable: result?.detail?.receivable,
          inventory: result?.detail?.inventory,
          remark: result?.detail?.remark,
          bank: result?.detail?.bank,
          cash: result?.detail?.cash,
          mfs: result?.detail?.mfs,
        
        });
      }
      // setUnions(res);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    enterprise = (
      ent?.enterprise?.length > 0 ? ent?.enterprise?.map(item => createOption(item.id, item.name))
    : null);
    const onFinish = async (values) => {
      const code = await reevaluteAccount(values, ID);
      console.log(code);
      if (code === 200) {
        form.resetFields();
      }
      
      console.log('Received values of form:', values);
    };
    
    return ( 
      <Content style={{ background: 'white' }}>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Account ReEvaluate"
          subTitle="Enterprise Management"
        />
        <Divider>Re-Evaluate</Divider>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} name="dynamic_form_nest_item" onFinish={onFinish} form={form} autoComplete="off">
          <Form.Item
            label="Owners Equity" 
            name="owners_equity"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Asset" 
            name="asset"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Payable" 
            name="payable"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
              
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Receivable" 
            name="receivable"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Inventory" 
            name="inventory"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input type="number" />
          </Form.Item>
          
          <Form.Item
            label="Bank" 
            name="bank"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Cash" 
            name="cash"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Mfs" 
            name="mfs"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Remark" 
            name="remark"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Field is required',
            //   },
            // ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input />
          </Form.Item>
        
          <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Content>
     );
};
 
export default connect(null, { reevaluteAccount, getAccountHelper, getAccountById })(EditAccountReEvaluate);
