import React from 'react';
import { 
    Col, Row,
    Button,
    ButtonToolbar,
    Card,
    CardBody,
  } from 'reactstrap';
  import { useTranslation } from 'react-i18next';
  import { useForm, Controller } from 'react-hook-form';
  import { SelectField } from '@/shared/components/form/Select';
  import { emailPatter, urlPattern } from '@/shared/helpers';
  import showResults from '@/utils/showResults';
  import PasswordField from '@/shared/components/form/Password';
  import styled from 'styled-components';
  import PropTypes from 'prop-types';
  import { FormField } from './FormField';

  const Container = styled.div`
    width: 600px;
    padding: 10px;
  @media (max-width: 768px) {
    width: 300px;
  }
  `;

const FloatForm = ({ 
    isHorizontal, isAboveError, closeFnc, title, subtitle,
 }) => {
  const { t } = useTranslation('common');
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const onSubmit = data => showResults(data);
  return (
    <Container style={{ 
        position: 'fixed', zIndex: '1000', backgroundColor: '#4c4c4c', right: '0', top: '60px', height: '100vh', transition: '1s',
          }}
    >
      <Row>
        <Col md={12}>
          <h3 className="page-title" style={{ color: '#FFF' }}>{ title }</h3>
          <h3 className="page-subhead subhead" style={{ color: '#FFF' }}>{ subtitle }</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Form</h5>
                <h5 className="subhead">
                  fill up carefully
                </h5>
              </div>
              <form className={`form ${isHorizontal && 'form--horizontal'}`} onSubmit={handleSubmit(onSubmit)}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Username</span>
                  <div className="form__form-group-field">
                    <FormField
                      name="username"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{ required: 'This is required field' }}
                      defaultValue=""
                      isAboveError={isAboveError}
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">E-mail</span>
                  <div className="form__form-group-field">
                    <FormField
                      name="email"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        required: 'This is required field',
                        pattern: {
                        value: emailPatter,
                        message: 'Entered value does not match email format',
                        },
                    }}
                      defaultValue=""
                      isAboveError={isAboveError}
                      placeholder="example@gmail.com"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Url</span>
                  <div className="form__form-group-field">
                    <FormField
                      name="url"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        required: 'This is required field',
                        pattern: {
                        value: urlPattern,
                        message: 'invalid url',
                        },
                      }}
                      placeholder="https://themeforest.com"
                      defaultValue=""
                      isAboveError={isAboveError}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field, fieldState }) => (
                        <PasswordField
                          input={field}
                          meta={{ touched: !!fieldState.error, error: fieldState.error?.message }}
                          placeholder="Password"
                          isAboveError={isAboveError}
                        />
                      )}
                      rules={{ required: 'This is required field' }}
                      defaultValue=""
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Select Option</span>
                  <div className="form__form-group-field">
                    <FormField
                      name="select"
                      control={control}
                      component={SelectField}
                      errors={errors}
                      options={[
                        { value: 'one', label: 'One' },
                        { value: 'two', label: 'Two' },
                      ]}
                      rules={{ required: 'This is required field' }}
                      defaultValue=""
                      isAboveError={isAboveError}
                    />
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">Submit</Button>
                  <Button
                    type="button"
                    onClick={() => { 
                        reset({
                    username: '',
                    email: '',
                    url: '',
                    password: '',
                    select: '',
                    });
                    closeFnc();
                    }}
                  >
                    Close
                  </Button>
                </ButtonToolbar>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

FloatForm.propTypes = {
    isHorizontal: PropTypes.bool,
    isAboveError: PropTypes.bool,
  };
  
  FloatForm.defaultProps = {
    isHorizontal: false,
    isAboveError: false,
  };

export default FloatForm;
