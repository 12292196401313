import React, { useEffect, useState } from 'react';
import { 
  Button, ButtonToolbar, Container, Modal,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { 
  Col, Row, Statistic, Badge, Descriptions, Timeline, Divider, List, Typography, Tag,
 } from 'antd';
import { connect } from 'react-redux';
import { 
  getOrganizationUserById, 
} from '../../redux/actions/apis/organizationuser';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';


const ViewOrganization = ({ 
  getOrganizationUserById, toggle, open, id,
 }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getOrganizationUserById(id);
    setData(res?.detail);
  };

  useEffect(() => {
    getData();
  }, []);

  
  return (
    <Container>
      <Modal
        isOpen={open}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
        size="lg" 
        style={{ maxWidth: '90%', width: '100%' }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          
          <h4 style={{ color: '#1a0e75', fontSize: '30px' }} className="text-modal  modal__title">Enterprise user Details</h4>
        </div>
        <div style={{ pading: '20px' }}>
          <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Enterprise Info</h4>
          {/* <h4>Subject : {data?.subject} </h4> */}
          <Tag color="#fc0748">Name: {data?.enterprise?.name}</Tag>
          <Tag color="#e1e20b"> Email : {data?.enterprise?.email}</Tag> 
          <Tag color="#07fc51">Mobile Number : {data?.enterprise?.mobile_number}</Tag>
          {/* <Tag color="#db2775">Transfer Amount : {data?.detail?.transfer_amount}</Tag> */}
          {/* <Tag color="#16ceb2">{data?.detail?.created_at}</Tag> */}
          <br />
          <br />
        </div>
        <div className="modal__body">
          <Row gutter={16}>
            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>User Info</h4>
              <Descriptions>
                {/* <Descriptions.Item label="User Id">{data?.user_id}</Descriptions.Item> */}
                <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
                <Descriptions.Item label="Mobile number">{data?.mobile_number}</Descriptions.Item>
                <Descriptions.Item label="Address">{data?.address}</Descriptions.Item>
                <Descriptions.Item label="Email">{data?.email}</Descriptions.Item>
                <Descriptions.Item label="Creator">{data?.creator}</Descriptions.Item>
                <Descriptions.Item label="Account">{data?.account === '[]' ? data?.account : 'null'}</Descriptions.Item>
                <Descriptions.Item label="Active">{data?.authenticated
                  ? <Badge status="success" text="Active" /> : <Badge status="error" text="Inactive" />}
                </Descriptions.Item>
                <Descriptions.Item label="Enterprise Admin">{data?.enterprise_admin
                  ? <Badge status="success" text="Active" /> : <Badge status="error" text="Inactive" />}
                </Descriptions.Item>
                <Descriptions.Item label="Created at">{data?.created_at}</Descriptions.Item>
                <Descriptions.Item label="Update at">{data?.updated_at}</Descriptions.Item>
              </Descriptions>
            </Col>

            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Account Info</h4>
              <br />
              <div>
                {data?.account?.slice(0)?.reverse()?.map((item, id) => (
                  <>
                    <Row>
                      <Col xs={1}>{id + 1} </Col>
                      <Col xs={7}>Name: {item?.name} </Col>
                      <Col xs={8}>Mobile Number: {item?.number} </Col>
                      <Col xs={8}> Email: {item?.email}</Col>
                      
                      
                    </Row>
                    <br />
                  </>
                  
                ))}
              </div>
              
            </Col>
            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Logs</h4>
              <br />
              <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
                {data?.logs?.slice(0)?.reverse()?.map(item => (
                  <Timeline.Item>
                    <p>{item?.message}</p>
                    <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{item?.created_at}</p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col>
            
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    </Container>
  );
};

export default connect(null, { 
  getOrganizationUserById,
 })(ViewOrganization);
