import React, { useEffect, useState } from 'react';
import { 
  Badge, Button, ButtonToolbar, Container, Modal, Row,
 } from 'reactstrap';
 import { Tag, PageHeader } from 'antd';
import { connect } from 'react-redux';
import { 
  getAllRoles, deleteRole, updateURoleStatus, 
} from '../../redux/actions/apis/role';
import { 
  getUsers, updateUserPassword, updateUserStatus, deleteUser, 
} from '../../redux/actions/apis/user';
import Pageheader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import EditForm from '../../global/editForm';

const columns = [
  {
    Header: '#SL',
    accessor: 'id',
    disableGlobalFilter: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Super Admin',
    accessor: 'superAdmin',
  },
  {
    Header: 'Status',
    accessor: 'authenticated',
    disableGlobalFilter: true,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    disableGlobalFilter: true,
  },
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
  },
];

const RoleList = ({ 
  getAllRoles, deleteRole, updateURoleStatus, updateUserPassword, updateUserStatus, deleteUser,
 }) => {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [delModal, setDelModal] = useState(false); 
  const [id, setId] = useState(false); 

  const toggle = () => {
    setModal(prevState => !prevState);
  };
  const delToggle = () => {
    setDelModal(prevState => !prevState);
  };
  
  const getData = async () => {
    const res = await getAllRoles();
    setData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleStatus = async (val, id) => {
    const res = await updateURoleStatus(val, id);
    if (res) {
      getData();
    }
  };

  const handleDelete = async (val, id) => {
    const res = await deleteRole(val, id);
    if (res) {
      getData();
    }
  };
  
  const createData = (id, name, superAdmin, authenticated, createdAt, action) => {
    const a = 0;
    return { 
      id, name, superAdmin, authenticated, createdAt, action,
    };
  };
  const rows = [...
    data
      ? data?.map((item, i) => createData(
        i + 1,
        item?.name,
        item?.super_admin === true ? 'Yes' : 'No',
        <>
          {item?.active 
          ? (
            <> 
              <Tag color="#87d068">Active</Tag> &nbsp;
              <Tag size="sm" color="#f50" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(false, item?.role_id); }}>Disable</Tag> 
            </>
          ) 
          : (
            <>
              <Tag color="#f50">Inactive</Tag> &nbsp;
              <Tag size="sm" color="#87d068" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(true, item?.role_id); }}>Activate</Tag> 
            </>
            )}
        
        </>,
        
        item?.created_at, 
        <>
          <Tag 
            color="#f50" 
            style={{ cursor: 'pointer' }} 
            onClick={() => history.push(`/user/edit-role?id=${item?.role_id}`)}
          >
            Edit
          </Tag> &nbsp;
          <Tag color="#f50" style={{ cursor: 'pointer' }} onClick={() => { setId(item?.role_id); delToggle(); }}>Delete</Tag> &nbsp;
        </>,
        ))
      : '',
  ];
  
  const fields = [
    {
      type: 'password', 
      label: 'Password', 
      name: 'password', 
      placeholder: '8 Digit Password', 
      rules: { 
        required: 'This is required field', 
        pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
      },
    },
    {
      type: 'password', 
      label: 'Confirm Password', 
      name: 'confirm_password', 
      placeholder: 'Confirm Password', 
      rules: { 
        required: 'This is required field', 
        pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
      },
    },
];
  const delFields = [
    {
      type: 'input', 
      label: 'Remark', 
      name: 'remark', 
      rules: { 
        required: 'This is required field', 
        },
    },
];

  return (
    <Container>
      <Row>
        <PageHeader
          ghost={false}
          style={{ background: '#f2f4f7' }}
          onBack={() => window.history.back()}
          title="Back"
        />
        <Pageheader title="User management" subTitle="Manage all kind of user operations" />
        <Table columns={columns} rows={rows} title="Role List" subTitle="All Roles" formTitle="Create a new role" samePage={false} route="/user/create-role" />
      </Row>
       
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Change Password</h4>
        </div>
        <div className="modal__body">
          <EditForm isAboveError fiels={fields} submitFnc={updateUserPassword} id={id} closeFnc={toggle} />
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
        </ButtonToolbar>
      </Modal>
      
      <Modal
        isOpen={delModal}
        toggle={delToggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={delToggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Delete Role</h4>
        </div>
        <div className="modal__body">
          <EditForm isAboveError fiels={delFields} submitFnc={handleDelete} id={id} closeFnc={delToggle} />
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={delToggle}>Cancel</Button>{' '}
        </ButtonToolbar>
      </Modal>
    </Container>
  );
};

export default connect(null, { 
  getAllRoles, deleteRole, updateURoleStatus, updateUserPassword, updateUserStatus, deleteUser,
 })(RoleList);
