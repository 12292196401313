import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody, Button, Container,
} from 'reactstrap';
import { Tag, PageHeader } from 'antd';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import MicrosoftOfficeIcon from 'mdi-react/MicrosoftOfficeIcon';
import Form from '../../global/form';
import Pageheader from '../../global/pageHeader';
import { createsubject } from '../../redux/actions/apis/subject';

const CreateSubject = ({ createsubject }) => {
    const [roles, setRoles] = useState([]);
    const [layers, setLayers] = useState([]);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    let role = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      // const res = await getAssignableRoles();
      // setRoles(res);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    const closeModal = () => {
      setError('');
      setIsOpen(false);
    };

    const openModal = () => {
      setIsOpen(true);
      setError('');
    };

    role = (
      roles ? roles?.map(item => createOption(item.role_id, item.name))
    : null);
    
 

    const fiels = [
        
        {
          type: 'input', 
          label: 'Subject Name', 
          name: 'name', 
          placeholder: 'Name', 
          rules: { 
            required: 'This is required field', 
            },
        },
        
    ];
    
    
    return ( 
      <Container>
        <PageHeader
          ghost={false}
          style={{ background: '#f2f4f7' }}
          onBack={() => window.history.back()}
          title="Back"
        />
        <Pageheader title="Support Management" subTitle="Manage all kind of subject create operations" />
        <Form isHorizontal isAboveError title="Create a new Subject" fiels={fiels} submitFnc={createsubject} />
      
      </Container>
      
     );
};
 
export default connect(null, { createsubject })(CreateSubject);
