import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AgentList from '../../../../pages/agent';
import CreateAgent from '../../../../pages/agent/createAgent';
import EditAgent from '../../../../pages/agent/editAgent';
import TicketList from '../../../../pages/Ticket';
import createTicket from '../../../../pages/Ticket/createTicket';
import editTicket from '../../../../pages/Ticket/editTicket';

export default () => (
  <Switch>
    <Route exact path="/ticket/list" component={TicketList} />
    <Route exact path="/ticket/create" component={createTicket} />
    <Route exact path="/ticket/details" component={editTicket} />
  </Switch>
);
