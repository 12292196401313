import React, { useEffect, useState } from 'react';
import { 
  Badge, Button, ButtonToolbar, Container, Modal, Row,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { Tag } from 'antd';
import { connect } from 'react-redux';
import { 
  getAgents, updateAgentPassword, updateAgentStatus, getAllUnions, 
} from '../../redux/actions/apis/agent';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import EditForm from '../../global/editForm';
import { areaList } from '../../redux/actions/apis/union';
import ViewAgent from './view';

const columns = [
  {
    Header: '#SL',
    accessor: 'id',
    disableGlobalFilter: true,
  },
  {
    Header: 'User Id',
    accessor: 'userId',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Mobile Number',
    accessor: 'mobileNumber',
  },
  {
    Header: 'Status',
    accessor: 'authenticated',
    disableGlobalFilter: true,
  },
  {
    Header: 'Union',
    accessor: 'union',
    disableGlobalFilter: true,
  },
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
  },
];

const AgentList = ({ 
  getAgents, updateAgentPassword, updateAgentStatus, getAllUnions, areaList, 
 }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState('');
  const [viewModal, setViewModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(false); 
  const [allArea, setAllArea] = useState([]);

  const [pageOptions, setPageOptions] = useState(null); 
  const [page, setPage] = useState(1); 
  const [name, setName] = useState(''); 
  const [userId, setUserId] = useState(''); 
  const [status, setStatus] = useState(''); 
  const [union, setUnion] = useState('');
  const [unionCode, setUnionCode] = useState('');
  const [allDistrict, setAllDistrict] = useState(null);
  const [district, setDistrict] = useState('');
  const [upazilla, setUpazilla] = useState('');
  const [allupazilla, setAllupazilla] = useState(null);
  const [allunion, setAllunion] = useState(null);
  const [allunionOption, setAllunionOption] = useState(null);

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const vtoggle = () => {
    setViewModal(prevState => !prevState);
  };

  const getData = async () => {
  const res = await getAgents(page, name, userId, status, union, unionCode, district, upazilla, false);
    setData(res?.list);
    setCount(res?.count);
    const boundary = Math.ceil(res?.count / 20);
    
    const pageNumber = [];
      for (let i = 0; i < boundary; i += 1) {
      pageNumber.push({
        value: i + 1,
        label: i + 1, 
      });
    }

    setPageOptions(pageNumber);

    const dis = [];
    const upz = [];
    const uni = [];
    const code = [];

    const area = await areaList();
    setAllArea(area);

    for (let i = 0; i < area.length; i += 1) {
      const element = area[i];
      dis.push(element?.district);
    }
    setAllDistrict([...new Set(dis)]);

    for (let i = 0; i < area.length; i += 1) {
      const element = area[i];
      upz.push(element?.upazilla);
    }
    setAllupazilla([...new Set(upz)]);
    
    for (let i = 0; i < area.length; i += 1) {
      const element = area[i];
      uni.push(`${area[i]?.union}-${area[i]?.union_code}`);
      // code.push({ value: element?.union_code, label: element?.union });
      code.push([element?.union_code]);
    }
    setAllunion([...uni]);
    setAllunionOption(code);
  };
  
  const handleDownload = async () => {
    const res = await getAgents(page, name, userId, status, union, unionCode, district, upazilla, true);
  };

  useEffect(() => {
    getData();
  }, []);

  const createOption = (value, label) => {
    const a = '';
    return { value, label };
  };

  const handleStatus = async (val, id) => {
    const res = await updateAgentStatus(val, id);
    if (res) {
      getData();
    }
  };
  
  const createData = (id, userId, name, email, mobileNumber, authenticated, union, action) => {
    const a = 0;
    return { 
      id, userId, name, email, mobileNumber, authenticated, union, action,
    };
  };
  
  const rows = [...
    data
      ? data?.map((item, i) => createData(
        i + 1, 
        item?.user_id ? item?.user_id : '', 
        item?.name, 
        item?.email ? item?.email : '', 
        item?.mobile_number, 
        <>
          {item?.authenticated 
          ? (
            <> 
              <Tag color="#87d068">Active</Tag> &nbsp;
              <Tag size="sm" color="#f50" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(false, item?.user_id); }}>Disable</Tag> 
            </>
          ) 
          : (
            <>
              <Tag size="sm" color="#f50">Inactive</Tag> &nbsp;
              <Tag size="sm" color="#87d068" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(true, item?.user_id); }}>Activate</Tag> 
            </>
            )}
        </>,
        item?.union?.map(i => (
          <span color="primary">
            {i?.name}({i?.union_code}),&nbsp;
          </span>
        )), 
        <>
          <Tag 
            color="#f50" 
            style={{ cursor: 'pointer' }} 
            onClick={() => history.push(`/agent/edit?id=${item?.user_id}`)}
          >
            Edit
          </Tag> &nbsp;
          <Tag 
            color="#108ee9" 
            style={{ cursor: 'pointer' }} 
            onClick={() => { vtoggle(item?.user_id); setId(item?.user_id); }}
          >
            View
          </Tag> &nbsp;
          <Tag color="#108ee9" style={{ cursor: 'pointer' }} onClick={() => { setId(item?.user_id); toggle(); }}>Change Password</Tag>
        </>,
        ))
      : '',
  ];

  const fields = [
    {
      type: 'password', 
      label: 'Password', 
      name: 'password', 
      placeholder: '8 Digit Password', 
      rules: { 
        required: 'This is required field', 
        pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
      },
    },
  ];

  const handleSearch = async (event) => {
    const res = await getAgents(page, name, userId, status, union, unionCode, district, upazilla, false);
    setData(res?.list);
    setCount(res?.count);
  };

  const handlePageSearch = async (e) => {
    const res = await getAgents(e, name, userId, status, union, unionCode, district, upazilla, false);
    setData(res?.list);
    setCount(res?.count);
  };

  const handleReset = () => {
    setPage('1');
    setName('');
    setUserId('');
    setStatus('');
    setUnion('');
    setUnionCode('');
    setDistrict('');
    setUpazilla('');
    getData('1', '', '', '', '', '', '', false);
  };
  
  const getUpazilla = (val) => {
    setName('');
    setUnionCode('');
    setUpazilla('');
    setDistrict(val);
    const upa = [];
    for (let i = 0; i < allArea.length; i += 1) {
      const element = allArea[i]?.district;
      if (element === val) {
        upa.push(allArea[i]?.upazilla);
      }
    }
    setAllupazilla([...new Set(upa)]);
    setAllunion(null);
    setAllunionOption(null);
  };

  const getUnion = (val) => {
    setUnionCode('');
    setName('');
    setUpazilla(val);
    const un = [];
    for (let i = 0; i < allArea.length; i += 1) {
      const element = allArea[i]?.upazilla;

      if (element === val) {
        un.push(allArea[i]?.union);
      }
    }
    setAllunion([...new Set(un)]);
    setAllunionOption(null);
  };

  const getAllCode = (val) => {
    setName(val);
    const co = [];
    for (let i = 0; i < allArea.length; i += 1) {
      const element = allArea[i]?.union;
      if (element === val) {
        co.push(allArea[i]?.union_code);
      }
    }
    setAllunionOption([...new Set(co)]);
  };

  const getCode = (val) => {
    setUnion(val);
    const co = [];
    for (let i = 0; i < allArea.length; i += 1) {
      const element = allArea[i]?.union_code;
      if (element === val.split('-')[1]) {
        setAllunionOption([allArea[i]?.union_code]);
        setUnionCode(allArea[i]?.union_code);
        break;
      } else setUnionCode('');
    }
  };

  const getName = (val) => {
    setUnionCode(val);
    for (let i = 0; i < allArea.length; i += 1) {
      const element = allArea[i]?.union_code;

      if (element === val) {
        setUnion(allArea[i]?.union);
        break;
      } else setUnion('');
    }
  };
  
  // unionOption = (
  //   unions ? unions?.map(item => createOption(item.union_id, item.name))
  // : null);
  
  return (
    <Container>
      <Row>
        <PageHeader title="Agent management" subTitle="Manage all kind of agent operations" />
        <Form inline style={{ padding: '0px 25px' }}>
          
          {/* <FormGroup>
            <Label for="p">Page &nbsp; </Label>
            <Input bsSize="sm" value={page} type="select" id="p" onChange={(e) => { setPage(e.target.value); }}>
              <option value="1">Select Page Number</option>
              {pageOptions?.length > 0 
              ? pageOptions.map(item => (
                <option value={item?.value}>{item?.label}</option>
                ))
              : <option value="1">1</option>}
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp; */}
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="name" className="mr-sm-2">Name </Label>
            <Input bsSize="sm" value={name} type="input" id="name" placeholder="" onChange={(e) => { setName(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="user" className="mr-sm-2">User Id </Label>
            <Input bsSize="sm" type="input" value={userId} id="user" placeholder="" onChange={(e) => { setUserId(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="name" className="mr-sm-2">District </Label>
            <Input bsSize="sm" value={district} type="select" id="district" onChange={(e) => { getUpazilla(e.target.value); }}>
              <option value="1">Select District</option>
              {allDistrict?.length
              ? allDistrict?.map(item => (
                <option value={item}>{item}</option>
              ))
              : null}
            </Input>
          </FormGroup>

          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="upazilla" className="mr-sm-2">Upazilla </Label>
            <Input bsSize="sm" value={upazilla} name="upazilla" type="select" id="upazilla" onChange={(e) => { getUnion(e.target.value); }}>
              <option value="1">Select Upazilla</option>
              {allupazilla?.length
              ? allupazilla?.map(item => (
                <option value={item}>{item}</option>
              ))
              : null}
            </Input>
          </FormGroup>

          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="union" className="mr-sm-2">Union </Label>
            <Input bsSize="sm" name="union" value={union} type="select" id="union" onChange={(e) => { getCode(e.target.value); }}>
              <option value="1">Select Union</option>
              {allunion?.length
              ? allunion.map(item => (
                // console.log(item)
                <option value={item}>{(item?.split('-'))[0]}</option>
              ))
              : null}
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="union">Union Code &nbsp; </Label>
            <Input bsSize="sm" value={unionCode} type="select" id="unioncode" name="unioncode" onChange={(e) => { getName(e.target.value); }}>
              <option value="1">Select Union</option>
              {allunionOption?.length > 0 
              ? allunionOption.map((item, key) => (
                <option value={item}>{item}</option>
                ))
              : null}
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="a">Status &nbsp; </Label>
            <Input bsSize="sm" value={status} type="select" name="select" id="a" onChange={(e) => { setStatus(e.target.value); }}>
              <option value="">Select One</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mt-sm-4">
            <Button color="primary" type="button" size="sm" onClick={handleSearch}>Search</Button>&nbsp;
            <Button color="primary" type="button" size="sm" onClick={handleDownload}>Download</Button>&nbsp;
            <Button color="danger" type="button" size="sm" onClick={handleReset}>Reset</Button>
          </FormGroup>
        </Form>
        <Table 
          columns={columns} 
          rows={rows} 
          title="Agent List" 
          subTitle="All Agents" 
          formTitle="Create a new agent" 
          samePage={false} 
          route="/agent/create"
          count={count}
          handlePageSearch={handlePageSearch}
          isResizable={!false}
        />
      </Row>
       
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Change Password</h4>
        </div>
        <div className="modal__body">
          <EditForm isAboveError fiels={fields} submitFnc={updateAgentPassword} id={id} closeFnc={toggle} />
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
      
      {viewModal ? <ViewAgent id={id} toggle={vtoggle} open={viewModal} /> : null}
    </Container>
  );
};

export default connect(null, { 
  getAgents, updateAgentPassword, updateAgentStatus, getAllUnions, areaList, 
 })(AgentList);
