import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody, Button, Container,
} from 'reactstrap';
import { Tag, PageHeader } from 'antd';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import Pageheader from '../../global/pageHeader';
import EditForm from '../../global/editForm';
import { getAssignableRoles } from '../../redux/actions/apis/role';
import { getAllSubject, updateSubject } from '../../redux/actions/apis/subject';

const EditSubject = ({ 
    getAllSubject, updateSubject, location,
    }) => {
    const [roles, setRoles] = useState([]);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [data, setData] = useState(null);
    const [ID, setID] = useState(null);
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      const res = await getAllSubject();
      setData(res?.list);
      const search = location?.search;
      const id = new URLSearchParams(search).get('id');
      // if (res?.list?.id === id) {
      //   setData(res?.list);
      // }
      setID(id);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    const closeModal = () => {
      setError('');
      setIsOpen(false);
    };

    const openModal = () => {
      setIsOpen(true);
      setError('');
    };

    const fiels = [
        // {
        //   type: 'input', 
        //   label: 'User Id', 
        //   name: 'user_id', 
        //   placeholder: 'User Id', 
        //   value: data?.user_id,
        //   rules: { 
        //     required: 'This is required field', 
        //     },
        // },
        {
          type: 'input', 
          label: 'Subject Name', 
          name: 'name', 
          placeholder: 'Name', 
          value: data?.name,
          rules: { 
            required: 'This is required field', 
            },
        },
        
    ];
    
    
    return ( 
      
      // <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen}>
      //   <ModalHeader>Form</ModalHeader>
      //   <ModalBody>
      //     <p>
      //       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint neque aut, debitis, 
      //       similique temporibus amet voluptatem minima sequi
      //       tempore reiciendis laborum itaque numquam omnis mollitia, quibusdam magnam optio dolorem corrupti!
      //     </p>
            
      //   </ModalBody>
      // </Modal>
      <Container>
        <PageHeader
          ghost={false}
          style={{ background: '#f2f4f7' }}
          onBack={() => window.history.back()}
          title="Back"
        />
        <Pageheader title="Support Management" subTitle="Manage all kind of subject update operations" />
        { data 
        ? <EditForm isHorizontal isAboveError title="Update Subject" fiels={fiels} submitFnc={updateSubject} id={ID} />
        : null}
      </Container>
     );
};
 
export default connect(null, { 
  getAllSubject, updateSubject,
 })(EditSubject);
