import React, { useEffect, useState } from 'react';
import { 
  Button, ButtonToolbar, Container, Modal,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { 
  Col, Row, Statistic, Badge, Descriptions, Timeline, Divider, List, Typography, Tag,
 } from 'antd';
import { connect } from 'react-redux';
import { 
  getProductById, 
} from '../../redux/actions/apis/product';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';


const ViewProduct = ({ 
  getProductById, toggle, open, id,
 }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getProductById(id);
    setData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  
  return (
    <Container>
      <Modal
        isOpen={open}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
        size="lg" 
        style={{ maxWidth: '90%', width: '100%' }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          
          <h4 style={{ color: '#1a0e75', fontSize: '30px' }} className="text-modal  modal__title">Product Details</h4>
        </div>
        <div style={{ pading: '20px' }}>
          <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Account Info</h4>
          {/* <h4>Subject : {data?.subject} </h4> */}
          <Tag color="#2db7f5">Name: {data?.detail?.account?.name}</Tag>
          <Tag color="#00b894"> Email : {data?.detail?.account?.email}</Tag> 
          <Tag color="#cd2df5">Mobile Number : {data?.detail?.account?.mobile_number}</Tag>
          {/* <Tag color="#db2775">Transfer Amount : {data?.detail?.transfer_amount}</Tag> */}
          <Tag color="#16ceb2">{data?.detail?.created_at}</Tag>
          <br />
          <br />
        </div>
        <div style={{ pading: '20px' }}>
          <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Enterprise Info</h4>
          {/* <h4>Subject : {data?.subject} </h4> */}
          <Tag color="#fc0748">Name: {data?.detail?.enterprise?.name}</Tag>
          <Tag color="#e1e20b"> Email : {data?.detail?.enterprise?.email}</Tag> 
          <Tag color="#07fc51">Mobile Number : {data?.detail?.enterprise?.mobile_number}</Tag>
          {/* <Tag color="#db2775">Transfer Amount : {data?.detail?.transfer_amount}</Tag> */}
          {/* <Tag color="#16ceb2">{data?.detail?.created_at}</Tag> */}
          <br />
          <br />
        </div>
        <div className="modal__body">
          <Row gutter={16}>
            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Product Info</h4>
              <Descriptions>
                {/* <Descriptions.Item label="User Id">{data?.detail?.id}</Descriptions.Item> */}
                <Descriptions.Item label="Name">{data?.detail?.name}</Descriptions.Item>
                <Descriptions.Item label="Sale Price">{data?.detail?.sale_price}</Descriptions.Item>
                <Descriptions.Item label="Production Cost">{data?.detail?.production_cost}</Descriptions.Item>
                
                <Descriptions.Item label="Active">{data?.detail?.active
                  ? <Badge status="success" text="Active" /> : <Badge status="error" text="Inactive" />}
                </Descriptions.Item>
                <Descriptions.Item label="Description">{data?.detail?.description}</Descriptions.Item>
                {/* <Descriptions.Item label="Created at">{data?.detail?.created_at}</Descriptions.Item> */}
                <Descriptions.Item label="Update at">{data?.detail?.updated_at}</Descriptions.Item>
                
              </Descriptions>
            </Col>
            
            
            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Logs</h4>
              <br />
              <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
                {data?.detail?.logs?.slice(0)?.reverse()?.map(item => (
                  <Timeline.Item>
                    <p>{item?.message}</p>
                    <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{item?.created_at}</p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col>
            
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    </Container>
  );
};

export default connect(null, { 
  getProductById,
 })(ViewProduct);
