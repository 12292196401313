import React, { useEffect, useState } from 'react';
import { 
  Badge, ButtonToolbar, Container, Modal, Row,
 } from 'reactstrap';
import { Card, Button } from 'antd';
import { connect } from 'react-redux';
import { 
  getApi, updateApi,
} from '../../redux/actions/apis/settings';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import EditForm from '../../global/editForm';
import ViewUser from './view';

const APIList = ({ 
  getApi, updateApi,
 }) => {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(prevState => !prevState);
  };
  
  const getData = async () => {
    const res = await getApi();
    setData(res?.detail);
  };

  useEffect(() => {
    getData();
  }, []);
  
  const handleStatus = async (val, id) => {
    const res = await updateApi(val);
    if (res) {
      getData();
    }
  };

  
  const fiels = [
    {
      type: 'input', 
      label: 'Porichoy API', 
      name: 'porichoy_api', 
      value: data?.porichoy_api,
      rules: { 
        required: 'This is required field', 
        },
    },
    {
      type: 'password', 
      label: 'Password', 
      name: 'password', 
      rules: { 
        required: 'This is required field', 
        },
    },
    {
      type: 'input', 
      label: 'Remark', 
      name: 'remark',
      rules: { required: 'This is required field' },
    },
];

  return (
    <Container>
      <Row>
        <PageHeader title="Setting management" subTitle="Manage all kind of setting operations" />
      </Row>
      
      <Card
        title={data?.name}
        style={{
          width: '100%',
        }}
      >
        <p>Porichoy API</p>
        <br />
        <Button type="dashed" danger>
          {data?.porichoy_api}
        </Button>
        <br />
        <br />
        <Button type="primary" danger onClick={toggle}>
          Edit
        </Button>
      </Card>

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-key modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Update API</h4>
        </div>
        <div className="modal__body">
          <EditForm isHorizontal isAboveError fiels={fiels} submitFnc={updateApi} />
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
      
    </Container>
  );
};

export default connect(null, { 
  getApi, updateApi,
 })(APIList);
