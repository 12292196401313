import React, { useEffect, useState } from 'react';
import { 
  Container,
 } from 'reactstrap';
 import { Col, Row, Statistic } from 'antd';
 import { Pie } from '@ant-design/plots';
 import Panel from '@/shared/components/Panel';
import { connect } from 'react-redux';
import { 
  getCandidateStac, 
} from '../../redux/actions/apis/candidate';
import VisitorsSessions from '../../containers/Dashboards/OnLineMarketing/components/VisitorsSessions';


const CandidateStac = ({ 
  getCandidateStac,
 }) => {
  const [data0, setData0] = useState([]);
  const [data, setData] = useState([]);
 
  const getData = async () => {
    const res = await getCandidateStac();
    setData0(res?.statistic);
    const d = [];
    d.push(...[{
     type: 'Employment Group', value: res?.statistic?.group1,
    }, {
     type: 'Enterprise & IGA Group', value: res?.statistic?.group2,
    }]);
    setData(d);
  };

  useEffect(() => {
    getData();
  }, []);
  
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],
  };

  return (
    <Container>
      
      <Row gutter={16}>
        <Col span={24}>
          <h4>Candidate Overview</h4>
        </Col>
        <Col span={6}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Pending" value={data0?.pending} />
        </Col>
        <Col span={6}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Waiting" value={data0?.waiting} />
        </Col>
        <Col span={6}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Extra waiting" value={data0?.e_waiting} />
        </Col>
        <Col span={6}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Home Verification" value={data0?.verification} />
        </Col>
        
        <Col span={24}> <br /> </Col>

        <Col span={6}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Selected" value={data0?.selected} />
        </Col>
        <Col span={6}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Rejected" value={data0?.rejected} />
        </Col>
        <Col span={6}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Total" value={data0?.total} />
        </Col>
        <Col span={24}> <br /> </Col>
        <Col span={12}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Enterprise & IGA Group" value={data0?.group2} />
        </Col>
        <Col span={12}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Employment Group" value={data0?.group1} />
        </Col>
        <Col span={24}>
          <br />
        </Col>
        <Col span={24}>
          <br />
        </Col>
        <Col span={24}>
          <Panel
            // lg={6}
            // xl={4}
            // md={12}
            xs={12}
            // title={t('home.visitors_sessions')}
            // subhead="What browsers are most popular"
          >
            <div className="dashboard__visitors-chart" style={{ height: '400px' }}>
              <p className="dashboard__visitors-chart-title">Total Canidtates by group</p>
              <p className="dashboard__visitors-chart-number">{data0?.total}</p>
              {data?.length > 0 
                ? <Pie {...config} />
                : null}
            </div>
          </Panel>
        </Col>
        <Col span={24}> <br /> </Col>
        <Col span={24}>
          <VisitorsSessions data0={data0} dir="ltr" />
        </Col>
      </Row>
       
    </Container>
  );
};

export default connect(null, { 
  getCandidateStac,
 })(CandidateStac);
