import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import MicrosoftOfficeIcon from 'mdi-react/MicrosoftOfficeIcon';
import EditForm from '../../global/editForm';
import { getUnionsByID, updateUnion } from '../../redux/actions/apis/union';
import { areaList } from '../../redux/actions/apis/area';

const EditUnion = ({ 
      updateUnion, getUnionsByID, location, areaList,
    }) => {
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [data, setData] = useState(null);
    const [ID, setID] = useState(null);
    const [area, setArea] = useState(null);
    const [allDistrict, setAllDistrict] = useState(null);
    const [district, setDistrict] = useState(null);
    const [allupazilla, setAllupazilla] = useState(null);
    const [allunion, setAllunion] = useState(null);
    const [upaZilla, setUpaZilla] = useState(null);
    const [union, setUnion] = useState(null);
    const [unionCode, setUnionCode] = useState(null);

    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];

    const getArea = async () => {
      const res = await areaList();
      setArea(res);
    };

    useEffect(async () => {
      const search = location?.search;
      const id = new URLSearchParams(search).get('id');
      setID(id);
      const resUn = await getUnionsByID(id);
      setData(resUn?.detail);
      
      const res = await areaList();
      setArea(res);

      const dis = [];
      setDistrict(
        { value: resUn?.detail?.district, label: resUn?.detail?.district },
      );
      for (let i = 0; i < res.length; i += 1) {
        const element = res[i];
        dis.push(element?.district);
      }
      setAllDistrict([...new Set(dis)]);

      const upa = [];
      for (let i = 0; i < res?.length; i += 1) {
        const element = res[i]?.district;
        if (element === resUn?.detail?.district) {
          upa.push(res[i]?.upazilla);
        }
      }
      setAllupazilla([...new Set(upa)]);
      setUpaZilla(
        { value: resUn?.detail?.upazilla, label: resUn?.detail?.upazilla },
      );

      const uni = [];
      for (let i = 0; i < res?.length; i += 1) {
        const element = res[i]?.upazilla;
        if (element === resUn?.detail?.upazilla) {
          uni.push(res[i]?.union);
        }
      }
      setAllunion([...new Set(uni)]);
      setUnion(
        { value: resUn?.detail?.name, label: resUn?.detail?.name },
      );

      setUnionCode(resUn?.detail?.union_code);
    }, []);

    
    const getUpozilla = (val) => {
      setUnionCode(null);
      setUnion(null);
      setUpaZilla(null);
      setDistrict(val);
      const uni = [];
      for (let i = 0; i < area?.length; i += 1) {
        const element = area[i]?.district;
        if (element === val?.value) {
          uni.push(area[i]?.upazilla);
        }
      }
      setAllupazilla([...new Set(uni)]);
    };

    const getUnion = (val) => {
      setUnion(null);
      setUnionCode(null);
      setUpaZilla(val);
      const uni = [];
      for (let i = 0; i < area?.length; i += 1) {
        const element = area[i]?.upazilla;
        if (element === val?.value) {
          uni.push(area[i]?.union);
        }
      }
      setAllunion([...new Set(uni)]);
    };

    const getUnionCode = (val) => {
      setUnionCode(null);
      setUnion(val);
      for (let i = 0; i < area?.length; i += 1) {
        const element = area[i]?.union;

        if (element === val?.value) {
          setUnionCode(area[i]?.union_code);
          break;
        } else setUnionCode('');
      }
    };

    
    const fields = [
      { 
        type: 'dropdown', 
        label: 'District', 
        name: 'district', 
        placeholder: 'District', 
        rules: { required: 'This is required field' },
        option: allDistrict?.map(item => ({
          value: item,
          label: item,
        })),
        multi: false,
        value: { value: data?.district, label: data?.district },
        fnc: val => getUpozilla(val),
      },
      {
        type: 'dropdown', 
        label: 'Upazilla', 
        name: 'upazilla', 
        placeholder: 'Upazilla', 
        rules: { required: 'This is required field' },
        option: allupazilla?.map(item => ({
          value: item,
          label: item,
        })),
        multi: false,
        value: { value: data?.upazilla, label: data?.upazilla },
        fnc: getUnion,
      },
      {
        type: 'dropdown', 
        label: 'Union', 
        name: 'union', 
        placeholder: 'Union', 
        rules: { required: 'This is required field' },
        option: allunion?.map(item => ({
          value: item,
          label: item,
        })),
        multi: false,
        value: { value: data?.name, label: data?.name },
        fnc: getUnionCode,
      },
      {
        type: 'input', 
        label: 'Union Code', 
        name: 'union_code',
        value: unionCode,
      },
      { 
        type: 'input', 
        label: 'Mobile Number', 
        name: 'mobile_number', 
        placeholder: 'Mobile', 
        value: data?.mobile_number,
      },
      {
        type: 'input', 
        label: 'Chairman Name', 
        name: 'chairman_name', 
        value: data?.chairman_name,
      },
      {
        type: 'input', 
        label: 'Chairman Mobile Number', 
        name: 'chairman_mobile_number', 
        value: data?.chairman_mobile_number,
      },
      { 
        type: 'dropdown', 
        label: 'Status', 
        name: 'authenticated', 
        placeholder: 'Status', 
        value: {
          value: data?.active,
          label: data?.active ? 'Active' : 'Inactive',
        },
        rules: { required: 'This is required field' },
        option: authenticated,
        multi: false,
      },
      { 
        type: 'dropdown', 
        label: 'Candidate Entry', 
        name: 'candidate_entry', 
        placeholder: 'Candidate Entry', 
        value: {
          value: data?.active,
          label: data?.active ? 'Active' : 'Inactive',
        },
        rules: { required: 'This is required field' },
        option: authenticated,
        multi: false,
      },
      {
        type: 'input', 
        label: 'Address', 
        name: 'address', 
        value: data?.address,
        placeholder: 'address', 
        rules: { 
          required: 'This is required field', 
          },
      },
    ];
    
    // if (district !== null) {
    //   fields.splice(1, 0, { 
    //     type: 'dropdown', 
    //     label: 'Upazilla', 
    //     name: 'upazilla', 
    //     placeholder: 'Upazilla', 
    //     rules: { required: 'This is required field' },
    //     option: allupazilla?.map(item => ({
    //       value: item,
    //       label: item,
    //     })),
    //     multi: false,
    //     value: { value: data?.upazilla, label: data?.upazilla },
    //     fnc: getUnion,
    //   });
    // }
    // if (upaZilla !== null) {
    //   fields.splice(2, 0, { 
    //     type: 'dropdown', 
    //     label: 'Union', 
    //     name: 'union', 
    //     placeholder: 'Union', 
    //     rules: { required: 'This is required field' },
    //     option: allunion?.map(item => ({
    //       value: item,
    //       label: item,
    //     })),
    //     multi: false,
    //     value: { value: data?.name, label: data?.name },
    //     fnc: getUnionCode,
    //   });
    // }
    // if (unionCode !== null) {
    //   fields.splice(3, 0, {
    //       type: 'input', 
    //       label: 'Union Code', 
    //       name: 'union_code',
    //       value: data?.union_code,
    //     });
    // }
    
    return ( 
      // <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen}>
      //   <ModalHeader>Form</ModalHeader>
      //   <ModalBody>
      //     <p>
      //       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint neque aut, debitis, 
      //       similique temporibus amet voluptatem minima sequi
      //       tempore reiciendis laborum itaque numquam omnis mollitia, quibusdam magnam optio dolorem corrupti!
      //     </p>
      data 
      ? <EditForm unionCode={unionCode} isHorizontal isAboveError title="Edit Union" fiels={fields} submitFnc={updateUnion} id={ID} />
      : null      
      //   </ModalBody>
      // </Modal>
     );
};
 
export default connect(null, { 
  updateUnion, getUnionsByID, areaList, 
 })(EditUnion);
