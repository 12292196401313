import React from 'react';
import { Button, Result } from 'antd';
import ReactLoading from 'react-loading';
import history from '../../history';

const ErrorPage = () => (
  <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
    <div style={{ alignSelf: 'center' }}>
      <Result
        status="warning"
        title="There are some problems with your operation."
        extra={(
          <Button type="primary" key="console" onClick={() => { history.goBack(); }}>
            Try again
          </Button>
        )}
      />
    </div>
  </div>
);
export default ErrorPage;
