import React, { useEffect, useState } from 'react';
import { 
  Button, ButtonToolbar, Container, Modal,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { 
  Col, Row, Statistic, Badge, Descriptions, Timeline, Divider, List, Typography,
 } from 'antd';
import { connect } from 'react-redux';
import { 
  getUnions, updateUnionEntryStatus, updateUnionStatus, getUnionsByID, 
} from '../../redux/actions/apis/union';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';


const ViewUnion = ({ 
  getUnions, updateUnionEntryStatus, updateUnionStatus, getUnionsByID, toggle, open, id,
 }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getUnionsByID(id);
    setData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleStatus = async (val, id) => {
    const res = await updateUnionStatus(val, id);
    if (res) {
      getData();
    }
  };

  const handleEntryStatus = async (val, id) => {
    const res = await updateUnionEntryStatus(val, id);
    if (res) {
      getData();
    }
  };
  
  return (
    <Container>
      <Modal
        isOpen={open}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
        size="lg" 
        style={{ maxWidth: '90%', width: '100%' }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-store modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Union Details</h4>
        </div>
        <div className="modal__body">
          <Row gutter={16}>
            <Col span={3}>
              <Statistic title="Pending" value={data?.candidateStatistic?.pending} />
            </Col>
            <Col span={3}>
              <Statistic title="Lottary" value={data?.candidateStatistic?.waiting} />
            </Col>
            <Col span={3}>
              <Statistic title="Verification" value={data?.candidateStatistic?.verification} />
            </Col>
            <Col span={3}>
              <Statistic title="Selected" value={data?.candidateStatistic?.selected} />
            </Col>
            <Col span={3}>
              <Statistic title="Extra Waiting" value={data?.candidateStatistic?.e_waiting} />
            </Col>
            <Col span={3}>
              <Statistic title="Rejected" value={data?.candidateStatistic?.rejected} />
            </Col>
            <Col span={3}>
              <Statistic title="Total" value={data?.candidateStatistic?.total} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Statistic title="Enterprise & IGA Group" value={data?.candidateStatistic?.group2} />
            </Col>
            <Col span={12}>
              <Statistic title="Employment Group" value={data?.candidateStatistic?.group1} />
            </Col>
          </Row>
          <br />
          <Row gutter={16}>
            <Col span={20}>
              <Descriptions title="Union Info" size="small">
                <Descriptions.Item label="Name">{data?.detail?.name}</Descriptions.Item>
                <Descriptions.Item label="Chairman name">{data?.detail?.chairman_name}</Descriptions.Item>
                <Descriptions.Item label="Candidate limit">{data?.detail?.candidate_limit}</Descriptions.Item>
                <Descriptions.Item label="District">{data?.detail?.district}</Descriptions.Item>
                <Descriptions.Item label="Chairman number">{data?.detail?.chairman_mobile_number}</Descriptions.Item>
                
                <Descriptions.Item label="Selected Candidate">{data?.detail?.candidate_count}</Descriptions.Item>
                <Descriptions.Item label="Upazilla">{data?.detail?.upazilla}</Descriptions.Item>
                <Descriptions.Item label="Mobile number">{data?.detail?.mobile_number}</Descriptions.Item>
                <Descriptions.Item label="Created at">{data?.detail?.created_at}</Descriptions.Item>
                <Descriptions.Item label="Union code">{data?.detail?.union_code}</Descriptions.Item>
                <Descriptions.Item label="Address">{data?.detail?.address}</Descriptions.Item>
                <Descriptions.Item label="Active">{data?.detail?.active
                  ? <Badge status="success" text="Active" /> : <Badge status="error" text="Inactive" />}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Candidate entry">{data?.detail?.candidate_entry 
                  ? <Badge status="success" text="Open" /> : <Badge status="error" text="Closed" />}
                </Descriptions.Item>
                <Descriptions.Item label="Word no">{data?.detail?.word_no}</Descriptions.Item> */}
                {/* <Descriptions.Item label="Lottery">{data?.detail?.lottery
                  ? <Badge status="success" text="Open" /> : <Badge status="error" text="Closed" />}
                </Descriptions.Item> */}
              
              </Descriptions>
            </Col>
            
            <Col span={4}>
              <h4>Logs</h4>
              <br />
              <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
                {data?.detail?.logs?.slice(0)?.reverse()?.map(item => (
                  <Timeline.Item>
                    <p>{item?.message}</p>
                    <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{item?.created_at}</p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col>
            <Col span={24} style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
             
              <Divider orientation="left">Agent List</Divider>
              <List
                size="small"
                bordered
                dataSource={data?.agentList}
                renderItem={(item => <List.Item> {item?.name}({item?.user_id}) &nbsp; &nbsp; &nbsp; {item?.mobile_number}</List.Item>)}
              />
             
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    </Container>
  );
};

export default connect(null, { 
  getUnions, updateUnionEntryStatus, updateUnionStatus, getUnionsByID,
 })(ViewUnion);
