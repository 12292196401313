import React, { useEffect, useState } from 'react';
import { 
  Button, ButtonToolbar, Container, Modal,
  Form, FormGroup, Label, Input, Table, 
 } from 'reactstrap';
 import { 
  Col, Row, Badge, Descriptions, Timeline, Divider, List, PageHeader, Tag, Empty,
 } from 'antd';
import { connect } from 'react-redux';
import { 
  getOrganizationById, 
} from '../../redux/actions/apis/organization';
// import PageHeader from '../../global/pageHeader';
// import Table from '../../global/table';
import history from '../../history';
import '../../global/style.css';

const ViewOrganization = ({ 
  getOrganizationById, toggle, open, location,
 }) => {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  const getData = async () => {
    const search = location?.search;
    const id = new URLSearchParams(search).get('id');
    const res = await getOrganizationById(id);
    setData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  const modalToggle = () => {
    setIsModalOpen(prevState => !prevState);
  };

  return (
    <Container style={{ background: 'white' }}>
      
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Eenterprise Details"
        subTitle="Enterprise Management"
        style={{ paddingLeft: '0px' }}
      />
      <div style={{ pading: '20px' }}>
        <h4 className="detailHeader">Enterprise Info</h4>
        <Descriptions>
          {/* <Descriptions.Item label="User Id">{data?.user_id}</Descriptions.Item> */}
          <Descriptions.Item label="Name">{data?.detail?.name}</Descriptions.Item>
          <Descriptions.Item label="Mobile number">{data?.detail?.mobile_number}</Descriptions.Item>
          <Descriptions.Item label="Address">{data?.detail?.address}</Descriptions.Item>
          <Descriptions.Item label="Email">{data?.detail?.email}</Descriptions.Item>
          <Descriptions.Item label="Creator">{data?.detail?.creator?.name}</Descriptions.Item>
          <Descriptions.Item label="Status">{data?.detail?.active
            ? <Badge status="success" text="Active" /> : <Badge status="error" text="Inactive" />}
          </Descriptions.Item>
          <Descriptions.Item label="Created at">{data?.detail?.created_at}</Descriptions.Item>
          <Descriptions.Item label="Update at">{data?.detail?.updated_at}</Descriptions.Item>
          <Descriptions.Item label="Description">{data?.detail?.description}</Descriptions.Item>
        </Descriptions>
        <br />
        <br />
      </div>
      <Row gutter={16}>
        <Col xs={12}>
          <div style={{ pading: '20px' }}>
            <h4 className="detailHeader">Account List</h4>
            {data?.account?.length > 0 
            ? (
              <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                <Table size="sm">
                  <thead>
                    <tr style={{ backgroundColor: '#FFF', zIndex: '200000' }}>
                      <th>#</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.account?.map((item, id) => (  
                      <tr>
                        <td>{id + 1 }</td>
                        <td>{item?.name}</td>
                        <td>{item?.type}</td>
                        <td>{item?.created_at}</td>
                        <td><Button size="sm" onClick={() => { modalToggle(); setUserData(item?.users); }}>Users</Button></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : <Empty />}
            <br />
            <br />
          </div>
        </Col>
        <Col xs={12}>
          <div style={{ pading: '20px' }}>
            <h4 className="detailHeader">Enterprise Users</h4>
            {data?.user?.length > 0 
            ? (
              <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                <Table size="sm">
                  <thead>
                    <tr style={{ backgroundColor: '#FFF', zIndex: '200000' }}>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.user?.map((item, id) => (  
                      <tr>
                        <td>{id + 1 }</td>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.enterprise_admin ? 'Admin' : 'User'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : <Empty />}
            <br />
            <br />
          </div>
        </Col>
      </Row>
      
      <Modal
        isOpen={isModalOpen}
        toggle={modalToggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
        size="lg" 
        style={{ maxWidth: '90%', width: '100%' }}
        title="Account Users"
      >
        <h4>Account Users</h4>
        {userData
            ? (
              <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                <Table size="sm">
                  <thead>
                    <tr style={{ backgroundColor: '#FFF', zIndex: '200000' }}>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.map((item, id) => (  
                      <tr>
                        <td>{id + 1 }</td>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.enterprise_admin ? 'Admin' : 'User' }</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : <Empty />}
      </Modal>

      <Row gutter={16}>
        
        <Col span={14}>
          <h4 className="detailHeader">Contact Person</h4>
          <br />
          <div>
            {data?.detail?.contact_person?.slice(0)?.reverse()?.map((item, id) => (
              <>
                <Row>
                  <Col xs={1}>{id + 1} </Col>
                  <Col xs={4}>Name: {item?.name} </Col>
                  <Col xs={6}>Mobile Number: {item?.number} </Col>
                  <Col xs={4}> Email: {item?.email}</Col>
                  <Col xs={9}>Description: {item?.description}</Col>
                  
                </Row>
                <br />
              </>
              
            ))}
          </div>
        </Col>
        <Col span={10}>
          <h4 className="detailHeader">Logs</h4>
          <br />
          <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
            {data?.detail?.logs?.slice(0)?.reverse()?.map(item => (
              <Timeline.Item>
                <p>{item?.message}</p>
                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{item?.created_at}</p>
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
        
      </Row>
    </Container>
  );
};

export default connect(null, { 
  getOrganizationById,
 })(ViewOrganization);
