
import axios from 'axios';
import history from '../../../history';
import api from './base';
import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_AUTH_ERROR,
    VALIDATION_ERROR,
} from '../../types';

// export const getOrganizations = (p, n, mbl, a,e, ds, de) => async (dispatch) => {
//     dispatch({ type: LOADING });
//     try {
//       if (d) {
//       const response = await axios(`
//       https://sbss-api.redorch.com/sailor/v1/enterprise-management/enterprise?p=${p}&n=${n}&user=${user}&a=${a}&u=${u}&uc=${uc}&dis=${dis}&up=${up}&d=${d}`,
//       {
//         headers: {
//           authorization: `Bearer ${localStorage.getItem('tk_auth')}`,
//           },
//           method: 'GET',
//           responseType: 'blob',
//         });
      
//         if (response.status === 200) {
//           dispatch({ type: LOADED });
//           const url = window.URL.createObjectURL(new Blob([response.data]));
//           const link = document.createElement('a');
//           link.href = url;
//           link.setAttribute(
//             'download',
//             'agent.xlsx',
//           );
//           document.body.appendChild(link);
//           link.click();
//           link.parentNode.removeChild(link);
//         }
//       } else {
//         const response = await api(localStorage.getItem('tk_auth')).get(`/enterprise-management/enterprise?p=${p}&n=${n}&m=${mbl}&a=${a}&e=${e}&ds=${ds}&de=${de}`);
//         if (response.status === 200) {
//             dispatch({ type: LOADED });
//             return response?.data;
//         }
//       }
//     } catch (err) {
//         /* =============Start========== */
//           if (err?.response?.status === 422) {
//             err?.response?.data?.errors?.forEach((item) => {
//                 dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
//             });
//           }
//           if (err?.response?.status === 401 || err?.response?.status === 403) {
//               dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
//               localStorage.clear();
//               history.push('/login');
//           }
//           dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
//           dispatch({ type: LOADED });
//         /* ===========End============ */
//       }
//       return true;
// };

export const createOrganizationdb = data => async (dispatch) => {
    dispatch({ type: LOADING });
    const boolValue = (data.active === 'true');

    const object = {
      
      name: data.name,
      email: data.email,
      mobile_number: data.mobile_number,
      address: data.address,
      active: boolValue,
      description: data.description,
      contact_person: data?.contact_person,

    };
    try {
        const response = await api(localStorage.getItem('tk_auth')).post('/enterprise-management/enterprise/create', { ...object });
        // const response = null;
            if (response.status === 201) {
                dispatch({ type: LOADED });
                dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
                history.push('/enterprise/list');
            }
    } catch (err) {
        /* =============Start========== */
          if (err?.response?.status === 422) {
            err?.response?.data?.errors?.forEach((item) => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
              localStorage.clear();
              history.push('/login');
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
          dispatch({ type: LOADED });
        /* ===========End============ */
      }
      return true;
};

export const getOrganizationById = id => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem('tk_auth')).get(
      `/enterprise-management/enterprise/detail?enterprise=${id}`,
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};
export const getOrganizationHelper = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem('tk_auth')).get(
      '/enterprise-management/helper',
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateOrganization = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const un = [];
    data?.union?.forEach((i) => {
      un.push(i?.value);
    });
    const boolValue = (data.active === 'true');
    const object = {
      enterprise: id,
      name: data.name,
      email: data.email,
      mobile_number: data.mobile_number,
      address: data.address,
      active: boolValue,
      // description: data.description,
      contact_person: data?.contact_person,
  
      remark: data?.remark,
    };

  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/enterprise-management/enterprise/update', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      history.push('/enterprise/list');
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateAgentPassword = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
      user: id,
      password: data.password,
      // confirm_password: data.confirm_password,
    };

  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/agent-management/agent/update/pass', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response?.data?.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateOrganizationStatus = (authenticated, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
        enterprise: id,
        active: authenticated,
    };

  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/enterprise-management/enterprise/active', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response?.data?.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const getAllOrganization = (p, n, mbl, a, e, ds, de) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
      const response = await api(localStorage.getItem('tk_auth')).get(`/enterprise-management/enterprise?p=${p}&n=${n}&m=${mbl}&a=${a}&e=${e}&ds=${ds}&de=${de}`);
          if (response.status === 200) {
              dispatch({ type: LOADED });
              return response.data;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};

export const getAgentStac = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
      const response = await api(localStorage.getItem('tk_auth')).get('/agent-management/agent/statistic');
          if (response.status === 200) {
              dispatch({ type: LOADED });
              return response.data;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};
