import React from 'react';
import { useTranslation } from 'react-i18next';
import { Progress, Table } from 'reactstrap';
import Panel from '@/shared/components/Panel';

const Brasilia = `${process.env.PUBLIC_URL}/img/flags/Brasil.svg`;
const Argentina = `${process.env.PUBLIC_URL}/img/flags/Argentina.svg`;
const Gabon = `${process.env.PUBLIC_URL}/img/flags/Gabon.svg`;
const Ireland = `${process.env.PUBLIC_URL}/img/flags/Ireland.svg`;
const Italian = `${process.env.PUBLIC_URL}/img/flags/Italia.svg`;
const Sierra = `${process.env.PUBLIC_URL}/img/flags/Sierra.svg`;

const header = [
  { id: 1, title: 'Union Name' },
  { id: 2, title: 'Union Code' },
  { id: 3, title: 'Occupied' },
];

const rows = [
  {
    id: 0,
    src: Brasilia,
    country: 'Brasilia',
    page_views: '12134',
    device: 'Desktop',
    value: 41,
    bounce_rate: '41%',
  }, {
    id: 1,
    src: Argentina,
    country: 'Argentina',
    page_views: '47584',
    device: 'Mobile',
    bounce_rate: 30,
  }, {
    id: 2,
    src: Gabon,
    country: 'Gabon',
    page_views: '24235',
    device: 'Desktop',
    value: 65,
  }, {
    id: 3,
    src: Italian,
    country: 'Italian',
    page_views: '2255',
    device: 'Desktop',
    value: 41,
  }, {
    id: 4,
    src: Sierra,
    country: 'Sierra Leone',
    page_views: '2421',
    device: 'Tablet',
    value: 31,
  }, {
    id: 5,
    src: Ireland,
    country: 'Ireland',
    page_views: '805',
    device: 'Mobile',
    value: 70,
  },
];

const AudienceByCountry = ({ data, title }) => {
  const { t } = useTranslation('common');
  return (
    <div style={{ width: '100%', maxHeight: '500px', overflowY: 'scroll' }}>
      <Panel xs={12} title={`${title}`}>
        <Table responsive className="table--bordered dashboard__audience-table" style={{ minHeight: '380px' }}>
          <thead>
            <tr>
              {header.map(item => (
                <th key={item.id}>{item.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map(item => (
              <tr>
                <td>{item.name}</td>
                <td>{item.union_code}</td>
                <td>
                  <div className="progress-wrap progress-wrap--blue">
                    <Progress
                      value={Number((item?.occupy).split('/')[0])}
                    >
                      {/* {`${Number((item?.occupy).split('/')[0])}`} */}
                    </Progress>
                    <p style={{ fontSize: '12px', textAlign: 'center' }}>{`${Number((item?.occupy).split('/')[0])}  / ${(item?.occupy).split('/')[1]}`}</p>
                  </div>  
                </td>
              </tr>
            ))}
           
            {/* {rows.map(item => (
              <tr key={item.id}>
                <td><img className="dashboard__table-flag" src={item.src} alt="flag" />{item.country}</td>
                <td>{item.page_views}</td>
                <td>{item.device}</td>
                <td>
                  <div className="progress-wrap progress-wrap--blue">
                    <Progress value={item.value}>{`${item.value}%`}</Progress>
                  </div>
                </td>
              </tr>
            ))} */}
          </tbody>
        </Table>
      </Panel>
    </div>
  );
};

export default AudienceByCountry;
