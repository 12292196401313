import React, { useEffect, useState } from 'react';
import { 
  Button, ButtonToolbar, Container, Modal,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { 
  Col, Row, Statistic, Badge, Descriptions, Timeline, Divider, List, Typography, Tag,
 } from 'antd';
import { connect } from 'react-redux';
import { 
  getUsersById, 
} from '../../redux/actions/apis/user';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';


const ViewUser = ({ 
  getUsersById, toggle, open, id,
 }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getUsersById(id);
    setData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  
  return (
    <Container>
      <Modal
        isOpen={open}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
        size="lg" 
        style={{ maxWidth: '90%', width: '100%' }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-user modal__title-ico">  </span>
          <h4 style={{ color: '#1a0e75', fontSize: '30px' }} className="text-modal  modal__title">User Details</h4>
        </div>
        <div style={{ pading: '20px' }}>
          <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>User Info</h4>
          {/* <h4>Subject : {data?.subject} </h4> */}
          <Tag color="#fc0748">User ID: {data?.user_id}</Tag>
          <Tag color="#fc07e3">Name: {data?.name}</Tag>
          <Tag color="#e1e20b"> Email : {data?.email}</Tag> 
          <Tag color="#07fc51">Mobile Number : {data?.mobile_number}</Tag>
          <Tag color="#db2775">Address : {data?.address}</Tag>
          <Tag color="#4661f4">Role Name : {data?.roles?.name}</Tag>
          {/* <Tag color="#16ceb2">{data?.created_at}</Tag> */}
          <br />
          <br />
        </div>
        <div className="modal__body">
          <Row gutter={16}>
            
            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}> Logs</h4>
              <br />
              <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
                {data?.logs?.map(item => (
                  <Timeline.Item>
                    <p>{item?.message}</p>
                    <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{item?.created_at}</p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    </Container>
  );
};

export default connect(null, { 
  getUsersById,
 })(ViewUser);
