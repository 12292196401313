import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import { PageHeader } from 'antd';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import MicrosoftOfficeIcon from 'mdi-react/MicrosoftOfficeIcon';
import Form from '../../global/form';
import { getAssignableRoles } from '../../redux/actions/apis/role';
import { createUser } from '../../redux/actions/apis/user';

const CreateUser = ({ getAssignableRoles, createUser }) => {
    const [roles, setRoles] = useState([]);
    const [layers, setLayers] = useState([]);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    let role = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      const res = await getAssignableRoles();
      setRoles(res);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    const closeModal = () => {
      setError('');
      setIsOpen(false);
    };

    const openModal = () => {
      setIsOpen(true);
      setError('');
    };

    role = (
      roles ? roles?.map(item => createOption(item.role_id, item.name))
    : null);
    
 

    const fiels = [
        {
          type: 'input', 
          label: 'User Id', 
          name: 'user_id', 
          placeholder: 'User Id', 
          rules: { 
            required: 'This is required field', 
            // pattern: {
            //   value: new RegExp(/^[a-z | 0-9]{6,15}$'/),
            //   message: 'yyy',
            // },
          },
        },
        {
          type: 'input', 
          label: 'User Name', 
          name: 'name', 
          placeholder: 'Name', 
          rules: { 
            required: 'This is required field', 
            },
        },
        {
          type: 'input', 
          label: 'Email Address', 
          name: 'email', 
          placeholder: 'Email', 
          rules: { 
            required: 'This is required field', 
            // pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Entered value does not match email format' },
          },
        },
        { 
          type: 'input', 
          label: 'Mobile Number', 
          name: 'mobile_number', 
          placeholder: 'Mobile', 
          rules: { required: 'This is required field' },
        },
        {
          type: 'password', 
          label: 'Password', 
          name: 'password', 
          placeholder: '8 Digit Password', 
          rules: { 
            required: 'This is required field', 
            pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
          },
        },
        { 
          type: 'dropdown', 
          label: 'Role', 
          name: 'role', 
          placeholder: 'Role', 
          rules: { required: 'This is required field' },
          option: role,
          multi: false,
        },
       
        { 
          type: 'dropdown', 
          label: 'Status', 
          name: 'authenticated', 
          placeholder: 'Status', 
          rules: { required: 'This is required field' },
          option: authenticated,
          multi: false,
        },
        {
          type: 'input', 
          label: 'Address', 
          name: 'address', 
          placeholder: 'address', 
          rules: { 
            required: 'This is required field', 
            },
        },
    ];
    
    
    return ( 
      <>
        <PageHeader
          ghost={false}
          style={{ background: '#f2f4f7' }}
          onBack={() => window.history.back()}
          title="Back"
        />
        <Form isHorizontal isAboveError title="Create a new user" fiels={fiels} submitFnc={createUser} />
      </>
     );
};
 
export default connect(null, { getAssignableRoles, createUser })(CreateUser);
