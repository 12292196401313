import React, { useEffect, useState } from 'react';
import { 
  Container,
 } from 'reactstrap';
 import { Col, Row, Statistic } from 'antd';
 import { Pie as APie } from '@ant-design/plots';
import { connect } from 'react-redux';
import { PieChart, Pie } from 'recharts';
import Panel from '@/shared/components/Panel';
import { 
  getUnionStac, 
} from '../../redux/actions/apis/union';
import SalesStatistic from '../../containers/Dashboards/OnLineMarketing/components/SalesStatistic';
import AudienceByCountry from '../../containers/Dashboards/OnLineMarketing/components/AudienceByCountry';


const UnionStac = ({ 
  getUnionStac,
 }) => {
  const [data0, setData0] = useState([]);
  const [data, setData] = useState([]);
 
  const getData = async () => {
    const d = [];
    const res = await getUnionStac();
    setData0(res?.statistic);
    d.push(...[{
      type: 'Union fully occupied',
      value: res?.statistic?.full_count,
    },
    {
      type: 'Union partially occupied',
      value: res?.statistic?.partial_count,
    }]);
    setData(d);
  };
  
  const data1 = [{ value: (Math.ceil(((100 * data0?.occupy) / data0?.limit))), fill: '#e74c3c' },
  { value: (Math.ceil(((100 * data0?.limit - data0?.occupy) / data0?.limit))), fill: '#eeeeee' }];

  const data2 = [{ value: (Math.ceil((100 * data0?.limit - data0?.occupy) / data0?.limit)), fill: '#4ce1b6' },
  { value: (Math.ceil((100 * data0?.occupy) / data0?.limit)), fill: '#eeeeee' }];

  useEffect(() => {
    getData();
  }, []);
  
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],
  };
  return (
    <Container>
      
      <Row gutter={16}>
        <Col span={24}>
          <h4>Union Overview</h4>
        </Col>
        <Col span={8}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Active" value={data0?.active} />
        </Col>
        <Col span={8}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Inactive" value={data0?.inactive} />
        </Col>
        <Col span={8}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Total" value={data0?.total} />
        </Col>
        
        <Col span={24}>
          <br />
        </Col>
        <Col span={24}>
          <br />
        </Col>

        {data0?.limit 
        ? (
          <Col span={12}>
            {/* <SalesStatistic limit={data0?.limit} occupy={data0?.occupy} title="Occupied" /> */}
            
            <div className="dashboard__stat">
              <div className="dashboard__stat-chart">
                <PieChart height={120} width={120}>
                  <Pie data={data2} dataKey="value" cx={55} cy={55} innerRadius={55} outerRadius={60} />
                </PieChart>
                <p className="dashboard__stat-label" style={{ color: '#4ce1b6' }}>{((100 * (data0?.limit - data0?.occupy)) / data0?.limit).toPrecision(3)}%</p>
              </div>
              <div className="dashboard__stat-info">
                <p>Candidate Limit <strong>{data0?.limit}</strong></p>
                <h4 className="dashboard__stat-number">
                  Avaiable {data0?.limit - data0?.occupy}
                </h4>
              </div>
            </div>
          </Col>
          )
        : null}
        {data0?.limit 
        ? (
          <Col span={12}>
            <div className="dashboard__stat">
              <div className="dashboard__stat-chart">
                <PieChart height={120} width={120}>
                  <Pie data={data1} dataKey="value" cx={55} cy={55} innerRadius={55} outerRadius={60} />
                </PieChart>
                <p className="dashboard__stat-label" style={{ color: '#e74c3c' }}>{((100 * data0?.occupy) / data0?.limit).toPrecision(2)}%</p>
              </div>
              <div className="dashboard__stat-info">
                <p>Candidate Limit <strong>{data0?.limit}</strong></p>
                <h4 className="dashboard__stat-number">
                  Occupied {data0?.occupy}
                </h4>
              </div>
            </div>
            {/* <SalesStatistic limit={data0?.limit} occupy={data0?.occupy} title="Available Candiate" /> */}
          </Col>
          )
        : null}
        
        <Col span={24}>
          <br />
        </Col>
        <Col span={24}>
          <br />
        </Col>
        
        {data0
        ? (
          <>
            <Col span={12}>
              <AudienceByCountry data={data0?.partial_list} title="Candidate by Union (Partial List)" />
            </Col>
            <Col span={12}>
              <AudienceByCountry data={data0?.full_list} title="Candidate by Union (Full List)" />
            </Col>
          </>
          )
      : null}
        <br />
        <Col span={24}> <br /> </Col>
        <Col span={24}>
          <Panel
            // lg={6}
            // xl={4}
            // md={12}
            xs={12}
            // title={t('home.visitors_sessions')}
            // subhead="What browsers are most popular"
          >
            <div className="dashboard__visitors-chart" style={{ height: '400px' }}>
              <p className="dashboard__visitors-chart-title">Union by Candidate Occupy</p>
              <p className="dashboard__visitors-chart-number">{data0?.total}</p>
              {data?.length > 0 
              ? <APie {...config} />
              : null}
            </div>
          </Panel>
        </Col>  
      </Row>
       
    </Container>
  );
};

export default connect(null, { 
  getUnionStac,
 })(UnionStac);
