import React, { useEffect, useState } from 'react';
import { 
  Container,
 } from 'reactstrap';
 import { Col, Row, Statistic } from 'antd';
 import { Pie } from '@ant-design/plots';
 import Panel from '@/shared/components/Panel';
import { connect } from 'react-redux';
import { 
  getAgentStac, 
} from '../../redux/actions/apis/agent';


const AgentStac = ({ 
  getAgentStac,
 }) => {
  const [data1, setData1] = useState([]);
  const [data, setData] = useState([]);
 
  const getData = async () => {
    const d = [];
    const res = await getAgentStac();
    setData1(res?.statistic);
    d.push(...[{
      type: 'Active',
      value: res?.statistic?.active,
    },
    {
      type: 'Inactive',
      value: res?.statistic?.inactive,
    }]);
    setData(d);
    // console.log(d);
  };

  useEffect(() => {
    getData();
  }, []);
  // console.log(chartData);
  
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],
  };
  
  return (
    <Container>
      
      <Row gutter={16}>
        <Col span={24}>
          <h4>Agent Overview</h4>
        </Col>
        <Col span={8}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Active" value={data1?.active} />
        </Col>
        <Col span={8}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Inactive" value={data1?.inactive} />
        </Col>
        <Col span={8}>
          <Statistic style={{ backgroundColor: '#fff', padding: '5px' }} title="Total" value={data1?.total} />
        </Col>
        <br />
        
        <Col span={24}> <br /> </Col>
        <Col span={24}>
          {data?.length > 0 
          ? (
          // <Pie {...config} />
            <Panel
              // lg={6}
              // xl={4}
              // md={12}
              xs={12}
              // title={t('home.visitors_sessions')}
              // subhead="What browsers are most popular"
            >
              <div className="dashboard__visitors-chart" style={{ height: '400px' }}>
                <p className="dashboard__visitors-chart-title">Total Agent by Status</p>
                <p className="dashboard__visitors-chart-number">{data1?.total}</p>
                {data?.length > 0 
                  ? <Pie {...config} />
                  : null}
              </div>
            </Panel>
          )
          : null}
        </Col>  
      </Row>
       
    </Container>
  );
};

export default connect(null, { 
  getAgentStac,
 })(AgentStac);
