import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { 
  Button, Form, Modal, Divider, Descriptions, Select, Input, PageHeader, Empty, Tag, Comment, Alert, Drawer,
} from 'antd';
import { 
  Content, 
} from 'antd/lib/layout/layout';
import styled from 'styled-components';
import { updateOrganization, getOrganizationById } from '../../redux/actions/apis/organization';
import { getTicketById, replayTicket, closeTicket } from '../../redux/actions/apis/ticket';

const { TextArea } = Input;
export const Title = styled.div`
    width: 100%;
    background-color: #636e72;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
    text-align: center;
`;
export const BoxWrapper = styled.div`
    height: auto;
    padding: 10px;
    /* margin: 10px; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white ;
   
`;
export const MessageBox = styled.div`
    border-radius: 5px;
    padding: 10px;
    width: 70%;
    display: block;
    position: relative;
    background-color: ${ props => (props.user ? '#ffe6e6' : '#e6e6ff')};
    margin-bottom: 10px;
    left: ${ props => (props.user ? '0px' : '30%')};
    right: ${ props => (props.user ? '30%' : '0')};
`;
const EditTicket = ({ 
  updateOrganization, getTicketById, replayTicket, location, closeTicket, 
 }) => {
    const [unions, setUnions] = useState([]);
    const [ent, setEnt] = useState([]);
    const [enterpriseadmin, setenterpriseAdmin] = useState('');
    const [StatusType, setStatusType] = useState(null);
    const [datahelpn, setDataHelp] = useState('');
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [data, setData] = useState(null);
    const [ID, setID] = useState(null);
    const [form] = Form.useForm();
    const [forms] = Form.useForm();
    const [review, setReview] = useState(false);
    let enterprise = [];
    // let datahelp = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      // const res = await getOrganizationHelper();
      const search = location?.search;
      const id = new URLSearchParams(search).get('id');
      setID(id);
      const result = await getTicketById(id);
      setData(result?.ticket);
      console.log(result);
      // setUnions(res);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };

    enterprise = (
      ent?.enterprise?.length > 0 ? ent?.enterprise?.map(item => createOption(item.id, item.name))
    : null);
    const onFinish = async (values) => {
      const code = await replayTicket(values, ID);
      if (code) {
      const result = await getTicketById(ID);
      setData(result?.ticket);
      }
    };
    const onFinishtickect = async (values) => {
      const code = await closeTicket(values, ID);
      
      if (code) {
        const result = await getTicketById(ID);
        setData(result?.ticket);
        forms.resetFields();
        setReview(false);
        }
    };
    const closeticket = (
      <Drawer
        title="Why you want to Close ?"
        placement="bottom"
        visible={review}
        closable={false}
        onClose={() => setReview(false)}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinishtickect}
          style={{ alignSelf: 'center' }}
          requiredMark={false}
          form={forms}
        >
          <Form.Item
            name="remark"
            style={{ alignSelf: 'flex-end', width: '70%', marginBottom: '0px' }}
            rules={[{ required: true, message: 'Required' }]}
          >
            <TextArea rows={4} name="remark" allowClear />
          </Form.Item>
          <br />
          <Form.Item> 
            <Button type="primary" htmlType="submit">Close</Button>
          </Form.Item>
        </Form>
      </Drawer>
    );

    return ( 
      <Content style={{ backgroundColor: 'white' }}>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Ticket Details"
          subTitle="Support Management"
        />
        <div style={{ pading: '20px' }}>
          <h4>Subject : {data?.subject} </h4>
          <Tag color="#2db7f5">Ticket ID: {data?.id}</Tag>
          {data?.active === true ? <Tag color="#00b894">Status: Not Replied </Tag> : <Tag color="#ff7675">Status: Closed</Tag>}
          <Tag color="#cd2df5">{data?.created_at}</Tag>
          <Tag color="#D3CB2A">{data?.enterprise?.name}</Tag>
          <br />
          <br />
          <Title style={{ backgroundColor: '#575dbf' }}>Account Info</Title>
          <Descriptions>
            
            <Descriptions.Item label="NAME"><strong>{data?.account?.name?.toUpperCase()}</strong></Descriptions.Item>
            <Descriptions.Item label="EMAIL"><strong>{data?.account?.email}</strong></Descriptions.Item>
            <Descriptions.Item label="ENTERPRISE NAME"><strong>{data?.enterprise?.name}</strong></Descriptions.Item>
            
          </Descriptions>
          <Title style={{ backgroundColor: '#575dbf' }}>Messages History</Title>
          {data?.logs 
          ? (
            <BoxWrapper style={{ background: '#E0FFE7' }}>
              { data?.logs?.map((item, id) => (
                <MessageBox user={item?.is_admin === false}>
                  {console.log(item?.is_admin)}
                  <Comment
                    author={
                              item?.is_admin === false
                            ? ( 
                              <span style={{ color: '#000', fontWeight: 'bold' }}>{ data?.client?.name } (Client)
                              </span>
                              ) 
                            : (
                              <span style={{ color: '#000', fontWeight: 'bold' }}>
                                { item?.admin?.name } (Admin)
                              </span>
                            )
                            }
                    content={<p>{item?.message}</p>}
                  />
                    
                                  
                </MessageBox>

              ))}
              {data?.active  
              ? (
                <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                  <Form.Item
                    name="message"
                    style={{ alignSelf: 'flex-end', width: '70%', marginBottom: '0px' }}
                    rules={[{ required: true, message: 'Required' }]}
                  >
                    <TextArea rows={4} name="text" allowClear />
                  </Form.Item>
                  <br />

                  <Button htmlType="submit" type="primary" size="middle" style={{ width: '150px', display: 'inline-block', alignSelf: 'flex-end' }}>
                    Reply
                  </Button>
                  <br />
                  <br />

                  <Button type="text" size="middle" style={{ backgroundColor: '#fc5c65', color: '#fff' }} onClick={() => setReview(true)}>
                    Close this ticket
                  </Button>
                  {closeticket}
                </Form>
                )
              
              : (
                <Alert 
                  style={{ background: '#E51D3F' }} 
                  message="This ticket is closed.......!" 
                  type="info"
                />
              )}
              
            </BoxWrapper>
            )
          : <Empty />};
          
        </div>
        
             
      </Content>
     );
};
 
export default connect(null, { 
  getTicketById, replayTicket, updateOrganization, closeTicket, 
})(EditTicket);
