import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { 
  Button, Form, Modal, Divider, Descriptions, Select, Input, PageHeader, InputNumber, Space, Row, Col,
} from 'antd';
import { 
  Content, 
} from 'antd/lib/layout/layout';

import { createOrganizationUser, getOrganizationUserHelper, getOrganizationUserHelperid } from '../../redux/actions/apis/organizationuser';

const { TextArea } = Input;

const CreateOrganizationUser = ({ createOrganizationUser, getOrganizationUserHelper, getOrganizationUserHelperid }) => {
    const [allAccount, setallAccount] = useState([]);
    const [ent, setEnt] = useState([]);
    const [enterpriseadmin, setenterpriseAdmin] = useState('');
    const [accountdata, setAccountData] = useState('');
    const [StatusType, setStatusType] = useState(null);
    const [datahelpn, setDataHelp] = useState('');
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [form] = Form.useForm();
    let enterprise = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      // const res = await getOrganizationHelper();
      const enterpriseall = await getOrganizationUserHelper();
      setEnt(enterpriseall);
      // setUnions(res);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    const handleChange = async (value) => {
      setStatusType(value);
    };
    const handleAccountData = async (value) => {
      setAccountData([]);
    };
    const handleChangeEnt = async (value) => {
        form.setFieldsValue({ account: [] });
        setAccountData([]);
      const datahelp = await getOrganizationUserHelperid(value);
      setDataHelp(datahelp);
      const acc = datahelp?.account?.length > 0 
      ? datahelp?.account?.map(item => createOption(item.id, item.name))
      : [];
      setallAccount(acc);
    };
    
  
    enterprise = (
      ent?.enterprise?.length > 0 ? ent?.enterprise?.map(item => createOption(item.id, item.name))
    : null);
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
    };
    const onFinish = async (values) => {
      const code = await createOrganizationUser(values);
      console.log(code);
      if (code === 200) {
        form.resetFields();
      }
      
      console.log('Received values of form:', values);
    };
    
    
    return ( 
      <Content style={{ background: 'white' }}>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="User Create"
          subTitle="Enterprise User Management"
        />
        <Divider> User Create</Divider>
        
        <Form {...formItemLayout} name="dynamic_form_nest_item" onFinish={onFinish} form={form} autoComplete="off">
          <Row>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Name" 
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input placeholder="Name" />
              </Form.Item>
              
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Mobile Number" 
                name="mobile_number"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                  {
                    pattern: /^(01)[0-9][0-9]{8}$/,
                    message: 'Please valid phone number!',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input placeholder="017********" />
              </Form.Item>
              
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Email" 
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                    pattern: {
                      value: new RegExp(/\S+@\S+\.\S+/),
                      message: 'Email is not valid',
                    },
                  },
                  
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input placeholder="xyz@redorch.com" />
              </Form.Item>
              
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Password" 
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                  
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input type="password" />
              </Form.Item>
              
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item 
                label="Status"
                name="authenticated"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Select>
                  <Select.Option value="true">Active</Select.Option>
                  <Select.Option value="false">Inactive</Select.Option>
                </Select>
              </Form.Item>
              
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Address" 
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <TextArea />
              </Form.Item>
              
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Description" 
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <TextArea />
              </Form.Item>

            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item 
                label="Enterprise"
                name="enterprise"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Select 
                  showSearch 
                  onChange={handleChangeEnt}
                >
                  {
                  ent?.enterprise?.map(item => (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                    
                  ))
                  }
                </Select>
              </Form.Item>
              
            </Col>
            
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item 
                label="Account Type"
                name="enterprise_admin"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Select onChange={handleChange}>
                  <Select.Option value="true">Admin</Select.Option>
                  <Select.Option value="false">User</Select.Option>
                </Select>
              </Form.Item>

            </Col>
            { StatusType === 'false' 
            ? (
              <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                <Form.Item
                  label="Account List" 
                  name="account"
                  value={accountdata}
                  rules={[
                    {
                      required: true,
                      message: 'Field is required',
                    },
                  ]}
                  style={{ alignSelf: 'center', width: '100%' }}
                >
                  <Select 
                    showSearch
                    mode="multiple" 
                    onChange={handleAccountData}
                  >
                    { 
                      allAccount?.map(item => (
                        <Select.Option value={item.value}>{item.label}</Select.Option>
                        
                      ))
                    }
                  </Select>
                </Form.Item>

              </Col>
            ) : null}
            
          </Row>
      
          
          
          <Form.Item style={{ marginLeft: '450px' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          <br />
        </Form>
      </Content>
     );
};
 
export default connect(null, { getOrganizationUserHelper, createOrganizationUser, getOrganizationUserHelperid })(CreateOrganizationUser);
// import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
// import {
//   Modal, ModalHeader, ModalBody, Button,
// } from 'reactstrap';
// import { useForm, Controller } from 'react-hook-form';
// import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
// import FacebookIcon from 'mdi-react/FacebookIcon';
// import MicrosoftOfficeIcon from 'mdi-react/MicrosoftOfficeIcon';
// import Form from '../../global/form';
// import { getAllUnions, createAgent } from '../../redux/actions/apis/agent';
// import { createOrganizationUser, getOrganizationUserHelper, getOrganizationUserById } from '../../redux/actions/apis/organizationuser';

// const CreateOrganizationUser = ({ createOrganizationUser, getOrganizationUserHelper, getOrganizationUserById }) => {
//     // const [enterprise, setEnterprise] = useState([]);
//     const [org, setOrg] = useState('');
//     const [enterpriseadmin, setenterpriseAdmin] = useState('');
//     const [datahelpn, setDataHelp] = useState('');
//     const [formValues, setFormValues] = useState([{ name: '', mobile_number: '' }]);
//     const [error, setError] = useState('');
//     const [accountfieldresent, setAccountFieldreset] = useState('');
//     const [isOpen, setIsOpen] = useState(true);
//     const {
//       handleSubmit,
//       reset,
//       setValue,
//       control,
//       formState: { errors },
//     } = useForm({});
//     let enterprise = [];
//     let accounts = [];
//     let datahelp = [];
//     const authenticated = [
//       { value: true, label: 'Active' },
//       { value: false, label: 'Inactive' },
//     ];
//     const authenticatedadmin = [
//       { value: true, label: 'Admin' },
//       { value: false, label: 'User' },
//     ];
//     // console.log(enterpriseadmin, 'ddd');
//     useEffect(async () => {
//       const organizationHelper = await getOrganizationUserHelper();
//       // console.log(organizationHelper);
//       setOrg(organizationHelper);
//     }, []);
//     const createOption = (value, label) => {
//       const a = '';
//       return { value, label };
//     };
//     const closeModal = () => {
//       setError('');
//       setIsOpen(false);
//     };
//     const getenterprise = async (val) => {
//     // console.log(val);
//     datahelp = await getOrganizationUserById(val.value);
//     setDataHelp(datahelp);
//     };
//     const checkvalue = async (val) => {
//     // console.log(val.value);
//     setenterpriseAdmin(val.value);
//     };

//     const openModal = () => {
//       setIsOpen(true);
//       setError('');
//     };
//     // console.log(datahelpn?.account);
//     enterprise = (
//       org?.enterprise_list?.length > 0 ? org?.enterprise_list?.map(item => createOption(item.id, item.name))
//     : null);

//     accounts = (
//       datahelpn?.account?.length > 0 ? datahelp?.account?.map(item => createOption(item.id, item.name))
//     : null);
    
 

//     const fiels = [
        
//         {
//           type: 'input', 
//           label: 'Name', 
//           name: 'name', 
//           placeholder: 'Name', 
//           rules: { 
//             required: 'This is required field', 
//             },
//         },
//         { 
//           type: 'dropdown', 
//           label: 'Enterprise', 
//           name: 'enterprise', 
//           placeholder: 'enterprise', 
//           rules: { required: 'This is required field' },
//           option: enterprise,
//           multi: false,
//           fnc: val => getenterprise(val),
//         },
        
//         { 
//           type: 'dropdown', 
//           label: 'Enterprise Admin', 
//           name: 'enterprise_admin', 
//           placeholder: 'authenticated', 
//           rules: { required: 'This is required field' },
//           option: authenticatedadmin,
//           multi: false,
//           fnc: val => checkvalue(val),
//         },
       
        
//         {
//           type: 'password', 
//           label: 'Password', 
//           name: 'password', 
//           placeholder: '8 Digit Password', 
//           rules: { 
//             required: 'This is required field', 
//             pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
//           },
//         },
        
//         {
//           type: 'input', 
//           label: 'Email Address', 
//           name: 'email', 
//           placeholder: 'Email', 
//           rules: { 
//             required: 'This is required field',
//             pattern: {
//               value: new RegExp(/\S+@\S+\.\S+/),
//               message: 'Email is not valid',
//             },
//             // pattern: { value: new RegExp(/\S+@\S+\.\S+/), message: 'Entered value does not match email format' },
//             // pattern: { new RegExp(/^(01)[0-9][0-9]{8}$/), message: 'Entered hh value does not match email format' },
//           },
          
            
//         },
//         { 
//           type: 'input', 
//           label: 'Mobile Number', 
//           name: 'mobile_number', 
//           placeholder: 'Mobile', 
//           rules: { 
//             required: 'This is required field',
//           pattern: {
//             value: new RegExp(/^(01)[0-9][0-9]{8}$/),
//             message: 'Mobile is not valid',
//           },
//         },
//         },
//         { 
//           type: 'dropdown', 
//           label: 'Status', 
//           name: 'authenticated', 
//           placeholder: 'authenticated', 
//           rules: { required: 'This is required field' },
//           option: authenticated,
//           multi: false,
//         },
//         {
//           type: 'input', 
//           label: 'Address', 
//           name: 'address', 
//           placeholder: 'address', 
//           rules: { 
//             required: 'This is required field', 
//             },
//         },
//         {
//           type: 'input', 
//           label: 'Description', 
//           name: 'description', 
//           placeholder: 'description', 
//           rules: { 
//             required: 'This is required field', 
//             },
//         },
        

//     ];
//     console.log(accountfieldresent);
//     if (enterpriseadmin === false) {
//       fiels.splice(3, 0, { 
//         type: 'dropdown', 
//         label: 'Account', 
//         name: 'account', 
//         placeholder: 'account', 
//         option: datahelpn?.account?.map(item => ({
//           value: item?.id,
//           label: item?.name,
//         })),
//         multi: true,
//         // value: account,
//         // fnc: getUnion,
//       });
//     }
    
//     return ( 
//       // <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen}>
//       //   <ModalHeader>Form</ModalHeader>
//       //   <ModalBody>
//       //     <p>
//       //       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint neque aut, debitis, 
//       //       similique temporibus amet voluptatem minima sequi
//       //       tempore reiciendis laborum itaque numquam omnis mollitia, quibusdam magnam optio dolorem corrupti!
//       //     </p>
      
//       <Form isHorizontal isAboveError title="Create a new Enterprise User" fiels={fiels} submitFnc={createOrganizationUser} />
//       //   </ModalBody>
//       // </Modal>
//      );
// };
 
// export default connect(null, { createOrganizationUser, getOrganizationUserHelper, getOrganizationUserById })(CreateOrganizationUser);
