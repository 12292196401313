import React, { useEffect } from 'react';
import Alert from '@/shared/components/Alert';
import styled from 'styled-components';
import { Card, CardBody, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resetMessage } from '../redux/actions/reset';

const Msg = styled.div`
    position: fixed;
    right: 100px;
    top:0px;
    width: 500px;
    z-index: 10000000;
    animation: mymove 1s  ;

@keyframes mymove {
 0%{top: -100px}

 
 100%{top: 0px}
}
`;
const AlertBox = ({ resetMessage, value, type }) => {
    const { t } = useTranslation('common');
    useEffect(() => {
        const timer = setTimeout(() => { resetMessage(); }, 3000);
        return () => clearTimeout(timer);
    }, []);
    
    return ( 
      <Msg>
        <Alert color={type} className="alert--colored" icon>
          <p>
            <span className="bold-text">{type === 'danger' ? 'Error' : 'Success'}</span>
            <br />
            {value}
          </p>
        </Alert>
      </Msg>
     );
    };
 
export default connect(null, { resetMessage })(AlertBox);
