import React, { useEffect, useState } from 'react';
import { 
  Badge, Button, ButtonToolbar, Container, Modal, Row,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { Tag, PageHeader } from 'antd';
import { connect } from 'react-redux';

import { 
  getAllTransactionLoan, getTransactionById, getTransactionLoanById, getTransactionHelper, getTransactionHelperId,
} from '../../redux/actions/apis/transaction';
import Pageheader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import EditForm from '../../global/editForm';

import ViewAgent from './view';

const columns = [
  {
    Header: '#SL',
    accessor: 'id',
    disableGlobalFilter: true,
  },
  {
    Header: 'Issue Name',
    accessor: 'issueName',
  },
  {
    Header: 'Loan Id',
    accessor: 'loanId',
  },
  {
    Header: 'Loan State',
    accessor: 'loanState',
  },
  
  {
    Header: 'State',
    accessor: 'state',

  },
  {
    Header: 'Paid Amount',  
    accessor: 'paidamount',
  },
  
  {
    Header: 'Total Amount',  
    accessor: 'totalAmount',  
  },
  {
    Header: 'Account',  
    accessor: 'account',  
  },
  {
    Header: 'Enterprise',  
    accessor: 'enterprise',  
  },
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
  },
];

const TransactionList = ({ 
  getAllTransactionLoan, getTransactionById, getTransactionLoanById, getTransactionHelper, getTransactionHelperId,
 }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState('');
  const [currentpage, setCountPage] = useState('');
  const [viewModal, setViewModal] = useState(false);
  const [datahelp, setDataHelp] = useState([]);
  const [viewModalt, setViewModalT] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(false); 
  const [pageOptions, setPageOptions] = useState(null); 
  const [page, setPage] = useState(1); 
  const [loanid, setLoanId] = useState(''); 
  const [transactionid, setTransactionId] = useState(''); 
  const [loanstatus, setLoanStatus] = useState(''); 
  const [service, setService] = useState(''); 
  const [email, setEmail] = useState(''); 
  const [status, setStatus] = useState(''); 
  const [ds, setDS] = useState(''); 
  const [de, setDE] = useState(''); 
  const [accounts, setAccounts] = useState('');  
  const [enterprise, setEnterprise] = useState('');
  const [product, setProduct] = useState([]);
  const [productfun, setProductfun] = useState([]); 


  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const vtoggle = () => {
    setViewModal(prevState => !prevState);
  };
  const vtoggleTran = () => {
    setViewModalT(prevState => !prevState);
  };

  const getData = async () => {
  const res = await getAllTransactionLoan(page, loanid, transactionid, loanstatus, service, enterprise, accounts, ds, de);
    setData(res?.list);
    setCount(res?.count);
    const helperdata = await getTransactionHelper();
    setDataHelp(helperdata?.enterprise);

    const boundary = Math.ceil(res?.count / 20);
    setCountPage(boundary);
    const pageNumber = [];
      for (let i = 0; i < boundary; i += 1) {
      pageNumber.push({
        value: i + 1,
        label: i + 1, 
      });
    }
    setPageOptions(pageNumber);
  };
  const handleEnterprise = async (e) => {
    const id = e.target.value;
    setEnterprise(id); 
    const res = await getTransactionHelperId(e.target.value);
    setProductfun(res);
  };
  
  useEffect(() => {
    getData();
  }, []);


  // const handleStatus = async (val, id) => {
  //   const res = await updateOrganizationStatus(val, id);
  //   if (res) {
  //     getData();
  //   }
  // };
  
  const createData = (id, loanId, issueName, loanState, state, paidamount, totalAmount, account, enterprise, action) => {
    const a = 0;
    return { 
      id, loanId, issueName, loanState, state, paidamount, totalAmount, account, enterprise, action,
    };
  };
  
  const rows = [...
    data
      ? data?.map((item, i) => createData(
        i + 1,  
        item?.loan_id, 
        item?.issue_name, 
        item?.loan_state, 
        item?.state,
        item?.paid_amount,
        item?.total_amount,
        item?.account?.name,
        item?.enterprise?.name,
        <>
          <Tag 
            color="#108ee9" 
            style={{ cursor: 'pointer' }} 
            onClick={() => { vtoggle(item?.loan_id); setId(item?.loan_id); }}
          >
            View
          </Tag> &nbsp;
          {/* <Tag color="#108ee9" style={{ cursor: 'pointer' }} onClick={() => { setId(item?.id); toggle(); }}>Re-Evaluate</Tag> */}
        </>,
        ))
      : '',
  ];

  const fields = [
    {
      type: 'password', 
      label: 'Password', 
      name: 'password', 
      placeholder: '8 Digit Password', 
      rules: { 
        required: 'This is required field', 
        pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
      },
    },
  ];

  const handleSearch = async (event) => {
    const res = await getAllTransactionLoan(page, loanid, transactionid, loanstatus, service, enterprise, accounts, ds, de);
    setData(res?.list);
    setCount(res?.count);
  };

  const handlePageSearch = async (e) => {
    const res = await getAllTransactionLoan(e, '', '', '', '', '', '', '', '');
    setData(res?.list);
    setCount(res?.count);
  };

  const handleReset = () => {
    setPage('1');
    setLoanId('');
    setDE('');
    setDS('');
    setTransactionId('');
    setLoanStatus('');
    setAccounts('');
    setService('');
    setEnterprise('');
    setProduct('');
    setProductfun('');
    getData('1', '', '', '', '', '', '', '', '', '', '', '');
  };
  


  // unionOption = (
  //   unions ? unions?.map(item => createOption(item.union_id, item.name))
  // : null);
  
  return (
    <Container>
      <Row>
        <PageHeader
          ghost={false}
          style={{ background: '#f2f4f7' }}
          onBack={() => window.history.back()}
          title="Back"
        />
        <Pageheader title="Transaction management" subTitle="Manage all kind of transaction operations" />
        <Form inline style={{ padding: '0px 25px' }}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="Loan Id" className="mr-sm-2">Loan Id </Label>
            <Input bsSize="sm" value={loanid} type="input" id="lid" placeholder="" onChange={(e) => { setLoanId(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="user" className="mr-sm-2">Transaction ID</Label>
            <Input bsSize="sm" type="input" value={transactionid} id="user" placeholder="" onChange={(e) => { setTransactionId(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="is">Service &nbsp; </Label>
            <Input bsSize="is" value={loanstatus} type="select" name="select" id="a" onChange={(e) => { setLoanStatus(e.target.value); }}>
              <option value="">Select One</option>
              <option value="pending">Pending</option>
              <option value="partially paid">Partially paid</option>
              <option value="paid">Paid</option>
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="s">Service &nbsp; </Label>
            <Input bsSize="s" value={service} type="select" name="select" id="a" onChange={(e) => { setService(e.target.value); }}>
              <option value="">Select One</option>
              <option value="given ">Given</option>
              <option value="taken">Taken</option>
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="ent">Enterprise &nbsp; </Label>
            <Input 
              bsSize="sm" 
              value={enterprise} 
              type="select" 
              name="enterprise" 
              id="ent" 
              onChange={async (e) => { handleEnterprise(e); }}
            >
              <option value="">Select One</option>
              {
              datahelp?.map(item => (
                <option value={item.id}>{item.name}</option>
                  
                ))
              }
              
             
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="ac">Account &nbsp; </Label>
            <Input bsSize="sm" value={accounts} type="select" name="account" id="ac" onChange={(e) => { console.log(e); setAccounts(e.target.value); }}>
              <option value="">Select One</option>
              {
              productfun?.account?.map(item => (
                <option value={item.id}>{item.name}</option>
                  
                ))
              }
              
              
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="ds" className="mr-sm-2">Start Date </Label>
            <Input bsSize="sm" value={ds} type="date" name="dates" id="st" onChange={(e) => { setDS(e.target.value); }} />
          </FormGroup>
          
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="de" className="mr-sm-2">End Date </Label>
            <Input bsSize="sm" value={de} type="date" name="datee" id="ste" onChange={(e) => { setDE(e.target.value); }} />
          </FormGroup>
          
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mt-sm-4">
            <Button color="primary" type="button" size="sm" onClick={handleSearch}>Search</Button>&nbsp;
            {/* <Button color="primary" type="button" size="sm" onClick={handleDownload}>Download</Button>&nbsp; */}
            <Button color="danger" type="button" size="sm" onClick={handleReset}>Reset</Button>
          </FormGroup>
        </Form>
        <Table 
          columns={columns} 
          rows={rows} 
          title="Transaction List" 
          subTitle="All Transaction"  
          samePage={!false} 
          count={count}
          current={pageOptions}
          handlePageSearch={handlePageSearch}
          isResizable={!false}
        />
      </Row>
       
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Change Password</h4>
        </div>
        {/* <div className="modal__body">
          <EditForm isAboveError fiels={fields} submitFnc={updateAgentPassword} id={id} closeFnc={toggle} />
        </div> */}
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
      
      {viewModal ? <ViewAgent id={id} toggle={vtoggle} open={viewModal} /> : null}
    </Container>
  );
};

export default connect(null, { 
  getAllTransactionLoan, getTransactionById, getTransactionLoanById, getTransactionHelper, getTransactionHelperId,
 })(TransactionList);
