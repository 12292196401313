// import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import { 
//   Button, Form, Modal, Divider, Descriptions, Select, Input, PageHeader, InputNumber, Checkbox, Space,
// } from 'antd';
// import { 
//   Content, 
// } from 'antd/lib/layout/layout';
// import { updateAccount, getAccountHelper, getAccountById } from '../../redux/actions/apis/account';
// import EditForm from '../../global/editForm';

// const { TextArea } = Input;

// const EditAccount = ({ 
//   getAccountById, getAccountHelper, updateAccount, location,
//   }) => {
//     const [unions, setUnions] = useState([]);
//     const [ent, setEnt] = useState([]);
//     const [enterpriseadmin, setenterpriseAdmin] = useState('');
//     const [StatusType, setStatusType] = useState('');
//     const [datahelpn, setDataHelp] = useState('');
//     const [error, setError] = useState('');
//     const [isOpen, setIsOpen] = useState(true);
//     const [data, setData] = useState(null);
//     const [ID, setID] = useState(null);
//     const [types, setTypes] = useState(null);
//     const [submitmoel, setSubmitModel] = useState(false);
//     const [form] = Form.useForm();
//     let enterprise = [];
//     const authenticated = [
//       { value: true, label: 'Active' },
//       { value: false, label: 'Inactive' },
//     ];
//     const type = [
//       { value: 'trading', label: 'Trading' },
//       { value: 'production', label: 'Production' },
//     ];
//     useEffect(async () => {
//       // const res = await getOrganizationHelper();
//       const enterpriseall = await getAccountHelper();
//       setEnt(enterpriseall);
//       const search = location?.search;
//       const id = new URLSearchParams(search).get('id');
//       setID(id);
//       const result = await getAccountById(id);
//       setData(result?.detail);
//       setStatusType(result?.detail?.type);
//       if (result) {
//         form.setFieldsValue({
//           name: result?.detail?.name,
//           active: result?.detail?.active ? 'true' : 'false',
//           email: result?.detail?.email,
//           mobile_number: result?.detail?.mobile_number,
//           address: result?.detail?.address,
//           description: result?.detail?.description,
//           remark: result?.detail?.remark,
//           type: result?.detail?.type === 'production' ? 'production' : 'trading',
//           profit_margin: result?.detail?.profit_margin,
//           contact_person: result?.detail?.contact_person,
        
//         });
//       }
//       // setUnions(res);
//     }, []);
//     console.log(data);
//     const createOption = (value, label) => {
//       const a = '';
//       return { value, label };
//     };
//     const handleChange = (value) => {
//       setStatusType(value);
//     };
//     enterprise = (
//       ent?.enterprise?.length > 0 ? ent?.enterprise?.map(item => createOption(item.id, item.name))
//     : null);
//     const fiels = [
//       // {
//       //   type: 'input', 
//       //   label: 'User Id', 
//       //   name: 'enterprise', 
//       //   placeholder: 'User Id', 
//       //   value: data?.id,
//       //   rules: { 
//       //     required: 'This is required field', 
//       //     },
//       // },
//       {
//         type: 'input', 
//         label: 'User Name', 
//         name: 'name', 
//         placeholder: 'Name', 
//         value: data?.name,
//         rules: { 
//           required: 'This is required field', 
//           },
//       },
//       { 
//         type: 'dropdown', 
//         label: 'Enterprise', 
//         name: 'enterprise', 
//         placeholder: 'enterprise', 
//         rules: { required: 'This is required field' },
//         option: enterprise,
//         multi: false,
//         value: {
//           value: data?.enterprise?.id,
//           label: data?.enterprise?.name,
//         },
//         // fnc: val => getenterprise(val),
//       },
//       {
//         type: 'input', 
//         label: 'Email Address', 
//         name: 'email', 
//         placeholder: 'Email',
//         value: data?.email, 
//         rules: { 
//           required: 'This is required field',
//           pattern: {
//             value: new RegExp(/\S+@\S+\.\S+/),
//             message: 'Email is not valid',
//           },
//           // pattern: { value: new RegExp(/\S+@\S+\.\S+/), message: 'Entered value does not match email format' },
//           // pattern: { new RegExp(/^(01)[0-9][0-9]{8}$/), message: 'Entered hh value does not match email format' },
//         },
        
          
//       },
//       { 
//         type: 'input', 
//         label: 'Mobile Number', 
//         name: 'mobile_number', 
//         placeholder: 'Mobile', 
//         value: data?.mobile_number,
//         rules: { 
//           required: 'This is required field',
//         pattern: {
//           value: new RegExp(/^(01)[0-9][0-9]{8}$/),
//           message: 'Mobile is not valid',
//         },
//       },
//       },
      
      
//       { 
//         type: 'dropdown', 
//         label: 'Status', 
//         name: 'authenticated', 
//         placeholder: 'Status', 
//         value: {
//           value: data?.active,
//           label: data?.active ? 'Active' : 'Inactive',
//         },
//         rules: { required: 'This is required field' },
//         option: authenticated,
//         multi: false,
//       },
      
//       {
//         type: 'input', 
//         label: 'Address', 
//         name: 'address', 
//         placeholder: 'Address', 
//         value: data?.address,
//       },
//       {
//         type: 'input', 
//         label: 'Description', 
//         name: 'description', 
//         placeholder: 'description', 
//         value: data?.description,
//       },
//       { 
//         type: 'dropdown', 
//         label: 'Type', 
//         name: 'type', 
//         placeholder: 'Type', 
//         rules: { required: 'This is required field' },
//         option: type,
//         value: data?.type === 'production' ? 'production' : 'trading',
//         multi: false,
//          fnc: val => setTypes(val),
//       },
      
//       {
//         type: 'input', 
//         label: 'Remark', 
//         name: 'remark', 
//         placeholder: 'Remark', 
//         value: data?.remark,
//         rules: { required: 'This is required field' },
//       },
//   ];
//   if (types?.value === 'trading') {
//     fiels.splice(8, 0, { 
//       type: 'input', 
//       label: 'Profit Margin', 
//       name: 'profit_margin', 
//       placeholder: 'Profit margin', 
//       value: data?.profit_margin,
//       rules: { 
//         required: 'This is required field', 
//         },
//       // value: account,
//       // fnc: getUnion,
//     });
//   }
//     const onFinish = async (values) => {
//       const code = await updateAccount(values, ID);
//       console.log(code);
//       if (code === 200) {
//         form.resetFields();
//       }
      
//       console.log('Received values of form:', values);
//     };
    
    
//     return ( 
//       <Content style={{ background: 'white' }}>
//         <PageHeader
//           ghost={false}
//           onBack={() => window.history.back()}
//           title="Account Update"
//           subTitle="Enterprise Management"
//         />
//         <Divider>Account Update</Divider>
//         {
//           data 
//           ? <EditForm isHorizontal isAboveError title="Edit Organization" fiels={fiels} submitFnc={updateAccount} id={ID} />
//           : null 
//         }
//         <br />
//         <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} name="dynamic_form_nest_item" onFinish={onFinish} form={form} autoComplete="off">
          
//           <Form.List 
//             name="contact_person"
//           >
//             {(fields, { add, remove }) => (
//               <>
//                 {fields.map(({ key, name, ...restField }) => (
//                   <Space
//                     key={key}
//                     style={{
//                       display: 'flex',
//                       justifyContent: 'center',
//                       marginBottom: 2,
//                     }}
//                     align="baseline"
//                   >
//                     { name <= 0 ? setSubmitModel(true) : name }
//                     <Form.Item
//                       {...restField}
//                       name={[name, 'name']}
//                       rules={[
//                         {
//                           required: true,
//                           message: 'Field is required',
//                         },
//                       ]}
                      
//                     >
//                       <Input placeholder="Name" />
//                     </Form.Item>
//                     <Form.Item
//                       {...restField}
//                       name={[name, 'number']}
//                       rules={[
//                         {
//                           required: true,
//                           message: 'Field is required',
//                         },
//                         {
//                           pattern: /^(01)[0-9][0-9]{8}$/,
//                           message: 'Please valid phone number!',
//                         },
//                       ]}
                      
                      
//                     >
//                       <Input placeholder="Mobile Number" />
//                     </Form.Item>
//                     <Form.Item
//                       {...restField}
//                       name={[name, 'email']}
//                       rules={[
//                         {
//                           required: true,
//                           message: 'Field is required',
//                         },
//                       ]}
                      
                      
//                     >
//                       <Input placeholder="xyz@gmail.com" />
//                     </Form.Item>
//                     <Form.Item
//                       {...restField}
//                       name={[name, 'description']}
//                       // rules={[
//                       //   {
//                       //     pattern: /^(01)[0-9][0-9]{8}$/,
//                       //     message: 'Please insert Valid email!',
//                       //   },
//                       // ]}
                      
                      
//                     >
//                       <Input placeholder="description" />
//                     </Form.Item>
//                     {fields.length > 1 ? (
//                       <MinusCircleOutlined
//                         onClick={() => remove(name)}
//                       />
//                       ) : null}
//                   </Space>
//                 ))}
//                 <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
//                   <Button style={{ display: 'flex', justifyContent: 'center' }} type="dashed" onClick={() => { add(); setSubmitModel(true); }} block icon={<PlusOutlined />}>
//                     Contact Persons
//                   </Button>
//                 </Form.Item>
//               </>
//           )}
//           </Form.List>
//           {submitmoel ? (
//             <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//               <Button type="primary" htmlType="submit">
//                 Submit
//               </Button>
//             </Form.Item>
//           )
//           : (
//             <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//               <Button disabled type="primary" htmlType="submit">
//                 Submit
//               </Button>
//             </Form.Item>
//           )}
//         </Form>
//       </Content>
//      );
// };
 
// export default connect(null, { updateAccount, getAccountHelper, getAccountById })(EditAccount);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { 
  Button, Form, Modal, Divider, Descriptions, Select, Input, PageHeader, InputNumber, Checkbox, Space,
} from 'antd';
import { 
  Content, 
} from 'antd/lib/layout/layout';
import { updateAccount, getAccountHelper, getAccountById } from '../../redux/actions/apis/account';

const { TextArea } = Input;

const EditAccount = ({ 
  getAccountById, getAccountHelper, updateAccount, location,
  }) => {
    const [unions, setUnions] = useState([]);
    const [ent, setEnt] = useState([]);
    const [enterpriseadmin, setenterpriseAdmin] = useState('');
    const [StatusType, setStatusType] = useState('');
    const [datahelpn, setDataHelp] = useState('');
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [data, setData] = useState(null);
    const [ID, setID] = useState(null);
    const [submitmoel, setSubmitModel] = useState(false);
    const [form] = Form.useForm();
    let enterprise = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      // const res = await getOrganizationHelper();
      const enterpriseall = await getAccountHelper();
      setEnt(enterpriseall);
      const search = location?.search;
      const id = new URLSearchParams(search).get('id');
      setID(id);
      const result = await getAccountById(id);
      setData(result);
      setStatusType(result?.detail?.type);
      if (result) {
        form.setFieldsValue({
          name: result?.detail?.name,
          active: result?.detail?.active ? 'true' : 'false',
          email: result?.detail?.email,
          mobile_number: result?.detail?.mobile_number,
          address: result?.detail?.address,
          description: result?.detail?.description,
          remark: result?.detail?.remark,
          type: result?.detail?.type === 'production' ? 'production' : 'trading',
          profit_margin: result?.detail?.profit_margin,
          contact_person: result?.detail?.contact_person,
        
        });
      }
      // setUnions(res);
    }, []);
    console.log(StatusType);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    const handleChange = (value) => {
      setStatusType(value);
    };
    enterprise = (
      ent?.enterprise?.length > 0 ? ent?.enterprise?.map(item => createOption(item.id, item.name))
    : null);
    const onFinish = async (values) => {
      const code = await updateAccount(values, ID);
      console.log(code);
      if (code === 200) {
        form.resetFields();
      }
      
      console.log('Received values of form:', values);
    };
    
    
    return ( 
      <Content style={{ background: 'white' }}>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Account Update"
          subTitle="Enterprise Management"
        />
        <Divider>Account Update</Divider>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} name="dynamic_form_nest_item" onFinish={onFinish} form={form} autoComplete="off">
          <Form.Item
            label="Name" 
            name="name"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Mobile Number" 
            name="mobile_number"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
              {
                pattern: /^(01)[0-9][0-9]{8}$/,
                message: 'Please valid phone number!',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input placeholder="017********" />
          </Form.Item>
          <Form.Item
            label="Email" 
            name="email"
            rules={[
              {
                required: true,
                message: 'Field is required',
                pattern: {
                  value: new RegExp(/\S+@\S+\.\S+/),
                  message: 'Email is not valid',
                },
              },
              
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Input placeholder="xyz@redorch.com" />
          </Form.Item>
          <Form.Item
            label="Address" 
            name="address"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            label="Description" 
            name="description"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <TextArea />
          </Form.Item>
          <Form.Item 
            label="Status"
            name="active"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Select>
              <Select.Option value="true">Active</Select.Option>
              <Select.Option value="false">Inactive</Select.Option>
            </Select>
          </Form.Item>
          
          <Form.Item 
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <Select onChange={handleChange}>
              <Select.Option value="trading">Trading</Select.Option>
              <Select.Option value="production">Production</Select.Option>
            </Select>
          </Form.Item>
          { StatusType === 'trading' 
            ? (
              
              <Form.Item
                label="Profit Margin" 
                name="profit_margin"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <InputNumber
                  min={0}
                  max={100}
                />
              </Form.Item>

              
            ) : null}
          <Form.Item
            label="Remark" 
            name="remark"
            style={{ alignSelf: 'center', width: '100%' }}
          >
            <TextArea />
          </Form.Item>
          
          <Form.List 
            name="contact_person"
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: 2,
                    }}
                    align="baseline"
                  >
                    { name <= 0 ? setSubmitModel(true) : name }
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: 'Field is required',
                        },
                      ]}
                      
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'number']}
                      rules={[
                        {
                          required: true,
                          message: 'Field is required',
                        },
                        {
                          pattern: /^(01)[0-9][0-9]{8}$/,
                          message: 'Please valid phone number!',
                        },
                      ]}
                      
                      
                    >
                      <Input placeholder="Mobile Number" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'email']}
                      rules={[
                        {
                          required: true,
                          message: 'Field is required',
                        },
                      ]}
                      
                      
                    >
                      <Input placeholder="xyz@gmail.com" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      // rules={[
                      //   {
                      //     pattern: /^(01)[0-9][0-9]{8}$/,
                      //     message: 'Please insert Valid email!',
                      //   },
                      // ]}
                      
                      
                    >
                      <Input placeholder="description" />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                      />
                      ) : null}
                  </Space>
                ))}
                <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button style={{ display: 'flex', justifyContent: 'center' }} type="dashed" onClick={() => { add(); setSubmitModel(true); }} block icon={<PlusOutlined />}>
                    Contact Persons
                  </Button>
                </Form.Item>
              </>
          )}
          </Form.List>
          {submitmoel ? (
            <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          )
          : (
            <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button disabled type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      </Content>
     );
};
 
export default connect(null, { updateAccount, getAccountHelper, getAccountById })(EditAccount);
