import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { 
  Button, Form, Modal, Divider, Descriptions, Select, Input, PageHeader, InputNumber, Space, Row, Col,
} from 'antd';
import { 
  Content, 
} from 'antd/lib/layout/layout';

import { createAccount, getAccountHelper } from '../../redux/actions/apis/account';

const { TextArea } = Input;

const CreateAccount = ({ createAccount, getAccountHelper }) => {
    const [unions, setUnions] = useState([]);
    const [ent, setEnt] = useState([]);
    const [enterpriseadmin, setenterpriseAdmin] = useState('');
    const [StatusType, setStatusType] = useState(null);
    const [datahelpn, setDataHelp] = useState('');
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [submitmoel, setSubmitModel] = useState(false);
    const [form] = Form.useForm();
    let enterprise = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    useEffect(async () => {
      // const res = await getOrganizationHelper();
      const enterpriseall = await getAccountHelper();
      setEnt(enterpriseall);
      // setUnions(res);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
    };
    const handleChange = (value) => {
      setStatusType(value);
    };
    
  
    enterprise = (
      ent?.enterprise?.length > 0 ? ent?.enterprise?.map(item => createOption(item.id, item.name))
    : null);
    const onFinish = async (values) => {
      const code = await createAccount(values);
      if (code === 200) {
        form.resetFields();
      }
      
      console.log('Received values of form:', values);
    };
    
    
    return ( 
      <Content style={{ background: 'white' }}>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Account Create"
          subTitle="Enterprise Management"
        />
        <Divider> Account Create</Divider>
        
        <Form {...formItemLayout} name="dynamic_form_nest_item" onFinish={onFinish} form={form} autoComplete="off">
          <Row>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Name" 
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input placeholder="Name" />
              </Form.Item>
              
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Mobile Number" 
                name="mobile_number"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                  {
                    pattern: /^(01)[0-9][0-9]{8}$/,
                    message: 'Please valid phone number!',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input placeholder="017********" />
              </Form.Item>
              
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Email" 
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                    pattern: {
                      value: new RegExp(/\S+@\S+\.\S+/),
                      message: 'Email is not valid',
                    },
                  },
                  
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input placeholder="xyz@redorch.com" />
              </Form.Item>
              
            </Col>
            
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item 
                label="Enterprise"
                name="enterprise"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Select 
                  showSearch 
                >
                  {
                  ent?.enterprise_list?.map(item => (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                    
                  ))
                  }
                </Select>
              </Form.Item>
              
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item 
                label="Status"
                name="active"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Select>
                  <Select.Option value="true">Active</Select.Option>
                  <Select.Option value="false">Inactive</Select.Option>
                </Select>
              </Form.Item>
              
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Address" 
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <TextArea />
              </Form.Item>
              
            </Col>
            
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item 
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Select onChange={handleChange}>
                  <Select.Option value="trading">Trading</Select.Option>
                  <Select.Option value="production">Production</Select.Option>
                </Select>
              </Form.Item>

            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Description" 
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <TextArea />
              </Form.Item>

            </Col>
            { StatusType === 'trading' 
            ? (
              <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                <Form.Item
                  label="Profit Margin" 
                  name="profit_margin"
                  rules={[
                    {
                      required: true,
                      message: 'Field is required',

                    },
                  ]}
                  style={{ alignSelf: 'center', width: '100%' }}
                >
                  
                  <Input 
                    type="number" 
                    min={0}
                    max={100}
                  />
                
                </Form.Item>

              </Col>
            ) : null}
            <Divider> Contact Person </Divider>
            <Col xs={24}>
              
              <Form.List 
                name="contact_person"
                // rules={[
                //   {
                //     validator: async (_, contactPerson) => {
                //       if (!contactPerson || contactPerson.length < 0) {
                //         return Promise.reject(new Error('At least 1 Contact person'));
                //       }
                //     },
                //   },
                // ]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      
                      <Space
                        key={key} 
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: 2,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                          ]}
                          
                        >
                          <Input placeholder="Name" />
                        </Form.Item>
                        {console.log(fields.length)}
                        <Form.Item
                          {...restField}
                          name={[name, 'number']}
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                            {
                              pattern: /^(01)[0-9][0-9]{8}$/,
                              message: 'Please valid phone number!',
                            },
                          ]}
                          
                          
                        >
                          <Input placeholder="Mobile Number" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'email']}
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                              pattern: {
                                value: new RegExp(/\S+@\S+\.\S+/),
                                message: 'Email is not valid',
                              },
                            },
                            
                          ]}
                          
                          
                        >
                          <Input placeholder="email" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                          // rules={[
                          //   {
                          //     pattern: /^(01)[0-9][0-9]{8}$/,
                          //     message: 'Please insert Valid email!',
                          //   },
                          // ]}
                          
                          
                        >
                          <Input placeholder="description" />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                          />
                          ) : null}
                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                      </Space>
                    ))}
                    <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button style={{ display: 'flex', justifyContent: 'center' }} type="dashed" onClick={() => { add(); setSubmitModel(true); }} block icon={<PlusOutlined />}>
                        Add Contact Persons
                      </Button>
                    </Form.Item>
                  </>
              )}
              </Form.List>
            </Col>
            <Divider> Evaluate </Divider>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Owners Equity" 
                name="owners_equity"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                    pattern: {
                      value: new RegExp('^[0-9]$'),
                      message: 'Field accept number only',
                    },
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input type="number" />
              </Form.Item>

            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Asset" 
                name="asset"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                    pattern: {
                      value: new RegExp('^[0-9]$'),
                      message: 'Field accept number only',
                    },
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input type="number" />
              </Form.Item>

            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Payable" 
                name="payable"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                    pattern: {
                      value: new RegExp('^[0-9]$'),
                      message: 'Field accept number only',
                    },
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input type="number" />
              </Form.Item>

            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Cash" 
                name="cash"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                    pattern: {
                      value: new RegExp('^[0-9]$'),
                      message: 'Field accept number only',
                    },
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input type="number" />
              </Form.Item>

            </Col>
            
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Inventory" 
                name="inventory"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                    pattern: {
                      value: new RegExp('^[0-9]$'),
                      message: 'Field accept number only',
                    },
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input type="number" />
              </Form.Item>

            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Bank" 
                name="bank"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                    pattern: {
                      value: new RegExp('^[0-9]$'),
                      message: 'Field accept number only',
                    },
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input type="number" />
              </Form.Item>

            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="Receivable" 
                name="receivable"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                    pattern: {
                      value: new RegExp('^[0-9]$'),
                      message: 'Field accept number only',
                    },
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input type="number" />
              </Form.Item>

            </Col> 
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <Form.Item
                label="MFS" 
                name="mfs"
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                    pattern: {
                      value: new RegExp('^[0-9]$'),
                      message: 'Field accept number only',
                    },
                  },
                ]}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                <Input type="number" />
              </Form.Item>

            </Col>
            

          </Row>
      
          
          
          {/* <Form.Item style={{ marginLeft: '200px' }}> */}
          {submitmoel ? (
            <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          )
          : (
            <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button disabled type="primary" htmlType="submit">
                Please add minimun one contact person
              </Button>
            </Form.Item>
          )}
        </Form>
      </Content>
     );
};
 
export default connect(null, { getAccountHelper, createAccount })(CreateAccount);
