import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RoleList from '../../../../pages/role';
import UserList from '../../../../pages/user';
import CreateUser from '../../../../pages/user/createUser';
import EditUser from '../../../../pages/user/editUser';
import CreateRole from '../../../../pages/role/createRole';
import EditRole from '../../../../pages/role/editRole';

export default () => (
  <Switch>
    <Route exact path="/user/list" component={UserList} />
    <Route exact path="/user/create" component={CreateUser} />
    <Route exact path="/user/edit" component={EditUser} />
    <Route exact path="/user/role" component={RoleList} />
    <Route exact path="/user/create-role" component={CreateRole} />
    <Route exact path="/user/edit-role" component={EditRole} />
  </Switch>
);
