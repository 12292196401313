import React from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import PasswordField from '@/shared/components/form/Password';
import { Button } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { login } from '../../../../redux/actions/apis/auth';

const AccountForm = ({ login }) => {
  const onSubmit = (data) => {
    const old = data;
    old.email = localStorage.getItem('email');
    login(old);
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  
  return (
    <>
      <form className={`form ${true && 'form--horizontal'}`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <PasswordField
                  input={field}
                  meta={{ touched: !!fieldState.error, error: fieldState.error?.message }}
                  placeholder="Password"
                  isAboveError={false}
                />
              )}
              rules={{ 
                required: 'This is required field', 
                // pattern: { 
                //   value: /^[A-Za-z\d@$!%*?&]{8,50}$/,
                //   message: 'Minimum 8 character, allowed special characters are @$!%*?&',
                // },
              }}
              defaultValue=""
            />
          </div>
        </div>
        
        <Button className="btn btn-primary account__btn" color="primary" type="submit">Login</Button>
        <NavLink className="btn btn-outline-danger account__btn" to="/home">Logout</NavLink>
      </form>
    </>
  );
};

export default connect(null, { login })(AccountForm);
