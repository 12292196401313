import React, { useEffect, useState } from 'react';
import { 
  Button, ButtonToolbar, Container, Modal,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { 
  Col, Row, Statistic, Badge, Descriptions, Timeline, Divider, List, Tag, 
 } from 'antd';
import { connect } from 'react-redux';
import { 
  getTransactionLoanById, 
} from '../../redux/actions/apis/transaction';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import ViewTransactionDetails from './viewTransaction';

const ViewTransaction = ({ 
  getTransactionLoanById, toggle, open, id,
 }) => {
  const [data, setData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [transactionid, setTransactionId] = useState('');
  const getData = async () => {
    const res = await getTransactionLoanById(id);
    setData(res);
  };
  const handleTransactionView = () => {
    setViewModal(prevState => !prevState);
  };
  const handleTransactionData = async (e) => {
    console.log(e);
    // const res = await getValidMerchantShop(value);
  };
  useEffect(() => {
    getData();
  }, []);

  
  return (
    <Container>
      <Modal
        isOpen={open}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
        size="lg" 
        style={{ maxWidth: '90%', width: '100%' }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          
          <h4 className="text-modal  modal__title" style={{ color: '#1a0e75', fontSize: '30px' }}>Loan Details</h4>
        </div>
        <div style={{ pading: '20px' }}>
          {/* <h4>Subject : {data?.subject} </h4> */}
          <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Loan Info</h4>
          <Tag color="#2db7f5">ID: {data?.detail?.loan_id}</Tag>
          <Tag color="#00b894"> State : {data?.detail?.loan_state}</Tag> 
          <Tag color="#cd2df5">Type : {data?.detail?.state}</Tag>
          <Tag color="#db2775">Total Amount : {data?.detail?.total_amount}</Tag>
          <Tag color="#D3CB2A">Paid amount : {data?.detail?.paid_amount}</Tag>
          <Tag color="#16ceb2">{data?.detail?.created_at}</Tag>
          <br />
          <br />
        </div>
        <div className="modal__body">
          
          <Row gutter={16}>
            {/* <Col span={24}>
              <Descriptions title="Loan Info" size="small">
                <Descriptions.Item label="Id">{data?.detail?.loan_id}</Descriptions.Item>
                <Descriptions.Item label="State">{data?.detail?.loan_state}</Descriptions.Item>
                <Descriptions.Item label="Type">{data?.detail?.state}</Descriptions.Item>
                <Descriptions.Item label="Total Amount">{data?.detail?.total_amount}</Descriptions.Item>
                <Descriptions.Item label="Paid Amount">{data?.detail?.paid_amount}</Descriptions.Item>
                <Descriptions.Item label="Error">{data?.detail?.is_error
                  ? <Badge status="error" text="Problem" /> : <Badge status="success" text="No Problem" />}
                </Descriptions.Item>
                <Descriptions.Item label="Created at">{data?.detail?.created_at}</Descriptions.Item>
                <Descriptions.Item label="Update at">{data?.detail?.updated_at}</Descriptions.Item>
              </Descriptions>
            </Col> */}
            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Issuer Info</h4>
              <Descriptions size="small">
                <Descriptions.Item label="Name">{data?.detail?.info?.name}</Descriptions.Item>
                <Descriptions.Item label="Mobile Number">{data?.detail?.info?.mobile_number}</Descriptions.Item>
                <Descriptions.Item label="Type">{data?.detail?.info?.type}</Descriptions.Item>
                <Descriptions.Item label="Description">{data?.detail?.info?.description}</Descriptions.Item>
                
              </Descriptions>
            </Col>
            
            
            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Transaction Info</h4>
              <br />
              <div>
                {data?.detail?.transaction_list?.slice(0)?.reverse()?.map((item, id) => (
                  <>
                    <Row>
                      <Col xs={1}>{id + 1} </Col>
                      <Col xs={4}>ID: {item?.transaction_id} </Col>
                      <Col xs={4}>Amount: {item?.amount} </Col>
                      <Col xs={4}> State: {item?.transaction_state}</Col>
                      <Col xs={7}> Time: {item?.created_at}</Col>
                      <Col xs={4}> 
                        <Tag 
                          color="#108ee9" 
                          style={{ cursor: 'pointer' }} 
                          // onChange={e => handleTransactionData(e)}
                          onClick={() => { handleTransactionView(item?.transaction_id); setTransactionId(item?.transaction_id); }}
                        >
                          View
                        </Tag>
                      </Col>
                      
                      
                    </Row>
                    <br />
                  </>
                  
                ))}
              </div>
              
            </Col>
            <Col span={24}>
              <h4 className="text-modal  modal__title" style={{ background: '#74B9FF', color: 'white', padding: '4px 0px' }}>Logs</h4>
              <br />
              <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
                {data?.detail?.logs?.slice(0)?.reverse()?.map(item => (
                  <Timeline.Item>
                    <p>{item?.message}</p>
                    <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{item?.created_at}</p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col>
            
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
      {viewModal ? <ViewTransactionDetails id={transactionid} toggle={handleTransactionView} open={viewModal} /> : null}
    </Container>
  );
};

export default connect(null, { 
  getTransactionLoanById,
 })(ViewTransaction);
