import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../../../Layout/index';
import OnLineMarketingDashboard from '../../../Dashboards/OnLineMarketing/index';
import history from '../../../../history';
import User from './User';
import Agent from './Agent';
import Union from './Union';
import Candidate from './candidate';
import setting from './setting';
import FinanceDashboard from '../../../Dashboards/Finance';
import Organization from './Organization';
import OrganizationUser from './OrganizationUser';
import product from './product';
import Account from './Account';
import Ticket from './Ticket';
import subject from './subject';
import transaction from './transaction';

export default () => {
  useEffect(() => {
    if (!localStorage.getItem('tk_auth')) {
      history.push('/login');
    }
  }, []);
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Route path="/user" component={User} />
        <Route path="/enterprise" component={Organization} />
        <Route path="/enterprise-account" component={Account} />
        <Route path="/enterprise-user" component={OrganizationUser} />
        <Route path="/ticket" component={Ticket} />
        <Route path="/subject" component={subject} />
        <Route path="/transactions" component={transaction} />
        <Route path="/product" component={product} />
        <Route exact path="/dash" component={FinanceDashboard} />
        <Route exact path="/home" component={OnLineMarketingDashboard} />
        {/* <Route exact path="/" component={OnLineMarketingDashboard} /> */}
        <Redirect from="/" to="/home" />
      </div>
    </div>
  );
};
