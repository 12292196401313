import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AgentList from '../../../../pages/agent';
import CreateAgent from '../../../../pages/agent/createAgent';
import EditAgent from '../../../../pages/agent/editAgent';
import OrganizationUserList from '../../../../pages/organizationuser';
import createOrganizationUser from '../../../../pages/organizationuser/createOrganizationUser';
import editOrganizationUser from '../../../../pages/organizationuser/editOrganizationUser';

export default () => (
  <Switch>
    <Route exact path="/enterprise-user/list" component={OrganizationUserList} />
    <Route exact path="/enterprise-user/create" component={createOrganizationUser} />
    <Route exact path="/enterprise-user/edit" component={editOrganizationUser} />
  </Switch>
);
