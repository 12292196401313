import React, { useEffect, useState } from 'react';
import { 
  Badge, Button, ButtonToolbar, Container, Modal, Row,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { Tag, PageHeader } from 'antd';
import { connect } from 'react-redux';

import { 
  getAllOrganization, updateOrganization, getOrganizationById, updateOrganizationStatus, getOrganizationHelper,
} from '../../redux/actions/apis/organization';
// import Pageheader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import EditForm from '../../global/editForm';

import ViewAgent from './view';

const columns = [
  {
    Header: '#SL',
    accessor: 'id',
    disableGlobalFilter: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  
  {
    Header: 'Status',
    accessor: 'authenticated',
    disableGlobalFilter: true,
  },
  {
    Header: 'Mobile Number',
    accessor: 'mobileNumber',
  },
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
  },
];

const OrganizationList = ({ 
  getAllOrganization, updateOrganizationStatus, updateAgentPassword, updateOrganization, getOrganizationById, areaList, getOrganizationHelper,
 }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState('');
  const [viewModal, setViewModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(false); 
  const [pageOptions, setPageOptions] = useState(null); 
  const [page, setPage] = useState(1); 
  const [name, setName] = useState(''); 
  const [userId, setUserId] = useState(''); 
  const [email, setEmail] = useState(''); 
  const [status, setStatus] = useState(''); 
  const [ds, setDS] = useState(''); 
  const [de, setDE] = useState(''); 
  const [mobilenumber, setMobileNumber] = useState(''); 


  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const vtoggle = () => {
    setViewModal(prevState => !prevState);
  };

  const getData = async () => {
  const res = await getAllOrganization(page, name, mobilenumber, status, email, ds, de);
    setData(res?.list);
    setCount(res?.count);

    const boundary = Math.ceil(res?.count / 20);
    
    const pageNumber = [];
      for (let i = 0; i < boundary; i += 1) {
      pageNumber.push({
        value: i + 1,
        label: i + 1, 
      });
    }
    setPageOptions(pageNumber);
  };
  
  useEffect(() => {
    getData();
  }, []);


  const handleStatus = async (val, id) => {
    const res = await updateOrganizationStatus(val, id);
    if (res) {
      getData();
    }
  };
  
  const createData = (id, name, email, mobileNumber, authenticated, action) => {
    const a = 0;
    return { 
      id, name, email, mobileNumber, authenticated, action,
    };
  };
  
  const rows = [...
    data
      ? data?.map((item, i) => createData(
        i + 1,  
        item?.name, 
        item?.email ? item?.email : '', 
        item?.mobile_number, 
        <>
          {item?.active 
          ? (
            <> 
              <Tag color="#87d068">Active</Tag> &nbsp;
              <Tag size="sm" color="#f50" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(false, item?.id); }}>Disable</Tag> 
            </>
          ) 
          : (
            <>
              <Tag size="sm" color="#f50">Inactive</Tag> &nbsp;
              <Tag size="sm" color="#87d068" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(true, item?.id); }}>Activate</Tag> 
            </>
            )}
        </>,
        <>
          <Tag 
            color="#BFD62A" 
            style={{ cursor: 'pointer' }} 
            onClick={() => history.push(`/enterprise/edit?id=${item?.id}`)}
          >
            Edit
          </Tag> &nbsp;
          <Tag 
            color="#108ee9" 
            style={{ cursor: 'pointer' }} 
            // onClick={() => { vtoggle(item?.id); setId(item?.id); }}
            onClick={() => { history.push(`/enterprise/details?id=${item?.id}`); setId(item?.id); }}
          >
            View
          </Tag> &nbsp;
          {/* <Tag color="#108ee9" style={{ cursor: 'pointer' }} onClick={() => { setId(item?.id); toggle(); }}>Re-Evaluate</Tag> */}
        </>,
        ))
      : '',
  ];

  const fields = [
    {
      type: 'password', 
      label: 'Password', 
      name: 'password', 
      placeholder: '8 Digit Password', 
      rules: { 
        required: 'This is required field', 
        pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
      },
    },
  ];
  const handleReset = (e) => {
    setPage('1');
    setName('');
    setDE('');
    setDS('');
    setMobileNumber('');
    setEmail('');
    setStatus('');
    getData('1', '', '', '', '', '', '');
  };
  const handleSearch = async (event) => {
    const res = await getAllOrganization(page, name, mobilenumber, status, email, ds, de);
    setData(res?.list);
    setCount(res?.count);
  };
  const handleResetN = async (event) => {
    setPage('1');
    setName('');
    setDE('');
    setDS('');
    setMobileNumber('');
    setEmail('');
    setStatus('');
    const res = await getAllOrganization(1, '', '', '', '', '', '');
    setData(res?.list);
    setCount(res?.count);
  };

  const handlePageSearch = async (e) => {
    const res = await getAllOrganization(e, name, mobilenumber, status, email, ds, de);
    setData(res?.list);
    setCount(res?.count);
  };

  
  


  // unionOption = (
  //   unions ? unions?.map(item => createOption(item.union_id, item.name))
  // : null);
  
  return (
    <Container>
      <Row>
        <PageHeader
          ghost={false}
          style={{ background: '#f2f4f7' }}
          onBack={() => window.history.back()}
          title="Enterprise management"
          subTitle="Manage all kind of Enterprise operations"
        />
        {/* <Pageheader title="Enterprise management" subTitle="Manage all kind of Enterprise operations" /> */}
        <Form inline style={{ padding: '0px 25px' }}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="name" className="mr-sm-2">Name </Label>
            <Input bsSize="sm" value={name} type="input" id="name" placeholder="" onChange={(e) => { setName(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="user" className="mr-sm-2">Mobile Number</Label>
            <Input bsSize="sm" type="input" value={mobilenumber} id="user" placeholder="" onChange={(e) => { setMobileNumber(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="email" className="mr-sm-2">Email </Label>
            <Input bsSize="sm" type="input" value={email} id="email" placeholder="" onChange={(e) => { setEmail(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          
          <FormGroup>
            <Label for="a">Status &nbsp; </Label>
            <Input bsSize="sm" value={status} type="select" name="select" id="a" onChange={(e) => { setStatus(e.target.value); }}>
              <option value="">Select One</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="ds" className="mr-sm-2">Start Date </Label>
            <Input bsSize="sm" value={ds} type="date" name="dates" id="st" onChange={(e) => { setDS(e.target.value); }} />
          </FormGroup>
          
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="de" className="mr-sm-2">End Date </Label>
            <Input bsSize="sm" value={de} type="date" name="datee" id="ste" onChange={(e) => { setDE(e.target.value); }} />
          </FormGroup>
          
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mt-sm-4">
            <Button color="primary" type="button" size="sm" onClick={handleSearch}>Search</Button>&nbsp;
            {/* <Button color="primary" type="button" size="sm" onClick={handleDownload}>Download</Button>&nbsp; */}
            <Button color="danger" type="button" size="sm" onClick={handleResetN}>Reset</Button>
          </FormGroup>
        </Form>
        <Table 
          columns={columns} 
          rows={rows} 
          title="Enterprise List" 
          subTitle="All Enterprise" 
          formTitle="Create a new Enterprise" 
          samePage={false} 
          route="/enterprise/create"
          count={count}
          current={pageOptions}
          handlePageSearch={handlePageSearch}
          isResizable={!false}
        />
      </Row>
       
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Change Password</h4>
        </div>
        <div className="modal__body">
          <EditForm isAboveError fiels={fields} submitFnc={updateAgentPassword} id={id} closeFnc={toggle} />
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
      
      {viewModal ? <ViewAgent id={id} toggle={vtoggle} open={viewModal} /> : null}
    </Container>
  );
};

export default connect(null, { 
  getAllOrganization, updateOrganizationStatus, updateOrganization, getOrganizationById, getOrganizationHelper,
 })(OrganizationList);
