import React, { useEffect, useState } from 'react';
import { 
  Badge, Button, ButtonToolbar, Container, Modal, Row,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import { Tag } from 'antd';
import { 
  getCandidate, 
} from '../../redux/actions/apis/candidate';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import ViewUnion from './view';
import { areaList } from '../../redux/actions/apis/union';

const columns = [
  {
    Header: '#SL',
    accessor: 'id',
    disableGlobalFilter: true,
  },
  {
    Header: 'Candidate id',
    accessor: 'candidateId',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'NID',
    accessor: 'nid',
  },
  {
    Header: 'Image',
    accessor: 'image',
    disableGlobalFilter: true,
  },
  {
    Header: 'Union',
    accessor: 'address',
    disableGlobalFilter: true,
  },
  {
    Header: 'Ward',
    accessor: 'ward',
  },
  {
    Header: 'Group',
    accessor: 'group',
  },
  {
    Header: 'Status',
    accessor: 'authenticated',
    disableGlobalFilter: true,
  },
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
  },
];

const CandidateList = ({ 
  getCandidate, areaList,
 }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState('');
  const [allArea, setAllArea] = useState([]);
  const [page, setPage] = useState(1); 
  const [name, setName] = useState(''); 
  const [user, setUser] = useState(''); 
  const [nid, setNid] = useState(''); 
  const [cName, setCName] = useState(''); 
  const [wardNumber, setWardNumber] = useState(''); 
  const [groupNumber, setGroupNumber] = useState(''); 
  const [d, setD] = useState(false); 
  const [candidateEntry, setCandidateEntry] = useState(''); 
  const [status, setStatus] = useState(''); 
  const [unionCode, setUnionCode] = useState('');
  const [pageOptions, setPageOptions] = useState(null); 
  const [id, setId] = useState(null); 
  const [open, setOpen] = useState(false); 
  const [allDistrict, setAllDistrict] = useState(null);
  const [district, setDistrict] = useState('');
  const [upazilla, setUpazilla] = useState('');
  const [allupazilla, setAllupazilla] = useState(null);
  const [allunion, setAllunion] = useState(null);
  const [allunionOption, setAllunionOption] = useState(null);
  const toggle = () => {
    setOpen(prevState => !prevState);
  };

  const getData = async () => {
    const res = await getCandidate(page, user, nid, status, cName, wardNumber, groupNumber, name, unionCode, district, upazilla, d);
    setData(res?.list);
    setCount(res?.count);
    const boundary = Math.ceil(res?.count / 20);
    const pageNumber = [];
      for (let i = 0; i < boundary; i += 1) {
        pageNumber.push({
          value: i + 1,
          label: i + 1, 
        });
    }
    
    setPageOptions(pageNumber);
    
    const dis = [];
    const upz = [];
    const uni = [];
    const code = [];
    const area = await areaList();
    setAllArea(area);
    for (let i = 0; i < area.length; i += 1) {
      const element = area[i];
      dis.push(element?.district);
    }
    setAllDistrict([...new Set(dis)]);

    for (let i = 0; i < area.length; i += 1) {
      const element = area[i];
      upz.push(element?.upazilla);
    }
    setAllupazilla([...new Set(upz)]);

    for (let i = 0; i < area.length; i += 1) {
      const element = area[i];
      uni.push(`${area[i]?.union}-${area[i]?.union_code}`);
      // code.push({ value: element?.union_code, label: element?.union });
      code.push([element?.union_code]);
    }
    setAllunion([...uni]);
    // setAllunionOption([...code]);
    setAllunionOption(code);
  };

  useEffect(() => {
    getData();
  }, []);


  
  const createData = (id, candidateId, name, nid, image, address, ward, group, authenticated, action) => {
    const a = 0;
    return { 
      id, candidateId, name, nid, image, address, ward, group, authenticated, action,
    };
  };
  
  const rows = [...
    data
      ? data?.map((item, i) => createData(
        i + 1, 
        item?.candidate_id, 
        item?.name,  
        item?.nid, 
        <img style={{ width: '50px' }} src={`https://sbss-api.redorch.com/files/${item?.image}`} alt="" />,
        <>
          <p style={{ margin: '1px 0px' }}>{item?.district}</p>
          <p style={{ margin: '1px 0px' }}>{item?.upazilla}</p>
          <p style={{ margin: '1px 0px' }}>{item?.union_name}</p>
          <p style={{ margin: '1px 0px' }}>{item?.union_code}</p>
        </>, 
        item?.word_no,
        item?.group === 1 ? 'Employment Group' : 'Enterprise & IGA Group',
        <p style={{ margin: '0px', textAlign: 'center' }}>
          {item?.status}
          <br />
          {item?.rejection_level ? <Tag color="red"> {item?.rejection_level} </Tag> : null}
        </p>,
        <>
          <Tag
            color="#108ee9" 
            style={{ cursor: 'pointer' }} 
            onClick={() => { toggle(); setId(item?.candidate_id); }}
          >
            &nbsp; View &nbsp;
          </Tag>
        </>,
        ))
      : '',
  ];

  const handleSearch = async (event) => {
    const res = await getCandidate(page, user, nid, status, cName, wardNumber, groupNumber, name, unionCode, district, upazilla, d);
    setData(res?.list);
    setCount(res?.count);
  };

  const handlePageSearch = async (e) => {
    const res = await getCandidate(e, user, nid, status, cName, wardNumber, groupNumber, name, unionCode, district, upazilla, false);
    setData(res?.list);
    setCount(res?.count);
  };

  const handleDownload = async (e) => {
    const res = await getCandidate(page, user, nid, status, cName, wardNumber, groupNumber, name, unionCode, district, upazilla, true);
  };

  const handleReset = async (event) => {
    setPage('');
    setUser('');
    setNid('');
    setStatus('');
    setCName('');
    setWardNumber('');
    setGroupNumber('');
    setDistrict('');
    setUpazilla('');
    setName('');
    setUnionCode('');
    setD(false);
    const res = await getCandidate(1, '', '', '', '', '', '', '', '', '', '', false);
    setData(res?.list);
    setCount(res?.count);
  };

  const getUpazilla = (val) => {
    setName('');
    setUpazilla('');
    setUnionCode('');
    setDistrict(val);
    const upa = [];
    for (let i = 0; i < allArea.length; i += 1) {
      const element = allArea[i]?.district;
      if (element === val) {
        upa.push(allArea[i]?.upazilla);
      }
    }
    setAllupazilla([...new Set(upa)]);
    setAllunion(null);
    setAllunionOption(null);
  };

  const getUnion = (val) => {
    setName('');
    setUnionCode('');
    setUpazilla(val);
    const un = [];
    for (let i = 0; i < allArea.length; i += 1) {
      const element = allArea[i]?.upazilla;

      if (element === val) {
        un.push(allArea[i]?.union);
      }
    }
    setAllunion([...new Set(un)]);
    setAllunionOption(null);
  };

  const getCode = (val) => {
    setName(val);
    const co = [];
    for (let i = 0; i < allArea.length; i += 1) {
      const element = allArea[i]?.union_code;
      if (element === val.split('-')[1]) {
        setAllunionOption([allArea[i]?.union_code]);
        setUnionCode(allArea[i]?.union_code);
        break;
      } else setUnionCode('');
    }
  };

  const getName = (val) => {
    setUnionCode(val);
    for (let i = 0; i < allArea.length; i += 1) {
      const element = allArea[i]?.union_code;

      if (element === val) {
        setName(allArea[i]?.union);
        break;
      } else setName('');
    }
  };

  return (
    <Container>
      <Row>
        <PageHeader title="Candidate management" subTitle="Manage all kind of candidate operations" />
        <Form inline style={{ padding: '0px 25px' }}>
         
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="user" className="mr-sm-2">Candidate ID </Label>
            <Input bsSize="sm" value={user} type="input" id="user" placeholder="" onChange={(e) => { setUser(e.target.value); }} />
          </FormGroup>
          
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="nid" className="mr-sm-2">NID</Label>
            <Input bsSize="sm" value={nid} type="input" id="nid" placeholder="" onChange={(e) => { setNid(e.target.value); }} />
          </FormGroup>
          
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="nid" className="mr-sm-2">Name</Label>
            <Input bsSize="sm" value={cName} type="input" id="cName" placeholder="" onChange={(e) => { setCName(e.target.value); }} />
          </FormGroup>
          
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="nid" className="mr-sm-2">Ward No.</Label>
            <Input bsSize="sm" value={wardNumber} type="input" id="wardNumber" placeholder="" onChange={(e) => { setWardNumber(e.target.value); }} />
          </FormGroup>
          
          <FormGroup>
            <Label for="a">Group &nbsp; </Label>
            <Input bsSize="sm" value={groupNumber} type="select" name="groupNumber" id="groupNumber" onChange={(e) => { setGroupNumber(e.target.value); }}>
              <option value="">Select One</option>
              <option value="">All</option>
              <option value="1">Employment Group</option>
              <option value="2">Enterprise & IGA Group</option>
            </Input>
          </FormGroup>
          
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="name" className="mr-sm-2">District </Label>
            <Input bsSize="sm" value={district} type="select" id="district" onChange={(e) => { getUpazilla(e.target.value); }}>
              <option value="1">Select District</option>
              {allDistrict?.length
                ? allDistrict?.map(item => (
                  <option value={item}>{item}</option>
                ))
              : null}
            </Input>
          </FormGroup>

          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="upazilla" className="mr-sm-2">Upazilla </Label>
            <Input bsSize="sm" value={upazilla} name="upazilla" type="select" id="upazilla" onChange={(e) => { getUnion(e.target.value); }}>
              <option value="1">Select Upazilla</option>
              {allupazilla?.length
              ? allupazilla?.map(item => (
                <option value={item}>{item}</option>
              ))
              : null}
            </Input>
          </FormGroup>
          
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="name" className="mr-sm-2">Union</Label>
            <Input bsSize="sm" name="name" value={name} type="select" id="name" onChange={(e) => { getCode(e.target.value); }}>
              <option value="1">Select Union</option>
              {allunion?.length
              ? allunion.map(item => (
                // console.log(item)
                <option value={item}>{(item?.split('-'))[0]}</option>
              ))
              : null}
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="union">Union Code &nbsp; </Label>
            <Input bsSize="sm" value={unionCode} type="select" id="unioncode" name="unioncode" onChange={(e) => { getName(e.target.value); }}>
              <option value="1">Select Union</option>
              {allunionOption?.length > 0 
              ? allunionOption.map((item, key) => (
                <option value={item}>{item}</option>
                ))
              : null}
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          
          <FormGroup>
            <Label for="a">Status &nbsp; </Label>
            <Input bsSize="sm" value={status} type="select" name="select" id="a" onChange={(e) => { setStatus(e.target.value); }}>
              <option value="">Select One</option>
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="waiting">Lottery</option>
              <option value="e-waiting">Extra Waiting</option>
              <option value="selected">Selected</option>
              <option value="verification">Home Verification</option>
              <option value="rejected">Rejected</option>
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mt-sm-4">
            <Button color="primary" type="button" size="sm" onClick={handleSearch}>Search</Button>&nbsp;
            <Button color="danger" type="button" size="sm" onClick={handleReset}>Reset</Button>
            <Button color="danger" type="button" size="sm" onClick={handleDownload}>Download</Button>
          </FormGroup>
        </Form>
        <Table 
          columns={columns}
          rows={rows}
          title="Candidate List"
          subTitle="All Candidates"
          samePage={null}
          count={count}
          handlePageSearch={handlePageSearch}
          isResizable={false}
        />
        
      </Row>
       
      {open ? <ViewUnion id={id} toggle={toggle} open={open} /> : null}
    </Container>
  );
};

export default connect(null, { 
  getCandidate, areaList,
 })(CandidateList);
