import React, { useEffect, useState } from 'react';
import { 
  Badge, Button, ButtonToolbar, Container, Modal, Row,
  Form, FormGroup, Label, Input, Breadcrumb, BreadcrumbItem, 
 } from 'reactstrap';
 import { PageHeader, Tag } from 'antd';
import { connect } from 'react-redux';
import { 
  getAllAccount, updateAccountStatus, 
} from '../../redux/actions/apis/account';
import { 
  getOrganizationHelper, getOrganizationById,
} from '../../redux/actions/apis/organization';
// import Pageheader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import EditForm from '../../global/editForm';
import ViewAccount from './view';

const columns = [
  {
    Header: '#SL',
    accessor: 'id',
    disableGlobalFilter: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Mobile Number',
    accessor: 'mobileNumber',
  },
  {
    Header: 'Status',
    accessor: 'authenticated',
    disableGlobalFilter: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableGlobalFilter: true,
  },
  {
    Header: 'Enterprise',
    accessor: 'enterprise',
    disableGlobalFilter: true,
  },
  
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
  },
];

const AccountList = ({ 
  getAllAccount, updateAgentPassword, updateAccountStatus, getOrganizationHelper, getOrganizationById,
 }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState('');
  const [viewModal, setViewModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(false); 
  const [allArea, setAllArea] = useState([]);

  const [pageOptions, setPageOptions] = useState(null); 
  const [page, setPage] = useState(1); 
  const [type, setType] = useState(''); 
  const [name, setName] = useState(''); 
  const [userId, setUserId] = useState(''); 
  const [enterprise, setEnterprise] = useState(''); 
  const [enterprisedata, setEnterpriseData] = useState(''); 
  const [mbl, setMbl] = useState('');  
  const [emails, setEmails] = useState('');
  const [email, setEmail] = useState(''); 
  const [status, setStatus] = useState(''); 
  const [ds, setDS] = useState(''); 
  const [de, setDE] = useState(''); 
  const [mobilenumber, setMobileNumber] = useState('');
  const [accounts, setAccounts] = useState('');
  const [product, setProduct] = useState([]);
  const [productfun, setProductfun] = useState([]);

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const vtoggle = () => {
    setViewModal(prevState => !prevState);
  };

  const getData = async () => {
    const res = await getAllAccount(page, name, type, enterprise, mbl, status, emails, ds, de);
      setData(res?.list);
      setCount(res?.count);
    const entdata = await getOrganizationHelper();
    setEnterpriseData(entdata);
      const boundary = Math.ceil(res?.count / 20);
      const pageNumber = [];
        for (let i = 0; i < boundary; i += 1) {
        pageNumber.push({
          value: i + 1,
          label: i + 1, 
        });
      }
      setPageOptions(pageNumber);
    };

  useEffect(() => {
    getData();
  }, []);

  const handleEnterprise = async (e) => {
    const id = e.target.value;
    setEnterprise(id); 
    const res = await getOrganizationById(e.target.value);
    setProductfun(res);
  };

  const handleStatus = async (val, id) => {
    const res = await updateAccountStatus(val, id);
    if (res) {
      getData();
    }
  };
  
  const createData = (id, name, email, mobileNumber, authenticated, type, enterprise, action) => {
    const a = 0;
    return { 
      id, name, email, mobileNumber, authenticated, type, enterprise, action,
    };
  };
  
  const rows = [...
    data
      ? data?.map((item, i) => createData(
        i + 1, 
        item?.name, 
        item?.email ? item?.email : '', 
        item?.mobile_number, 
        <>
          {item?.active 
          ? (
            <> 
              <Tag color="#87d068">Active</Tag> &nbsp;
              <Tag size="sm" color="#BA2525" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(false, item?.id); }}>Disable</Tag> 
            </>
          ) 
          : (
            <>
              <Tag size="sm" color="#f50">Inactive</Tag> &nbsp;
              <Tag size="sm" color="#87d068" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(true, item?.id); }}>Activate</Tag> 
            </>
            )}
        </>,
        item?.type,
        item?.enterprise?.name,
        <>
          <Tag 
            color="#D3BF2A" 
            style={{ cursor: 'pointer' }} 
            onClick={() => history.push(`/enterprise-account/edit?id=${item?.id}`)}
          >
            Edit
          </Tag> &nbsp;
          <Tag 
            color="#108ee9" 
            style={{ cursor: 'pointer' }} 
            onClick={() => { history.push(`/enterprise-account/view?id=${item?.id}`); setId(item?.id); }}
          >
            View
          </Tag> &nbsp;
          <Tag color="#26BABF" style={{ cursor: 'pointer' }} onClick={() => history.push(`/enterprise-account/reevaluate-edit?id=${item?.id}`)}>Re-Evaluate</Tag>
        </>,
        ))
      : '',
  ];

  const fields = [
    {
      type: 'password', 
      label: 'Password', 
      name: 'password', 
      placeholder: '8 Digit Password', 
      rules: { 
        required: 'This is required field', 
        pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
      },
    },
  ];

  const handleSearch = async (event) => {
    const res = await getAllAccount(page, name, type, enterprise, mobilenumber, status, email, ds, de);
    setData(res?.list);
    setCount(res?.count);
  };
  const handleResetN = async (event) => {
    const res = await getAllAccount(1, '', '', '', '', '', '', '', '');
    setPage('1');
    setName('');
    setDS('');
    setDE('');
    setStatus('');
    setMobileNumber('');
    setEmail('');
    setType('');
    setEnterprise('');
    setData(res?.list);
    setCount(res?.count);
  };

  const handlePageSearch = async (e) => {
    const res = await getAllAccount(e, name, type, enterprise, mobilenumber, status, email, ds, de);
    
    setData(res?.list);
    setCount(res?.count);
  };

  const handleReset = () => {
    setPage('1');
    setName('');
    setDS('');
    setDE('');
    setStatus('');
    setMobileNumber('');
    setEmail('');
    setType('');
    setEnterprise('');
    getData('1', '', '', '', '', '', '', '');
  };




  return (
    <Container>
      <Row>
        <PageHeader
          ghost={false}
          style={{ background: '#f2f4f7' }}
          onBack={() => window.history.back()}
          title="Enterprise management"
          subTitle="Manage all kind of account operations"
        />
        {/* <Pageheader ghost={false} onBack={() => window.history.back()} title="Enterprise management" subTitle="Manage all kind of account operations" /> */}
        <Form inline style={{ padding: '0px 25px' }}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="name" className="mr-sm-2">Name </Label>
            <Input bsSize="sm" value={name} type="input" id="name" placeholder="" onChange={(e) => { setName(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="mbl" className="mr-sm-2">Mobile Number</Label>
            <Input bsSize="sm" type="input" value={mobilenumber} id="mbl" placeholder="" onChange={(e) => { setMobileNumber(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="email" className="mr-sm-2">Email </Label>
            <Input bsSize="sm" type="input" value={email} id="email" placeholder="" onChange={(e) => { setEmail(e.target.value); }} />
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          
          <FormGroup>
            <Label for="a">Active &nbsp; </Label>
            <Input bsSize="sm" value={status} type="select" name="select" id="a" onChange={(e) => { setStatus(e.target.value); }}>
              <option value="">Select One</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="a">Type &nbsp; </Label>
            <Input bsSize="sm" value={type} type="select" name="select" id="a" onChange={(e) => { setType(e.target.value); }}>
              <option value="">Select One</option>
              <option value="trading">Trading</option>
              <option value="production">Production</option>
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup>
            <Label for="ent">Enterprise &nbsp; </Label>
            <Input 
              bsSize="sm" 
              value={enterprise} 
              type="select" 
              name="enterprise" 
              id="ent" 
              onChange={async (e) => { handleEnterprise(e); }}
            >
              <option value="">Select One</option>
              {
              enterprisedata?.enterprise_list?.map(item => (
                <option value={item.id}>{item.name}</option>
                  
                ))
              }
              
             
            </Input>
          </FormGroup>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="ds" className="mr-sm-2">Start Date </Label>
            <Input bsSize="sm" value={ds} type="date" name="dates" id="st" onChange={(e) => { setDS(e.target.value); }} />
          </FormGroup>
          
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mb-sm-1">
            <Label for="de" className="mr-sm-2">End Date </Label>
            <Input bsSize="sm" value={de} type="date" name="datee" id="ste" onChange={(e) => { setDE(e.target.value); }} />
          </FormGroup>
          
          &nbsp;
          &nbsp;
          &nbsp;
          <FormGroup className="mb-2 mr-sm-2 mt-sm-4">
            <Button color="primary" type="button" size="sm" onClick={handleSearch}>Search</Button>&nbsp;
            {/* <Button color="primary" type="button" size="sm" onClick={handleDownload}>Download</Button>&nbsp; */}
            <Button color="danger" type="button" size="sm" onClick={handleResetN}>Reset</Button>
          </FormGroup>
        </Form>
        <Table 
          columns={columns} 
          rows={rows} 
          title="Account List" 
          subTitle="All Account" 
          formTitle="Create a new Account" 
          samePage={false} 
          route="/enterprise-account/create"
          count={count}
          current={pageOptions}
          handlePageSearch={handlePageSearch}
          isResizable={!false}
        />
      </Row>
       
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Change Password</h4>
        </div>
        <div className="modal__body">
          <EditForm isAboveError fiels={fields} submitFnc={updateAgentPassword} id={id} closeFnc={toggle} />
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
      
      {viewModal ? <ViewAccount id={id} toggle={vtoggle} open={viewModal} /> : null}
    </Container>
  );
};

export default connect(null, { 
  getAllAccount, updateAccountStatus, getOrganizationHelper, getOrganizationById,
 })(AccountList);
